import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Void: any;
};

export type AddInspiringCompanyInput = {
  name?: Maybe<Scalars['String']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  _id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type AiUserProfile = {
  __typename?: 'AiUserProfile';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocations>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  location?: Maybe<Array<Maybe<Location>>>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  userSkills?: Maybe<Array<Maybe<UserSkill>>>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
};

export type AllNotificationToRead = {
  __typename?: 'AllNotificationToRead';
  message?: Maybe<Scalars['String']>;
};

export enum AppType {
  Mobile = 'MOBILE',
  Soblonk = 'SOBLONK',
  Backoffice = 'BACKOFFICE',
  Wob = 'WOB',
}

export type AssessmentInputView = {
  title?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type AssessmentView = {
  __typename?: 'AssessmentView';
  title?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Maybe<AssessmentInputStructure>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputStructure>>>;
};

export type CandidateEducation = {
  __typename?: 'CandidateEducation';
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
};

export type CandidateExperience = {
  __typename?: 'CandidateExperience';
  _id?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasonForChange?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  commentData?: Maybe<ExperienceCommentData>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type CandidateExperienceInput = {
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasonForChange?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  location?: Maybe<LocationExperienceInput>;
  logo?: Maybe<Scalars['String']>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type CandidateInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  position: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  skills: Array<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  currentLocation?: Maybe<LocationInput>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileVideo?: Maybe<Scalars['String']>;
  profilePicture: Scalars['String'];
  coverPhoto?: Maybe<Scalars['String']>;
  resume: Scalars['String'];
  coverLetter?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  linkedIn?: Maybe<Scalars['String']>;
};

export type CandidateList = {
  __typename?: 'CandidateList';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userSource?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  userIsProfileCompleted?: Maybe<Scalars['Boolean']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Int']>;
};

export type CandidateListing = {
  __typename?: 'CandidateListing';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Country>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocations>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  location?: Maybe<Array<Maybe<Location>>>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
};

export type CandidateListingProfile = {
  __typename?: 'CandidateListingProfile';
  _id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensation>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  languageComments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<CandidateEducation>>>;
  educationComment?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Objectivestype>>>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsType>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<SkillsType>>>;
  title?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  targetLocations?: Maybe<Array<Maybe<CandidateTargetLocation>>>;
  sectors?: Maybe<Array<Maybe<SectorsType>>>;
  targetSalary?: Maybe<TargetSalary>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyType>>>;
  ResumePreview?: Maybe<Scalars['String']>;
  attachmentPreview?: Maybe<Scalars['String']>;
};

export type CandidateListingProfileForPopUp = {
  __typename?: 'CandidateListingProfileForPopUp';
  _id?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<Location>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  user?: Maybe<User>;
  companyData?: Maybe<Company>;
  jobData?: Maybe<Job>;
};

export type CandidateTargetLocation = {
  __typename?: 'CandidateTargetLocation';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  sector?: Maybe<SectorType>;
  companySize?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  users?: Maybe<Array<RecruiterUser>>;
  jobTitle?: Maybe<Scalars['String']>;
  jobs?: Maybe<Array<Job>>;
  totalJobs?: Maybe<Scalars['Int']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<RecruiterUser>;
  owner?: Maybe<RecruiterUser>;
  favouritedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type CompanyCreateInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  sector: Scalars['String'];
  companySize: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
};

export type CompanyView = {
  __typename?: 'CompanyView';
  name?: Maybe<Scalars['String']>;
};

export type Compensation = {
  __typename?: 'Compensation';
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type CompensationInput = {
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type ContractType = {
  __typename?: 'ContractType';
  _id: Scalars['ID'];
  name: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CreateTagParams = {
  name: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Currency = {
  __typename?: 'Currency';
  _id: Scalars['ID'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  _id: Scalars['ID'];
  email: Scalars['String'];
};

export type ExperienceCommentData = {
  __typename?: 'ExperienceCommentData';
  candidateId?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
};

export type FamousLeadersInput = {
  id: Scalars['ID'];
  leaderImage?: Maybe<Scalars['String']>;
  leaderName?: Maybe<Scalars['String']>;
  leaderTitle?: Maybe<Scalars['String']>;
  leaderQuestion?: Maybe<Scalars['String']>;
  videoCompleted?: Maybe<Scalars['Boolean']>;
  videoURL?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type FirebaseTimeStamp = {
  nanoseconds?: Maybe<Scalars['Int']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type ForgotPasswordReaponse = {
  __typename?: 'ForgotPasswordReaponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type History = {
  __typename?: 'History';
  _id?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  userId?: Maybe<Scalars['String']>;
  totalUsage?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  isRequestedForTokens?: Maybe<Scalars['Boolean']>;
};

export type HistoryData = {
  __typename?: 'HistoryData';
  chatHistoryForSingleTool?: Maybe<History>;
  chatHistoryForAllTools?: Maybe<Array<Maybe<History>>>;
};

export type InspiringCompanyInput = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InspiringCompanyType = {
  __typename?: 'InspiringCompanyType';
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Job = {
  __typename?: 'Job';
  _id: Scalars['ID'];
  title: Scalars['String'];
  hideSalary: Scalars['Boolean'];
  countries: Array<Country>;
  salaryPackage: Scalars['String'];
  experience?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  requiredSkills: Array<Skills>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  recruiters?: Maybe<Array<Maybe<RecruiterUser>>>;
  recruitersDetail?: Maybe<Array<Maybe<RecruiterUser>>>;
  leadRecruiter?: Maybe<RecruiterUser>;
  adminRecruiter?: Maybe<RecruiterBoUser>;
  fields: Array<Maybe<JobField>>;
  contractType: ContractType;
  createdAt?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<RecruiterUser>;
  status?: Maybe<Scalars['String']>;
  targetCompanies: Array<InspiringCompany>;
  candidates?: Maybe<Array<Match>>;
  leftContent?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  referral?: Maybe<Referral>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  matchs?: Maybe<Match>;
  banner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  newCandidates?: Maybe<Array<Maybe<MatchAllJobs>>>;
  favouriteBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  isConfidential?: Maybe<Scalars['Boolean']>;
  new_candidates?: Maybe<Array<Maybe<Match>>>;
  count?: Maybe<Scalars['Int']>;
  assessment?: Maybe<AssessmentView>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJobView>;
  progressReport?: Maybe<Scalars['String']>;
  totalJobs?: Maybe<Scalars['Int']>;
};

export type JobField = {
  __typename?: 'JobField';
  _id: Scalars['ID'];
  title: Scalars['String'];
  parent?: Maybe<JobField>;
};

export type JobId = {
  __typename?: 'JobId';
  jobId?: Maybe<Scalars['String']>;
};

export type JobIsFavouriteParams = {
  isFavourite: Scalars['Boolean'];
};

export type JobLandingPage = {
  __typename?: 'JobLandingPage';
  _id: Scalars['ID'];
  jobId?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftContent?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  cssLink?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  metaImageUrl?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<RecruiterUser>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JobLandingPageStatusParams = {
  status: Scalars['String'];
};

export type JobStatusParams = {
  status: Scalars['String'];
};

export type JobView = {
  __typename?: 'JobView';
  _id: Scalars['ID'];
  title: Scalars['String'];
  company?: Maybe<CompanyView>;
  new_candidates?: Maybe<Array<Maybe<Match>>>;
};

export type JobsCount = {
  __typename?: 'JobsCount';
  activeJobsCount?: Maybe<Scalars['String']>;
  archivedJobsCount?: Maybe<Scalars['String']>;
  pendingJobsCount?: Maybe<Scalars['String']>;
  rejectedJobsCount?: Maybe<Scalars['String']>;
};

export type Languages = {
  __typename?: 'Languages';
  language?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type LanguagesInput = {
  language?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type LocationFields = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type Match = {
  __typename?: 'Match';
  _id: Scalars['ID'];
  candidateListing?: Maybe<CandidateListing>;
  jobListing?: Maybe<Job>;
  benchmark?: Maybe<Scalars['String']>;
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
  nextSteps?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  score?: Maybe<Scalars['Int']>;
  jobScore?: Maybe<Scalars['Float']>;
  activationScore?: Maybe<Scalars['Int']>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  candidateScore?: Maybe<Scalars['Float']>;
  matchingAlgoScores?: Maybe<Array<Maybe<MatchingAlgorithmScores>>>;
  response?: Maybe<Response>;
  AssessmentReport?: Maybe<GetAssessments>;
  hidePersonalDetails?: Maybe<Scalars['Boolean']>;
};

export type MatchAllJobs = {
  __typename?: 'MatchAllJobs';
  _id: Scalars['ID'];
  candidateListing?: Maybe<CandidateListing>;
  jobListing?: Maybe<Job>;
  company?: Maybe<Company>;
  score?: Maybe<Scalars['Int']>;
  jobScore?: Maybe<Scalars['Float']>;
  activationScore?: Maybe<Scalars['Int']>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  favouriteBy?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MatchingAlgorithmScores = {
  __typename?: 'MatchingAlgorithmScores';
  candidateScore?: Maybe<Scalars['Float']>;
  jobScore?: Maybe<Scalars['Float']>;
};

export type Message = {
  __typename?: 'Message';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  payload?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  senderMail: Scalars['String'];
  receiverMail: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type MessagePayload = {
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  recruiterId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  timeStampOfMyLastMessage?: Maybe<FirebaseTimeStamp>;
};

export type MobileUser = {
  __typename?: 'MobileUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  candidate?: Maybe<CandidateListing>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  experiences?: Maybe<Array<Maybe<CandidateExperience>>>;
  educations?: Maybe<Array<Maybe<CandidateEducation>>>;
  response?: Maybe<Response>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateCandidateBenchmark?: Maybe<CandidateListingProfile>;
  updateCandidateShowCompensation?: Maybe<Match>;
  updateCandidateNextSteps?: Maybe<Match>;
  updateCandidatePersonalInfo?: Maybe<CandidateListingProfile>;
  createMatch?: Maybe<Match>;
  AssessmentsUpdate?: Maybe<GetAssessments>;
  activateCandidate?: Maybe<Match>;
  candidateBenchmark?: Maybe<Response>;
  candidateShowCompensation?: Maybe<Response>;
  candidateHidePersonalDetails?: Maybe<Response>;
  candidateNextSteps?: Maybe<Response>;
  recruiterAction?: Maybe<Match>;
  testEmailTrigger?: Maybe<Response>;
  messageTriggerMailRecruiter?: Maybe<Response>;
  generateText?: Maybe<GenerateTextResponse>;
  deleteSingleHistory?: Maybe<Response>;
  deleteHistory?: Maybe<Response>;
  updateAiUserProfile?: Maybe<AiUserProfile>;
  updateProfileWithLinkedin?: Maybe<AiUserProfile>;
  handleLogin?: Maybe<MobileUser>;
  createEvent?: Maybe<Event>;
  editEvent?: Maybe<Response>;
  updateFamousLeaderInfo?: Maybe<FamousLeaders>;
  addNewCompany?: Maybe<InspiringCompany>;
  generateBlinks?: Maybe<Blink>;
  saveReferral?: Maybe<Response>;
  getUserReferrals?: Maybe<Array<Maybe<Referral>>>;
  registerCompany?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  createCountry?: Maybe<Array<Maybe<Country>>>;
  createJobLandingPage?: Maybe<JobLandingPage>;
  createJob?: Maybe<Job>;
  updateJobStatus?: Maybe<Job>;
  updateJobIsFavourite?: Maybe<Job>;
  deleteJob?: Maybe<Job>;
  resetJobCards?: Maybe<ResetJobCards>;
  createUser: User;
  updateUser: User;
  deleteUser: Scalars['Boolean'];
  userTyping: Scalars['Boolean'];
  createMessage: Message;
  updateMessage: Message;
  deleteMessage: Scalars['Boolean'];
  AttachRecruiterToACompany?: Maybe<RecruiterUser>;
  AttachRecruiterToAJob?: Maybe<Response>;
  removeRecruiterFromACompany?: Maybe<RecruiterUser>;
  createSkills: Array<Skills>;
  updateSkill?: Maybe<Skills>;
  updateStatus?: Maybe<Skills>;
  createCandidateAndMatch?: Maybe<CreateCandidateAndMatchResponse>;
  adminSignup?: Maybe<AdminUser>;
  adminLogin?: Maybe<AdminUser>;
  userSignup?: Maybe<User>;
  loginUser?: Maybe<User>;
  registerAsRecruiter?: Maybe<RecruiterUser>;
  resetAiUserPassword?: Maybe<Response>;
  verifyAiUserResetPasswordToken?: Maybe<Response>;
  updateAiUserPassword?: Maybe<Response>;
  refillAiUserTokens?: Maybe<Response>;
  candidateSignup?: Maybe<User>;
  candidateLogin?: Maybe<User>;
  updateCandidate?: Maybe<CandidateListing>;
  createCandidate?: Maybe<Response>;
  forgetPassword?: Maybe<ForgotPasswordReaponse>;
  resetPassword?: Maybe<Response>;
  resetEmail?: Maybe<Response>;
  verifyResetCode?: Maybe<ForgotPasswordReaponse>;
  favouriteJob?: Maybe<Response>;
  removeFavouriteJob?: Maybe<Response>;
  checkExistingUser?: Maybe<ExistingUserResponse>;
  updateCandidateDocument?: Maybe<CandidateListing>;
  registerCandidateStep?: Maybe<CandidateListing>;
  recruiterSignup?: Maybe<RecruiterUser>;
  VerifyRecruiterSignup?: Maybe<Response>;
  updateRecruiter?: Maybe<RecruiterUser>;
  recruiterSignupForACompany?: Maybe<RecruiterUser>;
  recruiterLogin?: Maybe<RecruiterUser>;
  recruiterResetPassword?: Maybe<RecruiterUser>;
  sendPasswordResetEmail?: Maybe<Response>;
  updateUserFCMToken?: Maybe<Response>;
  deleteUserFCMToken?: Maybe<Response>;
  sendMessage?: Maybe<Response>;
  deleteAUser?: Maybe<Response>;
  createTag: Array<Tag>;
  setNotificationToRead?: Maybe<NotificationId>;
  setMultipleNotificationsToRead?: Maybe<AllNotificationToRead>;
  sendRecruiterComment?: Maybe<JobId>;
  tagCandidates?: Maybe<Array<Maybe<Tag>>>;
  tagCrawlies?: Maybe<Array<Maybe<Tag>>>;
  deleteTag?: Maybe<Tag>;
  editTag?: Maybe<Tag>;
  untagCandidate?: Maybe<Response>;
  untagCrawlies?: Maybe<Response>;
};

export type MutationUpdateCandidateBenchmarkArgs = {
  candidateId: Scalars['String'];
  benchMark?: Maybe<Scalars['String']>;
};

export type MutationUpdateCandidateShowCompensationArgs = {
  candidateId: Scalars['String'];
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateCandidateNextStepsArgs = {
  candidateId: Scalars['String'];
  nextSteps?: Maybe<Scalars['String']>;
};

export type MutationUpdateCandidatePersonalInfoArgs = {
  candidateId: Scalars['String'];
  personalInformation?: Maybe<Scalars['String']>;
};

export type MutationCreateMatchArgs = {
  jobId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  dislikeReason?: Maybe<Scalars['String']>;
  isDesktop?: Maybe<Scalars['Boolean']>;
};

export type MutationAssessmentsUpdateArgs = {
  matchId?: Maybe<Scalars['String']>;
  hideExperience?: Maybe<Scalars['Boolean']>;
  hideTraits?: Maybe<Scalars['Boolean']>;
  hideDrivers?: Maybe<Scalars['Boolean']>;
  experience?: Maybe<Array<Maybe<AssessmentInputView>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputView>>>;
  drivers?: Maybe<Array<Maybe<AssessmentInputView>>>;
};

export type MutationActivateCandidateArgs = {
  matchId?: Maybe<Scalars['String']>;
  activationScore?: Maybe<Scalars['Int']>;
};

export type MutationCandidateBenchmarkArgs = {
  matchId?: Maybe<Scalars['String']>;
  benchmark?: Maybe<Scalars['String']>;
};

export type MutationCandidateShowCompensationArgs = {
  matchId?: Maybe<Scalars['String']>;
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
};

export type MutationCandidateHidePersonalDetailsArgs = {
  matchId?: Maybe<Scalars['String']>;
  hidePersonalDetails?: Maybe<Scalars['Boolean']>;
};

export type MutationCandidateNextStepsArgs = {
  matchId?: Maybe<Scalars['String']>;
  nextSteps?: Maybe<Scalars['String']>;
};

export type MutationRecruiterActionArgs = {
  matchId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
};

export type MutationTestEmailTriggerArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type MutationMessageTriggerMailRecruiterArgs = {
  matchId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type MutationGenerateTextArgs = {
  prompt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type MutationDeleteSingleHistoryArgs = {
  historyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MutationDeleteHistoryArgs = {
  toolName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type MutationUpdateAiUserProfileArgs = {
  userId: Scalars['String'];
  data?: Maybe<UpdateAiUserData>;
};

export type MutationUpdateProfileWithLinkedinArgs = {
  userId?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
};

export type MutationHandleLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateEventArgs = {
  params?: Maybe<EventInput>;
};

export type MutationEditEventArgs = {
  params?: Maybe<EventInput>;
  eventId?: Maybe<Scalars['String']>;
};

export type MutationUpdateFamousLeaderInfoArgs = {
  params?: Maybe<FamousLeadersInput>;
};

export type MutationAddNewCompanyArgs = {
  name: Scalars['String'];
};

export type MutationGenerateBlinksArgs = {
  url?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};

export type MutationSaveReferralArgs = {
  jobId?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['Boolean']>;
  referreeId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationGetUserReferralsArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationRegisterCompanyArgs = {
  companyData?: Maybe<CompanyCreateInput>;
  companyId?: Maybe<Scalars['String']>;
};

export type MutationUpdateCompanyArgs = {
  companyData?: Maybe<UpdateCompany>;
  companyId?: Maybe<Scalars['String']>;
};

export type MutationCreateCountryArgs = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationCreateJobLandingPageArgs = {
  jobLandingPage?: Maybe<CreateJobLandingPageParams>;
  jobLandingPageId?: Maybe<Scalars['String']>;
};

export type MutationCreateJobArgs = {
  job?: Maybe<CreateJobParams>;
  jobId?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateJobStatusArgs = {
  jobId?: Maybe<Scalars['String']>;
  action?: Maybe<JobStatusParams>;
};

export type MutationUpdateJobIsFavouriteArgs = {
  jobId?: Maybe<Scalars['String']>;
  action?: Maybe<JobIsFavouriteParams>;
};

export type MutationDeleteJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type MutationResetJobCardsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type MutationCreateUserArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  email: Scalars['String'];
};

export type MutationUserTypingArgs = {
  email: Scalars['String'];
  receiverMail: Scalars['String'];
};

export type MutationCreateMessageArgs = {
  senderMail: Scalars['String'];
  receiverMail: Scalars['String'];
  message: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type MutationDeleteMessageArgs = {
  id: Scalars['String'];
};

export type MutationAttachRecruiterToACompanyArgs = {
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type MutationAttachRecruiterToAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationRemoveRecruiterFromACompanyArgs = {
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type MutationCreateSkillsArgs = {
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationUpdateSkillArgs = {
  skillId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MutationUpdateStatusArgs = {
  skillId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateCandidateAndMatchArgs = {
  jobId: Scalars['String'];
  candidateInfo?: Maybe<CandidateInfoInput>;
  media: Scalars['String'];
  action?: Maybe<Scalars['String']>;
};

export type MutationAdminSignupArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
};

export type MutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUserSignupArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  currentCompany?: Maybe<Scalars['String']>;
  currentJobTitle?: Maybe<Scalars['String']>;
};

export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRegisterAsRecruiterArgs = {
  userId: Scalars['String'];
  recruiter: Recruiter;
};

export type MutationResetAiUserPasswordArgs = {
  email: Scalars['String'];
};

export type MutationVerifyAiUserResetPasswordTokenArgs = {
  token: Scalars['String'];
};

export type MutationUpdateAiUserPasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationRefillAiUserTokensArgs = {
  email: Scalars['String'];
};

export type MutationCandidateSignupArgs = {
  fullName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
  roles: Array<Scalars['String']>;
};

export type MutationCandidateLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateCandidateArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<UpdateCandidate>;
  createdBy?: Maybe<Scalars['String']>;
};

export type MutationCreateCandidateArgs = {
  candidate?: Maybe<RegisterCandidate>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationForgetPasswordArgs = {
  email: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  resetCode: Scalars['Int'];
  newPassword: Scalars['String'];
  bypassResetCode?: Maybe<Scalars['Boolean']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationResetEmailArgs = {
  email: Scalars['String'];
  newEmail: Scalars['String'];
};

export type MutationVerifyResetCodeArgs = {
  email: Scalars['String'];
  resetCode: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationFavouriteJobArgs = {
  jobId: Scalars['String'];
  candidateId?: Maybe<Scalars['String']>;
};

export type MutationRemoveFavouriteJobArgs = {
  jobId: Scalars['String'];
  candidateId?: Maybe<Scalars['String']>;
};

export type MutationCheckExistingUserArgs = {
  email?: Maybe<Scalars['String']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateCandidateDocumentArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<UpdateCandidateDocument>;
};

export type MutationRegisterCandidateStepArgs = {
  candidateId: Scalars['String'];
  candidate?: Maybe<RegisterCandidateStepType>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationRecruiterSignupArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  company: Scalars['String'];
  location: UpdateLocationExperienceAndEducation;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationVerifyRecruiterSignupArgs = {
  userId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateRecruiterArgs = {
  recruiterId: Scalars['String'];
  recruiter?: Maybe<UpdateRecruiter>;
};

export type MutationRecruiterSignupForACompanyArgs = {
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  companyId: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationRecruiterLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationRecruiterResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
  isAiUser?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateUserFcmTokenArgs = {
  token: Scalars['String'];
  appType: Scalars['String'];
};

export type MutationDeleteUserFcmTokenArgs = {
  appType: Scalars['String'];
};

export type MutationSendMessageArgs = {
  sendTo: Scalars['String'];
  myRole: Scalars['String'];
  message: Scalars['String'];
  payload?: Maybe<MessagePayload>;
};

export type MutationDeleteAUserArgs = {
  userId?: Maybe<Scalars['String']>;
};

export type MutationCreateTagArgs = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSetNotificationToReadArgs = {
  notificationId?: Maybe<Scalars['String']>;
};

export type MutationSetMultipleNotificationsToReadArgs = {
  notificationsArray?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationSendRecruiterCommentArgs = {
  senderId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MutationTagCandidatesArgs = {
  candidateIds: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type MutationTagCrawliesArgs = {
  candidateIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MutationDeleteTagArgs = {
  _id?: Maybe<Scalars['ID']>;
};

export type MutationEditTagArgs = {
  _id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type MutationUntagCandidateArgs = {
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};

export type MutationUntagCrawliesArgs = {
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
};

export type NotificationId = {
  __typename?: 'NotificationId';
  notificationId?: Maybe<Scalars['String']>;
};

export type NotificationObject = {
  __typename?: 'NotificationObject';
  _id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  notificationHeader?: Maybe<Scalars['String']>;
  notificationBody?: Maybe<Scalars['String']>;
  tabActivity?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  candidate?: Maybe<CandidateListing>;
  user_type?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  recruiter?: Maybe<RecruiterUser>;
  match?: Maybe<Match>;
};

export type Objectives = {
  __typename?: 'Objectives';
  _id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProfileUserInput = {
  email?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getMatch?: Maybe<Match>;
  getAllMatch: Array<GetAllMatchData>;
  matchesForAJob: Array<Match>;
  newCandidatesAgainstAJob: Array<Match>;
  disLikesAgainstAJob: Array<Match>;
  dislikeActivityOfRecruiter: Array<Match>;
  likesAgainstAJob: Array<Match>;
  likeActivityOfRecruiter: Array<Match>;
  matchesAgainstAJob: Array<Match>;
  matchesActivityOfRecruiter: Array<Match>;
  selectedAgainstAJob: Array<Match>;
  selectedActivityOfRecruiter: Array<Match>;
  rejectedAgainstAJob: Array<Match>;
  hiredAgainstAJob: Array<Match>;
  favouritesAgainstAJob: Array<Match>;
  candidatesEligableForChatOnAJob: Array<Match>;
  getChatHistory?: Maybe<HistoryData>;
  getSingleHistory?: Maybe<SingleMessage>;
  getAiUserData?: Maybe<AiUserProfile>;
  requestAiTokens?: Maybe<Response>;
  famousLeaderQuery: Array<FamousLeaders>;
  getEvents?: Maybe<Array<Maybe<Event>>>;
  getAllInspiringCompanies?: Maybe<Array<Maybe<InspiringCompany>>>;
  getMatchPopUp?: Maybe<Array<Maybe<CandidateListingProfileForPopUp>>>;
  userCompanies: Array<Company>;
  companies: Array<Company>;
  getSingleCompany?: Maybe<Company>;
  getCompanyFromJob?: Maybe<Company>;
  contracts: Array<ContractType>;
  countries: Array<Country>;
  currencies: Array<Currency>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompany>>>;
  jobFields: Array<JobField>;
  getJobLandingPage?: Maybe<JobLandingPage>;
  getJobDetails?: Maybe<Job>;
  getPublicJobDetails?: Maybe<Job>;
  getUserJobs?: Maybe<Array<Maybe<Job>>>;
  AllJobs?: Maybe<Array<Maybe<Job>>>;
  getMatchAllJob?: Maybe<Array<Maybe<Job>>>;
  AllActiveJobs?: Maybe<Array<Maybe<Job>>>;
  AllArchivedJobs?: Maybe<Array<Maybe<Job>>>;
  AllPendingJobs?: Maybe<Array<Maybe<Job>>>;
  AllRejectedJobs?: Maybe<Array<Maybe<Job>>>;
  AllJobsView?: Maybe<Array<Maybe<JobView>>>;
  getAllRecruiterJobs?: Maybe<Array<Maybe<Job>>>;
  getJobs?: Maybe<Array<Maybe<Job>>>;
  getCompanyJobs?: Maybe<Array<Maybe<Job>>>;
  JobCards?: Maybe<Array<Maybe<Job>>>;
  likedJobs?: Maybe<Array<Maybe<Job>>>;
  disLikedJobs?: Maybe<Array<Maybe<Job>>>;
  matchedJobs?: Maybe<Array<Maybe<Job>>>;
  allTypesOfMatchedJobs?: Maybe<Array<Maybe<Job>>>;
  favouriteJobs?: Maybe<Array<Maybe<Job>>>;
  getCandidateChatsOnJobs?: Maybe<Array<Maybe<Job>>>;
  GetJobsCount?: Maybe<JobsCount>;
  users?: Maybe<Array<Maybe<User>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  getObjectives: Array<Objectives>;
  getPersonalInterests: Array<PersonalInterest>;
  getAllRecruitersInBO: Array<RecruiterBoUser>;
  getAllRecruiters: Array<RecruiterUser>;
  getAllRecruitersExcept: Array<RecruiterUser>;
  getAllCompanyRecruiters: Array<RecruiterUser>;
  getSingleRecruiter?: Maybe<RecruiterBoUser>;
  sectors?: Maybe<Array<Maybe<SectorType>>>;
  skills: Array<Skills>;
  getCandidate?: Maybe<CandidateListingProfile>;
  getAllCandidatesInBO?: Maybe<Array<Maybe<CandidateList>>>;
  countAllCandidatesInBO?: Maybe<CountAllCandidates>;
  currentUser?: Maybe<CurrentUser>;
  allAdmins: Array<Maybe<AdminUser>>;
  Objectives?: Maybe<Array<Maybe<ObjectivesOrInterests>>>;
  canidateInfo?: Maybe<User>;
  ProfessionalInterests?: Maybe<Array<Maybe<ObjectivesOrInterests>>>;
  allCandidates: Array<Maybe<CandidateListing>>;
  me?: Maybe<RecruiterUser>;
  allRecruiters: Array<Maybe<RecruiterUser>>;
  getTags?: Maybe<Array<Maybe<Tag>>>;
  getMyNotifications?: Maybe<Array<Maybe<NotificationObject>>>;
  crawliesPersonalDetails?: Maybe<Array<Maybe<CrawliesPersonalDetail>>>;
  jobListingsModel?: Maybe<Array<Maybe<JobListingsModel>>>;
};

export type QueryGetMatchArgs = {
  matchId?: Maybe<Scalars['String']>;
};

export type QueryGetAllMatchArgs = {
  ListingId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
};

export type QueryMatchesForAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
  searchParams?: Maybe<SearchParamsInput>;
};

export type QueryNewCandidatesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryDisLikesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryLikesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryMatchesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QuerySelectedAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryRejectedAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryHiredAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryFavouritesAgainstAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryCandidatesEligableForChatOnAJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryGetChatHistoryArgs = {
  userId?: Maybe<Scalars['String']>;
  toolName?: Maybe<Scalars['String']>;
};

export type QueryGetSingleHistoryArgs = {
  historyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type QueryGetAiUserDataArgs = {
  userId: Scalars['String'];
};

export type QueryRequestAiTokensArgs = {
  email?: Maybe<Scalars['String']>;
};

export type QueryGetEventsArgs = {
  userId?: Maybe<Scalars['String']>;
};

export type QueryGetMatchPopUpArgs = {
  candidateId?: Maybe<Array<Maybe<CandidateIds>>>;
};

export type QueryCompaniesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};

export type QueryGetSingleCompanyArgs = {
  companyId?: Maybe<Scalars['String']>;
};

export type QueryGetCompanyFromJobArgs = {
  jobId?: Maybe<Scalars['String']>;
};

export type QueryGetJobLandingPageArgs = {
  jobId: Scalars['ID'];
};

export type QueryGetJobDetailsArgs = {
  jobId: Scalars['ID'];
};

export type QueryGetPublicJobDetailsArgs = {
  jobId: Scalars['ID'];
};

export type QueryGetUserJobsArgs = {
  userId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type QueryAllActiveJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type QueryAllArchivedJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};

export type QueryAllPendingJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};

export type QueryAllRejectedJobsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};

export type QueryGetJobsArgs = {
  companyId: Scalars['ID'];
};

export type QueryGetCompanyJobsArgs = {
  companyId: Scalars['ID'];
};

export type QueryJobCardsArgs = {
  candidateId?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type QueryLikedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryDisLikedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryMatchedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryAllTypesOfMatchedJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryFavouriteJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryGetCandidateChatsOnJobsArgs = {
  candidateId?: Maybe<Scalars['String']>;
};

export type QueryGetJobsCountArgs = {
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
};

export type QueryGetAllRecruitersExceptArgs = {
  companyId?: Maybe<Scalars['String']>;
};

export type QueryGetAllCompanyRecruitersArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryGetSingleRecruiterArgs = {
  recruiterId?: Maybe<Scalars['String']>;
};

export type QuerySkillsArgs = {
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isBO?: Maybe<Scalars['Boolean']>;
};

export type QueryGetCandidateArgs = {
  candidateId: Scalars['String'];
};

export type QueryGetAllCandidatesInBoArgs = {
  searchParams?: Maybe<SearchParamsInput>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
};

export type QueryCountAllCandidatesInBoArgs = {
  searchParams?: Maybe<SearchParamsInput>;
};

export type QueryCanidateInfoArgs = {
  id: Scalars['String'];
};

export type QueryGetMyNotificationsArgs = {
  userId?: Maybe<Scalars['String']>;
};

export type QueryCrawliesPersonalDetailsArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryJobListingsModelArgs = {
  currencyId?: Maybe<Scalars['String']>;
  jobLandingPageId?: Maybe<Scalars['String']>;
};

export type Recruiter = {
  fullName: Scalars['String'];
  title: Scalars['String'];
  profilePic: Scalars['String'];
  coverPhoto?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  location: LocationType;
  company: Scalars['String'];
  description: Scalars['String'];
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
};

export type RecruiterBoUser = {
  __typename?: 'RecruiterBOUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  user?: Maybe<UserObj>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  companiesCount?: Maybe<Scalars['Int']>;
  jobsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<RecruiterExperience>>>;
  educations?: Maybe<Array<Maybe<RecruiterEducation>>>;
  coverPhoto?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<Languages>>>;
  location?: Maybe<LocationTypeEducationAndExperience>;
};

export type RecruiterCreateInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  sector: Scalars['String'];
  companySize: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
};

export type RecruiterEducation = {
  __typename?: 'RecruiterEducation';
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  mergedData?: Maybe<Array<Maybe<RecruiterEducation>>>;
};

export type RecruiterExperience = {
  __typename?: 'RecruiterExperience';
  _id?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<LocationTypeEducationAndExperience>;
  logo?: Maybe<Scalars['String']>;
  mergedData?: Maybe<Array<Maybe<RecruiterExperience>>>;
};

export type RecruiterUser = {
  __typename?: 'RecruiterUser';
  _id: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  location?: Maybe<LocationQuery>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<RecruiterExperience>>>;
  educations?: Maybe<Array<Maybe<RecruiterEducation>>>;
  token?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
  profileVideo?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfileUserOutput>;
};

export type RegisterCandidate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  yearlyCompensation?: Maybe<YearlyCompensationInput>;
  nextSteps?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<LocationInput>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetLocations?: Maybe<Array<Maybe<Scalars['String']>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<TagsInput>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
};

export type RegisterCandidateStepType = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  currentLocation?: Maybe<LocationInputRegisterationStep>;
  company?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileVideo?: Maybe<Scalars['String']>;
  registrationStepNo?: Maybe<Scalars['Int']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
};

export type ResetJobCards = {
  __typename?: 'ResetJobCards';
  message?: Maybe<Scalars['String']>;
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type SearchParamsInput = {
  searchText?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dtFrom?: Maybe<Scalars['String']>;
  dtTo?: Maybe<Scalars['String']>;
};

export type SectorType = {
  __typename?: 'SectorType';
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SectorsInput = {
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SectorsType = {
  __typename?: 'SectorsType';
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type SingleMessage = {
  __typename?: 'SingleMessage';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
};

export type Skills = {
  __typename?: 'Skills';
  _id: Scalars['ID'];
  title: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  jobsCount?: Maybe<Array<Maybe<Job>>>;
  candidatesCount?: Maybe<Array<Maybe<CandidateListing>>>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdMatch?: Maybe<Match>;
  companyCreated?: Maybe<Company>;
  newMessage?: Maybe<Message>;
  newUser?: Maybe<User>;
  oldUser?: Maybe<Scalars['String']>;
  userTyping?: Maybe<Scalars['String']>;
  sendNewNotification?: Maybe<SubscriptionPayload>;
};

export type SubscriptionNewMessageArgs = {
  receiverMail: Scalars['String'];
};

export type SubscriptionUserTypingArgs = {
  receiverMail: Scalars['String'];
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  notificationForId?: Maybe<Scalars['String']>;
  latestNotification?: Maybe<NotificationObject>;
};

export type Tag = {
  __typename?: 'Tag';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type TagsInput = {
  _id?: Maybe<Scalars['ID']>;
};

export type TargetLocations = {
  __typename?: 'TargetLocations';
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  _id: Scalars['ID'];
};

export type TargetLocationsInput = {
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
};

export type TargetSalary = {
  __typename?: 'TargetSalary';
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  range?: Maybe<Scalars['Int']>;
  targetSalaryMin?: Maybe<Scalars['Int']>;
  targetSalaryMax?: Maybe<Scalars['Int']>;
  visibleToRecruiter?: Maybe<Scalars['Boolean']>;
  _id: Scalars['ID'];
};

export type TargetSalaryInput = {
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  range?: Maybe<Scalars['Int']>;
  targetSalaryMin?: Maybe<Scalars['Int']>;
  targetSalaryMax?: Maybe<Scalars['Int']>;
  visibleToRecruiter?: Maybe<Scalars['Boolean']>;
};

export type UpdateCandidate = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  totalExperience?: Maybe<Scalars['Int']>;
  yearlyCompensation?: Maybe<YearlyCompensationInput>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  languageComments?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  title?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  educationComment?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  currentLocation?: Maybe<LocationInput>;
  profilePicture?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectives?: Maybe<Array<Maybe<SectorsInput>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocationsInput>>>;
  sectors?: Maybe<Array<Maybe<SectorsInput>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** targetLocations: [String] */
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  desiredSalaryPackage?: Maybe<Scalars['String']>;
  currentSalaryPackage?: Maybe<Scalars['String']>;
  isOnlyNetworker?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  appliedJobs?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractIdType?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  salaries?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  recruiterVisible?: Maybe<Scalars['Boolean']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  targetSalary?: Maybe<TargetSalaryInput>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyInput>>>;
  source?: Maybe<Scalars['String']>;
};

export type UpdateCandidateDocument = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
};

export type UpdateCompany = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  confidentialdescription?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  totalJobs?: Maybe<Scalars['Int']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  favouritedBy?: Maybe<Scalars['String']>;
};

export type UpdateLocationExperienceAndEducation = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type UpdateRecruiter = {
  fullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  coverPhoto?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  location?: Maybe<LocationFields>;
  user?: Maybe<ProfileUserInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  messages?: Maybe<Array<Maybe<Message>>>;
  source?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  currentCompany?: Maybe<Scalars['String']>;
  currentJobTitle?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  /** candidate: CandidateListing */
  response?: Maybe<Response>;
  aiTokens?: Maybe<Scalars['Int']>;
  languagePreference?: Maybe<Scalars['String']>;
  userProfile?: Maybe<AiUserProfile>;
  candidate?: Maybe<CandidateListing>;
};

export type UserObj = {
  __typename?: 'UserObj';
  _id?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  subscriptionPlanId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type UserProfileUserOutput = {
  __typename?: 'UserProfileUserOutput';
  email?: Maybe<Scalars['String']>;
};

export type YearlyCompensation = {
  __typename?: 'YearlyCompensation';
  currency?: Maybe<Scalars['String']>;
  current?: Maybe<Compensation>;
  expected?: Maybe<Compensation>;
  comments?: Maybe<Scalars['String']>;
  currentComments?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInput = {
  currency?: Maybe<Scalars['String']>;
  current?: Maybe<CompensationInput>;
  expected?: Maybe<CompensationInput>;
  comments?: Maybe<Scalars['String']>;
  currentComments?: Maybe<Scalars['String']>;
};

export type AssessmentInput = {
  experience?: Maybe<Array<Maybe<AssessmentInputObj>>>;
  traits?: Maybe<Array<Maybe<AssessmentInputObj>>>;
};

export type AssessmentInputObj = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AssessmentInputStructure = {
  __typename?: 'assessmentInputStructure';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Blink = {
  __typename?: 'blink';
  blinkURL?: Maybe<Scalars['String']>;
  response?: Maybe<Response>;
};

export type CandidateIds = {
  candidateId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  matchId?: Maybe<Scalars['String']>;
};

export type CoordinatesInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CoordinatesType = {
  __typename?: 'coordinatesType';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type CountAllCandidates = {
  __typename?: 'countAllCandidates';
  count?: Maybe<Scalars['Int']>;
};

export type CountryName = {
  __typename?: 'countryName';
  name?: Maybe<Scalars['String']>;
};

export type CrawliesOtherInfo = {
  __typename?: 'crawliesOtherInfo';
  _id?: Maybe<Scalars['ID']>;
  linkedIn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  crawlie?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
};

export type CrawliesPersonalDetail = {
  __typename?: 'crawliesPersonalDetail';
  _id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  is_candidate?: Maybe<Scalars['Boolean']>;
  CV?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type CrawliesProfessionalDetails = {
  __typename?: 'crawliesProfessionalDetails';
  _id?: Maybe<Scalars['ID']>;
  CV?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  crawlie?: Maybe<Scalars['ID']>;
};

export type CreateCandidateAndMatchResponse = {
  __typename?: 'createCandidateAndMatchResponse';
  _id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  candidate?: Maybe<CandidateListingProfile>;
};

export type CreateJobLandingPageParams = {
  jobId: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftContent?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  cssLink?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  metaImageUrl?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CreateJobParams = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hideSalary?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  salaryPackage?: Maybe<Scalars['String']>;
  requiredSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  experience?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['String']>;
  recruiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  leadRecruiter?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  targetCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isConfidential?: Maybe<Scalars['Boolean']>;
  assessment?: Maybe<AssessmentInput>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJob>;
  progressReport?: Maybe<Scalars['String']>;
};

export type EducationInput = {
  _id?: Maybe<Scalars['String']>;
  schoolName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  endYear?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateLocationExperienceAndEducation>;
  logo?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'event';
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventVenu?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  professionalInterests?: Maybe<Array<Maybe<ProfessionalIntereststype>>>;
};

export type EventInput = {
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  eventVenu?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook?: Maybe<Scalars['String']>;
};

export type ExistingUserResponse = {
  __typename?: 'existingUserResponse';
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
};

export type ExperienceInput = {
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
  sector?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  experienceDetails?: Maybe<Scalars['String']>;
};

export type ExperiencesInput = {
  _id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  reasonForChange?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Boolean']>;
  sector?: Maybe<Scalars['String']>;
  jobField?: Maybe<Array<Maybe<Scalars['String']>>>;
  experienceDetails?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<UpdateLocationExperienceAndEducation>;
};

export type FamousLeaders = {
  __typename?: 'famousLeaders';
  _id?: Maybe<Scalars['ID']>;
  leaderImage?: Maybe<Scalars['String']>;
  leaderName?: Maybe<Scalars['String']>;
  leaderTitle?: Maybe<Scalars['String']>;
  leaderQuestion?: Maybe<Scalars['String']>;
  videoCompleted?: Maybe<Scalars['Boolean']>;
  videoURL?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type GenerateTextResponse = {
  __typename?: 'generateTextResponse';
  remaningTokens?: Maybe<Scalars['Int']>;
  aiResponse?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GetAllMatchData = {
  __typename?: 'getAllMatchData';
  _id: Scalars['ID'];
  job?: Maybe<Job>;
  company?: Maybe<Company>;
  recruiterAction?: Maybe<Scalars['String']>;
  candidateAction?: Maybe<Scalars['String']>;
  lastAction?: Maybe<Scalars['String']>;
  isProfileCompleted?: Maybe<Scalars['Boolean']>;
  bypass?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  alreadyApplied?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  candidateScore?: Maybe<Scalars['Float']>;
  matchingAlgoScores?: Maybe<Array<Maybe<MatchingAlgorithmScores>>>;
  response?: Maybe<Response>;
  country: Array<CountryName>;
};

export type GetAssessments = {
  __typename?: 'getAssessments';
  _id?: Maybe<Scalars['ID']>;
  matchId?: Maybe<Scalars['ID']>;
  hideExperience?: Maybe<Scalars['Boolean']>;
  hideTraits?: Maybe<Scalars['Boolean']>;
  hideDrivers?: Maybe<Scalars['Boolean']>;
  experience?: Maybe<Array<Maybe<AssessmentView>>>;
  traits?: Maybe<Array<Maybe<AssessmentView>>>;
  drivers?: Maybe<Array<Maybe<AssessmentView>>>;
};

export type InspiringCompany = {
  __typename?: 'inspiringCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type JobListingsModel = {
  __typename?: 'jobListingsModel';
  isConfidential?: Maybe<Scalars['Boolean']>;
};

export type Location = {
  __typename?: 'location';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LocationExperienceInput = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type LocationInput = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LocationInputRegisterationStep = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type LocationQuery = {
  __typename?: 'locationQuery';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesType>;
};

export type LocationType = {
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
};

export type LocationTypeEducationAndExperience = {
  __typename?: 'locationTypeEducationAndExperience';
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesType>;
};

export type ObjectivesOrInterests = {
  __typename?: 'objectivesORInterests';
  title?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
};

export type Objectivestype = {
  __typename?: 'objectivestype';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PersonalInterest = {
  __typename?: 'personalInterest';
  _id: Scalars['ID'];
  title: Scalars['String'];
};

export type ProfessionalIntereststype = {
  __typename?: 'professionalIntereststype';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'referral';
  referreeId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobId?: Maybe<Scalars['String']>;
  blinkURL?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  applicantReward?: Maybe<Scalars['String']>;
  reffrerReward?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type SkillsType = {
  __typename?: 'skillsType';
  _id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type TagsType = {
  __typename?: 'tagsType';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type Temp = {
  __typename?: 'temp';
  experience?: Maybe<Array<Maybe<AssessmentView>>>;
  traits?: Maybe<Array<Maybe<AssessmentView>>>;
};

export type UpdateAiUserData = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<Scalars['String']>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  languagePreference?: Maybe<Scalars['String']>;
};

export type UserSkill = {
  __typename?: 'userSkill';
  name?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInputJob = {
  currency?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type YearlyCompensationInputJobView = {
  __typename?: 'yearlyCompensationInputJobView';
  currency?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  onTargetEarnings?: Maybe<Scalars['String']>;
  shortTermIncentives?: Maybe<Scalars['String']>;
  longTermIncentives?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type AllJobsQueryVariables = Exact<{ [key: string]: never }>;

export type AllJobsQuery = { __typename?: 'Query' } & {
  AllJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          | '_id'
          | 'title'
          | 'hideSalary'
          | 'salaryPackage'
          | 'experience'
          | 'description'
          | 'createdAt'
          | 'status'
          | 'favouriteBy'
        > & {
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            newCandidates?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MatchAllJobs' } & Pick<
                    MatchAllJobs,
                    | 'activationScore'
                    | '_id'
                    | 'score'
                    | 'jobScore'
                    | 'recruiterAction'
                    | 'candidateAction'
                    | 'bypass'
                    | 'media'
                    | 'isDeleted'
                    | 'createdAt'
                    | 'updatedAt'
                  > & {
                      candidateListing?: Maybe<
                        { __typename?: 'CandidateListing' } & Pick<
                          CandidateListing,
                          | '_id'
                          | 'firstName'
                          | 'lastName'
                          | 'position'
                          | 'description'
                          | 'resume'
                          | 'source'
                          | 'isPublic'
                          | 'profilePicture'
                          | 'isDeleted'
                          | 'token'
                          | 'subscriptionPlanId'
                          | 'createdAt'
                          | 'updatedAt'
                          | 'title'
                          | 'isProfileCompleted'
                        > & {
                            user?: Maybe<
                              { __typename?: 'User' } & Pick<User, 'email'>
                            >;
                          }
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type AllActiveJobsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type AllActiveJobsQuery = { __typename?: 'Query' } & {
  AllActiveJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          '_id' | 'title' | 'count' | 'createdAt' | 'status' | 'favouriteBy'
        > & {
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<RecruiterUser, '_id'>
                >
              >
            >;
            countries: Array<
              { __typename?: 'Country' } & Pick<Country, 'name'>
            >;
            recruitersDetail?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'fullName' | 'title' | 'profilePic'
                  >
                >
              >
            >;
            newCandidates?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MatchAllJobs' } & Pick<
                    MatchAllJobs,
                    | 'activationScore'
                    | '_id'
                    | 'score'
                    | 'jobScore'
                    | 'recruiterAction'
                    | 'candidateAction'
                    | 'bypass'
                    | 'media'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type AllArchivedJobsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type AllArchivedJobsQuery = { __typename?: 'Query' } & {
  AllArchivedJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          '_id' | 'title' | 'count' | 'createdAt' | 'status' | 'favouriteBy'
        > & {
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            countries: Array<
              { __typename?: 'Country' } & Pick<Country, 'name'>
            >;
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<RecruiterUser, '_id'>
                >
              >
            >;
            recruitersDetail?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'fullName' | 'title' | 'profilePic'
                  >
                >
              >
            >;
            newCandidates?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MatchAllJobs' } & Pick<
                    MatchAllJobs,
                    | 'activationScore'
                    | '_id'
                    | 'score'
                    | 'jobScore'
                    | 'recruiterAction'
                    | 'candidateAction'
                    | 'bypass'
                    | 'media'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type AllPendingJobsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type AllPendingJobsQuery = { __typename?: 'Query' } & {
  AllPendingJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          '_id' | 'title' | 'count' | 'createdAt' | 'status' | 'favouriteBy'
        > & {
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<RecruiterUser, '_id'>
                >
              >
            >;
            countries: Array<
              { __typename?: 'Country' } & Pick<Country, 'name'>
            >;
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            recruitersDetail?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'fullName' | 'title' | 'profilePic'
                  >
                >
              >
            >;
            newCandidates?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MatchAllJobs' } & Pick<
                    MatchAllJobs,
                    | 'activationScore'
                    | '_id'
                    | 'score'
                    | 'jobScore'
                    | 'recruiterAction'
                    | 'candidateAction'
                    | 'bypass'
                    | 'media'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type AllRejectedJobsQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type AllRejectedJobsQuery = { __typename?: 'Query' } & {
  AllRejectedJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          '_id' | 'title' | 'count' | 'createdAt' | 'status' | 'favouriteBy'
        > & {
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<RecruiterUser, '_id'>
                >
              >
            >;
            countries: Array<
              { __typename?: 'Country' } & Pick<Country, 'name'>
            >;
            recruitersDetail?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'fullName' | 'title' | 'profilePic'
                  >
                >
              >
            >;
            newCandidates?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MatchAllJobs' } & Pick<
                    MatchAllJobs,
                    | 'activationScore'
                    | '_id'
                    | 'score'
                    | 'jobScore'
                    | 'recruiterAction'
                    | 'candidateAction'
                    | 'bypass'
                    | 'media'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type AllJobsViewQueryVariables = Exact<{ [key: string]: never }>;

export type AllJobsViewQuery = { __typename?: 'Query' } & {
  AllJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<Job, '_id' | 'title'> & {
            company?: Maybe<{ __typename?: 'Company' } & Pick<Company, 'name'>>;
          }
      >
    >
  >;
};

export type GetJobsCountQueryVariables = Exact<{
  searchText?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
}>;

export type GetJobsCountQuery = { __typename?: 'Query' } & {
  GetJobsCount?: Maybe<
    { __typename?: 'JobsCount' } & Pick<
      JobsCount,
      | 'activeJobsCount'
      | 'archivedJobsCount'
      | 'pendingJobsCount'
      | 'rejectedJobsCount'
    >
  >;
};

export type GetAllRecruitersInBoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllRecruitersInBoQuery = { __typename?: 'Query' } & {
  getAllRecruitersInBO: Array<
    { __typename?: 'RecruiterBOUser' } & Pick<
      RecruiterBoUser,
      | '_id'
      | 'fullName'
      | 'profilePic'
      | 'status'
      | 'title'
      | 'verified'
      | 'companiesCount'
      | 'jobsCount'
      | 'createdAt'
    > & {
        user?: Maybe<
          { __typename?: 'UserObj' } & Pick<
            UserObj,
            | '_id'
            | 'roles'
            | 'email'
            | 'title'
            | 'verified'
            | 'subscriptionPlanId'
            | 'token'
          >
        >;
      }
  >;
};

export type UpdateAssessmentsMutationVariables = Exact<{
  matchId: Scalars['String'];
  hideExperience: Scalars['Boolean'];
  hideTraits: Scalars['Boolean'];
  hideDrivers: Scalars['Boolean'];
  experience: Array<AssessmentInputView>;
  traits: Array<AssessmentInputView>;
  drivers: Array<AssessmentInputView>;
}>;

export type UpdateAssessmentsMutation = { __typename?: 'Mutation' } & {
  AssessmentsUpdate?: Maybe<
    { __typename?: 'getAssessments' } & Pick<GetAssessments, '_id'>
  >;
};

export type AttachRecruiterToACompanyMutationVariables = Exact<{
  userId: Scalars['String'];
  companyId: Scalars['String'];
}>;

export type AttachRecruiterToACompanyMutation = { __typename?: 'Mutation' } & {
  AttachRecruiterToACompany?: Maybe<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      | '_id'
      | 'userId'
      | 'title'
      | 'subscriptionPlanId'
      | 'fullName'
      | 'email'
      | 'token'
    >
  >;
};

export type AttachRecruiterToAJobMutationVariables = Exact<{
  companyId: Scalars['String'];
  jobId: Scalars['String'];
  userIds?: Maybe<Array<Scalars['String']>>;
}>;

export type AttachRecruiterToAJobMutation = { __typename?: 'Mutation' } & {
  AttachRecruiterToAJob?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
  >;
};

export type CompanyRecruitersQueryVariables = Exact<{
  userIds?: Maybe<Array<Scalars['String']>>;
}>;

export type CompanyRecruitersQuery = { __typename?: 'Query' } & {
  getAllCompanyRecruiters: Array<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      '_id' | 'email' | 'fullName' | 'title'
    >
  >;
};

export type ActivateCandidateMutationVariables = Exact<{
  matchId: Scalars['String'];
  activationScore: Scalars['Int'];
}>;

export type ActivateCandidateMutation = { __typename?: 'Mutation' } & {
  activateCandidate?: Maybe<
    { __typename?: 'Match' } & Pick<
      Match,
      '_id' | 'activationScore' | 'createdAt' | 'jobScore' | 'score'
    >
  >;
};

export type CandidateBenchmarkMutationVariables = Exact<{
  matchId: Scalars['String'];
  benchmark: Scalars['String'];
}>;

export type CandidateBenchmarkMutation = { __typename?: 'Mutation' } & {
  candidateBenchmark?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type CandidateShowCompensationMutationVariables = Exact<{
  matchId: Scalars['String'];
  showCurrentCompensation?: Maybe<Scalars['Boolean']>;
  showExpectedCompensation?: Maybe<Scalars['Boolean']>;
}>;

export type CandidateShowCompensationMutation = { __typename?: 'Mutation' } & {
  candidateShowCompensation?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type CandidateHidePersonalDetailsMutationVariables = Exact<{
  matchId: Scalars['String'];
  hidePersonalDetails?: Maybe<Scalars['Boolean']>;
}>;

export type CandidateHidePersonalDetailsMutation = {
  __typename?: 'Mutation';
} & {
  candidateHidePersonalDetails?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type CandidateNextStepsMutationVariables = Exact<{
  matchId: Scalars['String'];
  nextSteps?: Maybe<Scalars['String']>;
}>;

export type CandidateNextStepsMutation = { __typename?: 'Mutation' } & {
  candidateNextSteps?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type UpdateCandidateMutationVariables = Exact<{
  candidateId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  personalInformation?: Maybe<Scalars['String']>;
  benchMark?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Maybe<SectorsInput>>>;
  professionalInterests?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetLocations?: Maybe<Array<Maybe<TargetLocationsInput>>>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  customTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  yearlyCompensation?: Maybe<YearlyCompensationInput>;
  education?: Maybe<Array<Maybe<EducationInput>>>;
  languages?: Maybe<Array<Maybe<LanguagesInput>>>;
  educationComment?: Maybe<Scalars['String']>;
  languageComments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  inspiringCompanies?: Maybe<Array<Maybe<InspiringCompanyInput>>>;
  sectors?: Maybe<Array<Maybe<SectorsInput>>>;
}>;

export type UpdateCandidateMutation = { __typename?: 'Mutation' } & {
  updateCandidate?: Maybe<
    { __typename?: 'CandidateListing' } & Pick<
      CandidateListing,
      'profilePicture' | 'profileVideo' | 'position'
    > & {
        objectives?: Maybe<
          Array<
            Maybe<
              { __typename?: 'objectivestype' } & Pick<
                Objectivestype,
                '_id' | 'title'
              >
            >
          >
        >;
        experiences?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CandidateExperience' } & Pick<
                CandidateExperience,
                'position' | 'company'
              >
            >
          >
        >;
      }
  >;
};

export type AddCompanyMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  sector: Scalars['String'];
  companySize: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
}>;

export type AddCompanyMutation = { __typename?: 'Mutation' } & {
  registerCompany?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | '_id'
      | 'name'
      | 'description'
      | 'logoUrl'
      | 'website'
      | 'companySize'
      | 'facebook'
      | 'linkedIn'
      | 'twitter'
      | 'isDeleted'
      | 'createdAt'
      | 'updatedAt'
    > & {
        sector?: Maybe<
          { __typename?: 'SectorType' } & Pick<SectorType, 'title'>
        >;
        users?: Maybe<
          Array<
            { __typename?: 'RecruiterUser' } & Pick<
              RecruiterUser,
              '_id' | 'email'
            >
          >
        >;
        createdBy?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
      }
  >;
};

export type AddJobMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  hideSalary?: Maybe<Scalars['Boolean']>;
  countries: Array<Maybe<Scalars['String']>>;
  salaryPackage: Scalars['String'];
  requiredSkills: Array<Maybe<Scalars['String']>>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  experience?: Maybe<Scalars['String']>;
  currencyId: Scalars['String'];
  recruiters?: Maybe<Array<Maybe<Scalars['String']>>>;
  leadRecruiter: Scalars['String'];
  fields: Array<Maybe<Scalars['String']>>;
  contractType: Scalars['String'];
  companyId: Scalars['String'];
  targetCompanies?: Maybe<Array<Maybe<Scalars['String']>>>;
  jobId?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  AnonymousCompanyTitle?: Maybe<Scalars['String']>;
  AnonymousCompanyDescription?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  mobileBanner?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  yearlyCompensation?: Maybe<YearlyCompensationInputJob>;
  assessment?: Maybe<AssessmentInput>;
  progressReport?: Maybe<Scalars['String']>;
}>;

export type AddJobMutation = { __typename?: 'Mutation' } & {
  createJob?: Maybe<
    { __typename?: 'Job' } & Pick<
      Job,
      | '_id'
      | 'title'
      | 'hideSalary'
      | 'experience'
      | 'salaryPackage'
      | 'description'
      | 'createdAt'
      | 'status'
      | 'isAnonymous'
      | 'AnonymousCompanyTitle'
      | 'AnonymousCompanyDescription'
      | 'banner'
      | 'mobileBanner'
      | 'progressReport'
    > & {
        countries: Array<
          { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
        >;
        requiredSkills: Array<
          { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
        >;
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            '_id' | 'name' | 'logoUrl'
          > & {
              users?: Maybe<
                Array<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    '_id' | 'email'
                  >
                >
              >;
            }
        >;
        recruiters?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email' | 'roles'
              >
            >
          >
        >;
        leadRecruiter?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
        fields: Array<
          Maybe<{ __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>>
        >;
        contractType: { __typename?: 'ContractType' } & Pick<
          ContractType,
          '_id' | 'name'
        >;
        targetCompanies: Array<
          { __typename?: 'inspiringCompany' } & Pick<
            InspiringCompany,
            '_id' | 'name'
          >
        >;
        currency?: Maybe<
          { __typename?: 'Currency' } & Pick<
            Currency,
            '_id' | 'name' | 'symbol'
          >
        >;
        candidates?: Maybe<
          Array<{ __typename?: 'Match' } & Pick<Match, '_id'>>
        >;
        yearlyCompensation?: Maybe<
          { __typename?: 'yearlyCompensationInputJobView' } & Pick<
            YearlyCompensationInputJobView,
            | 'currency'
            | 'total'
            | 'base'
            | 'onTargetEarnings'
            | 'shortTermIncentives'
            | 'longTermIncentives'
            | 'other'
            | 'comments'
          >
        >;
        assessment?: Maybe<
          { __typename?: 'AssessmentView' } & {
            experience?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'assessmentInputStructure' } & Pick<
                    AssessmentInputStructure,
                    'title' | 'description'
                  >
                >
              >
            >;
            traits?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'assessmentInputStructure' } & Pick<
                    AssessmentInputStructure,
                    'title' | 'description'
                  >
                >
              >
            >;
          }
        >;
      }
  >;
};

export type AllCandidatesInBoQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Scalars['String']>;
  dtFrom?: Maybe<Scalars['String']>;
  dtTo?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type AllCandidatesInBoQuery = { __typename?: 'Query' } & {
  getAllCandidatesInBO?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CandidateList' } & Pick<
          CandidateList,
          | '_id'
          | 'firstName'
          | 'lastName'
          | 'title'
          | 'company'
          | 'position'
          | 'resume'
          | 'createdAt'
          | 'location'
          | 'email'
          | 'source'
          | 'userSource'
          | 'isProfileCompleted'
          | 'userIsProfileCompleted'
          | 'skills'
          | 'count'
        > & {
            tags?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>
                >
              >
            >;
          }
      >
    >
  >;
};

export type CountAllCandidatesInBoQueryVariables = Exact<{
  searchText?: Maybe<Scalars['String']>;
  searchType?: Maybe<Scalars['String']>;
}>;

export type CountAllCandidatesInBoQuery = { __typename?: 'Query' } & {
  countAllCandidatesInBO?: Maybe<
    { __typename?: 'countAllCandidates' } & Pick<CountAllCandidates, 'count'>
  >;
};

export type AllRecruitersQueryVariables = Exact<{ [key: string]: never }>;

export type AllRecruitersQuery = { __typename?: 'Query' } & {
  getAllRecruiters: Array<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      '_id' | 'email' | 'fullName' | 'title'
    >
  >;
};

export type AllRecruitersExceptQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>;
}>;

export type AllRecruitersExceptQuery = { __typename?: 'Query' } & {
  getAllRecruitersExcept: Array<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      '_id' | 'email' | 'fullName' | 'title' | 'userId'
    >
  >;
};

export type GenericResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  resetCode: Scalars['Int'];
  bypassResetCode: Scalars['Boolean'];
}>;

export type GenericResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
  >;
};

export type LoginResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  resetCode: Scalars['Int'];
  bypassResetCode: Scalars['Boolean'];
}>;

export type LoginResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
  >;
};

export type VerifyResetCodeMutationVariables = Exact<{
  email: Scalars['String'];
  resetCode: Scalars['String'];
}>;

export type VerifyResetCodeMutation = { __typename?: 'Mutation' } & {
  verifyResetCode?: Maybe<
    { __typename?: 'ForgotPasswordReaponse' } & Pick<
      ForgotPasswordReaponse,
      'message' | 'status' | 'code'
    >
  >;
};

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ForgetPasswordMutation = { __typename?: 'Mutation' } & {
  forgetPassword?: Maybe<
    { __typename?: 'ForgotPasswordReaponse' } & Pick<
      ForgotPasswordReaponse,
      'message' | 'status' | 'email'
    >
  >;
};

export type GenericResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
  newEmail: Scalars['String'];
}>;

export type GenericResetEmailMutation = { __typename?: 'Mutation' } & {
  resetEmail?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
  >;
};

export type GetAdminsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAdminsQuery = { __typename?: 'Query' } & {
  allAdmins: Array<
    Maybe<
      { __typename?: 'AdminUser' } & Pick<
        AdminUser,
        '_id' | 'fullName' | 'userId'
      >
    >
  >;
};

export type GetCandidatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCandidatesQuery = { __typename?: 'Query' } & {
  allCandidates: Array<
    Maybe<
      { __typename?: 'CandidateListing' } & Pick<
        CandidateListing,
        '_id' | 'firstName' | 'lastName' | 'userId'
      >
    >
  >;
};

export type GetPersonalInterestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPersonalInterestsQuery = { __typename?: 'Query' } & {
  getPersonalInterests: Array<
    { __typename?: 'personalInterest' } & Pick<
      PersonalInterest,
      '_id' | 'title'
    >
  >;
};

export type GetRecruitersQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecruitersQuery = { __typename?: 'Query' } & {
  allRecruiters: Array<
    Maybe<
      { __typename?: 'RecruiterUser' } & Pick<
        RecruiterUser,
        '_id' | 'fullName' | 'userId'
      >
    >
  >;
};

export type CompanyJobsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CompanyJobsQuery = { __typename?: 'Query' } & {
  getCompanyJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          | '_id'
          | 'title'
          | 'hideSalary'
          | 'salaryPackage'
          | 'experience'
          | 'description'
          | 'createdAt'
          | 'status'
          | 'favouriteBy'
        > & {
            countries: Array<
              { __typename?: 'Country' } & Pick<
                Country,
                '_id' | 'name' | 'code'
              >
            >;
            requiredSkills: Array<
              { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
            >;
            company?: Maybe<
              { __typename?: 'Company' } & Pick<Company, '_id' | 'name'>
            >;
            currency?: Maybe<
              { __typename?: 'Currency' } & Pick<
                Currency,
                '_id' | 'name' | 'symbol'
              >
            >;
            leadRecruiter?: Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email'
              >
            >;
            fields: Array<
              Maybe<
                { __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>
              >
            >;
            contractType: { __typename?: 'ContractType' } & Pick<
              ContractType,
              '_id' | 'name'
            >;
            targetCompanies: Array<
              { __typename?: 'inspiringCompany' } & Pick<
                InspiringCompany,
                '_id' | 'name'
              >
            >;
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'email' | 'roles'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type ContractTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ContractTypesQuery = { __typename?: 'Query' } & {
  contracts: Array<
    { __typename?: 'ContractType' } & Pick<ContractType, '_id' | 'name'>
  >;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = { __typename?: 'Query' } & {
  countries: Array<
    { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
  >;
};

export type CrawliesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type CrawliesQuery = { __typename?: 'Query' } & {
  crawliesPersonalDetails?: Maybe<
    Array<
      Maybe<
        { __typename?: 'crawliesPersonalDetail' } & Pick<
          CrawliesPersonalDetail,
          | '_id'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'phone'
          | 'createdAt'
          | 'count'
          | 'title'
          | 'CV'
          | 'company'
          | 'linkedIn'
          | 'location'
        > & {
            tags?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>
                >
              >
            >;
          }
      >
    >
  >;
};

export type CreateCandidateAndMatchMutationVariables = Exact<{
  jobId: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  summary: Scalars['String'];
  position: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  profilePicture: Scalars['String'];
  resume: Scalars['String'];
  profileVideo?: Maybe<Scalars['String']>;
  coverLetter?: Maybe<Scalars['String']>;
  skills: Array<Scalars['String']>;
  customSkills?: Maybe<Array<Maybe<Scalars['String']>>>;
  media: Scalars['String'];
  action: Scalars['String'];
  experiences?: Maybe<Array<Maybe<ExperiencesInput>>>;
  educations?: Maybe<Array<Maybe<EducationInput>>>;
  currentLocation?: Maybe<LocationInput>;
  resumeName?: Maybe<Scalars['String']>;
  coverLetterName?: Maybe<Scalars['String']>;
}>;

export type CreateCandidateAndMatchMutation = { __typename?: 'Mutation' } & {
  createCandidateAndMatch?: Maybe<
    { __typename?: 'createCandidateAndMatchResponse' } & Pick<
      CreateCandidateAndMatchResponse,
      '_id' | 'email' | 'roles' | 'token'
    > & {
        candidate?: Maybe<
          { __typename?: 'CandidateListingProfile' } & Pick<
            CandidateListingProfile,
            | '_id'
            | 'resume'
            | 'firstName'
            | 'lastName'
            | 'description'
            | 'title'
            | 'profilePicture'
          > & {
              experiences?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CandidateExperience' } & Pick<
                      CandidateExperience,
                      | 'position'
                      | 'company'
                      | 'startYear'
                      | 'endYear'
                      | 'current'
                    >
                  >
                >
              >;
              education?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CandidateEducation' } & Pick<
                      CandidateEducation,
                      | 'schoolName'
                      | 'degree'
                      | 'startYear'
                      | 'current'
                      | 'endYear'
                    >
                  >
                >
              >;
              objectives?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'objectivestype' } & Pick<
                      Objectivestype,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              professionalInterests?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'professionalIntereststype' } & Pick<
                      ProfessionalIntereststype,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              skills?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'skillsType' } & Pick<
                      SkillsType,
                      '_id' | 'title'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type CreateJobLandingPageMutationVariables = Exact<{
  jobId: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  leftContent?: Maybe<Scalars['String']>;
  rightContent?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  customCss?: Maybe<Scalars['String']>;
  cssLink?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  faviconUrl?: Maybe<Scalars['String']>;
  metaImageUrl?: Maybe<Scalars['String']>;
  jobLandingPageId?: Maybe<Scalars['String']>;
}>;

export type CreateJobLandingPageMutation = { __typename?: 'Mutation' } & {
  createJobLandingPage?: Maybe<
    { __typename?: 'JobLandingPage' } & Pick<
      JobLandingPage,
      | '_id'
      | 'jobId'
      | 'header'
      | 'content'
      | 'leftContent'
      | 'rightContent'
      | 'metaTitle'
      | 'metaDescription'
      | 'customCss'
      | 'cssLink'
      | 'footer'
      | 'pageTitle'
      | 'faviconUrl'
      | 'metaImageUrl'
      | 'isDeleted'
    >
  >;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = { __typename?: 'Query' } & {
  currencies: Array<
    { __typename?: 'Currency' } & Pick<Currency, '_id' | 'name' | 'symbol'>
  >;
};

export type DeleteJobMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
}>;

export type DeleteJobMutation = { __typename?: 'Mutation' } & {
  deleteJob?: Maybe<
    { __typename?: 'Job' } & Pick<
      Job,
      | '_id'
      | 'title'
      | 'hideSalary'
      | 'experience'
      | 'salaryPackage'
      | 'description'
      | 'createdAt'
      | 'status'
      | 'favouriteBy'
    > & {
        countries: Array<
          { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
        >;
        requiredSkills: Array<
          { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
        >;
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            '_id' | 'name' | 'logoUrl'
          > & {
              users?: Maybe<
                Array<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    '_id' | 'email'
                  >
                >
              >;
            }
        >;
        recruiters?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email' | 'roles'
              >
            >
          >
        >;
        leadRecruiter?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
        fields: Array<
          Maybe<{ __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>>
        >;
        contractType: { __typename?: 'ContractType' } & Pick<
          ContractType,
          '_id' | 'name'
        >;
        targetCompanies: Array<
          { __typename?: 'inspiringCompany' } & Pick<
            InspiringCompany,
            '_id' | 'name'
          >
        >;
        currency?: Maybe<
          { __typename?: 'Currency' } & Pick<
            Currency,
            '_id' | 'name' | 'symbol'
          >
        >;
      }
  >;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    { __typename?: 'CurrentUser' } & Pick<CurrentUser, '_id' | 'email'>
  >;
};

export type GetAllMatchQueryVariables = Exact<{
  ListingId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
}>;

export type GetAllMatchQuery = { __typename?: 'Query' } & {
  getAllMatch: Array<
    { __typename?: 'getAllMatchData' } & Pick<
      GetAllMatchData,
      '_id' | 'recruiterAction' | 'candidateAction'
    > & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<Company, '_id' | 'name'>
        >;
        job?: Maybe<
          { __typename?: 'Job' } & Pick<Job, '_id' | 'title' | 'createdAt'>
        >;
        country: Array<
          { __typename?: 'countryName' } & Pick<CountryName, 'name'>
        >;
      }
  >;
};

export type GetJobLandingPageQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GetJobLandingPageQuery = { __typename?: 'Query' } & {
  getJobLandingPage?: Maybe<
    { __typename?: 'JobLandingPage' } & Pick<
      JobLandingPage,
      | '_id'
      | 'jobId'
      | 'header'
      | 'content'
      | 'leftContent'
      | 'rightContent'
      | 'metaTitle'
      | 'metaDescription'
      | 'customCss'
      | 'cssLink'
      | 'footer'
      | 'pageTitle'
      | 'faviconUrl'
      | 'metaImageUrl'
      | 'isDeleted'
    >
  >;
};

export type GetMatchQueryVariables = Exact<{
  matchId: Scalars['String'];
}>;

export type GetMatchQuery = { __typename?: 'Query' } & {
  getMatch?: Maybe<
    { __typename?: 'Match' } & Pick<
      Match,
      | '_id'
      | 'benchmark'
      | 'showCurrentCompensation'
      | 'showExpectedCompensation'
      | 'hidePersonalDetails'
      | 'nextSteps'
      | 'score'
      | 'jobScore'
      | 'activationScore'
      | 'recruiterAction'
      | 'candidateAction'
      | 'bypass'
      | 'media'
      | 'isDeleted'
      | 'createdAt'
      | 'updatedAt'
    > & {
        candidateListing?: Maybe<
          { __typename?: 'CandidateListing' } & Pick<
            CandidateListing,
            | '_id'
            | 'firstName'
            | 'lastName'
            | 'position'
            | 'description'
            | 'personalInformation'
            | 'benchMark'
            | 'totalExperience'
            | 'educationComment'
            | 'attachmentPreview'
            | 'ResumePreview'
            | 'languageComments'
            | 'resume'
            | 'coverLetter'
            | 'isPublic'
            | 'profilePicture'
            | 'coverPhoto'
            | 'profileVideo'
            | 'targetRoles'
            | 'desiredSalaryPackage'
            | 'currentSalaryPackage'
            | 'isOnlyNetworker'
            | 'isDeleted'
            | 'token'
            | 'subscriptionPlanId'
            | 'createdAt'
            | 'updatedAt'
            | 'appliedJobs'
            | 'contractIdType'
            | 'company'
            | 'userId'
            | 'isProfileCompleted'
            | 'twitter'
            | 'linkedIn'
            | 'facebook'
            | 'github'
          > & {
              experiences?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CandidateExperience' } & Pick<
                      CandidateExperience,
                      | '_id'
                      | 'position'
                      | 'company'
                      | 'startYear'
                      | 'endYear'
                      | 'current'
                      | 'logo'
                      | 'sector'
                      | 'jobField'
                      | 'reasonForChange'
                      | 'comments'
                    > & {
                        location?: Maybe<
                          {
                            __typename?: 'locationTypeEducationAndExperience';
                          } & Pick<
                            LocationTypeEducationAndExperience,
                            'country' | 'city' | 'code'
                          > & {
                              coordinates?: Maybe<
                                { __typename?: 'coordinatesType' } & Pick<
                                  CoordinatesType,
                                  'lat' | 'lng'
                                >
                              >;
                            }
                        >;
                        commentData?: Maybe<
                          { __typename?: 'ExperienceCommentData' } & Pick<
                            ExperienceCommentData,
                            | 'candidateId'
                            | 'company'
                            | 'comments'
                            | 'reasonForChange'
                          >
                        >;
                      }
                  >
                >
              >;
              targetLocations?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'TargetLocations' } & Pick<
                      TargetLocations,
                      'city' | 'name'
                    >
                  >
                >
              >;
              languages?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Languages' } & Pick<
                      Languages,
                      'language' | 'level'
                    >
                  >
                >
              >;
              education?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'CandidateEducation' } & Pick<
                      CandidateEducation,
                      | '_id'
                      | 'schoolName'
                      | 'degree'
                      | 'startYear'
                      | 'current'
                      | 'logo'
                      | 'endYear'
                    > & {
                        location?: Maybe<
                          {
                            __typename?: 'locationTypeEducationAndExperience';
                          } & Pick<
                            LocationTypeEducationAndExperience,
                            'country' | 'city' | 'code'
                          > & {
                              coordinates?: Maybe<
                                { __typename?: 'coordinatesType' } & Pick<
                                  CoordinatesType,
                                  'lat' | 'lng'
                                >
                              >;
                            }
                        >;
                      }
                  >
                >
              >;
              currentLocation?: Maybe<
                { __typename?: 'location' } & Pick<
                  Location,
                  'country' | 'city' | 'code' | 'coordinates'
                >
              >;
              objectives?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'objectivestype' } & Pick<
                      Objectivestype,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              professionalInterests?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'professionalIntereststype' } & Pick<
                      ProfessionalIntereststype,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              tags?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'tagsType' } & Pick<
                      TagsType,
                      '_id' | 'name' | 'color'
                    >
                  >
                >
              >;
              sectors?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'SectorsType' } & Pick<
                      SectorsType,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              inspiringCompanies?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'InspiringCompanyType' } & Pick<
                      InspiringCompanyType,
                      '_id' | 'name'
                    >
                  >
                >
              >;
              skills?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'skillsType' } & Pick<
                      SkillsType,
                      '_id' | 'title'
                    >
                  >
                >
              >;
              user?: Maybe<
                { __typename?: 'User' } & Pick<User, '_id' | 'email'>
              >;
              yearlyCompensation?: Maybe<
                { __typename?: 'YearlyCompensation' } & Pick<
                  YearlyCompensation,
                  'currency' | 'comments' | 'currentComments'
                > & {
                    current?: Maybe<
                      { __typename?: 'Compensation' } & Pick<
                        Compensation,
                        | 'base'
                        | 'onTargetEarnings'
                        | 'shortTermIncentives'
                        | 'longTermIncentives'
                        | 'other'
                        | 'total'
                      >
                    >;
                    expected?: Maybe<
                      { __typename?: 'Compensation' } & Pick<
                        Compensation,
                        | 'base'
                        | 'onTargetEarnings'
                        | 'shortTermIncentives'
                        | 'longTermIncentives'
                        | 'other'
                        | 'total'
                      >
                    >;
                  }
              >;
            }
        >;
        AssessmentReport?: Maybe<
          { __typename?: 'getAssessments' } & Pick<
            GetAssessments,
            '_id' | 'matchId' | 'hideExperience' | 'hideTraits' | 'hideDrivers'
          > & {
              experience?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'AssessmentView' } & Pick<
                      AssessmentView,
                      'title' | 'level' | 'comment'
                    >
                  >
                >
              >;
              traits?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'AssessmentView' } & Pick<
                      AssessmentView,
                      'title' | 'level' | 'comment'
                    >
                  >
                >
              >;
              drivers?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'AssessmentView' } & Pick<
                      AssessmentView,
                      'title' | 'level' | 'comment'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type InspiringCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type InspiringCompaniesQuery = { __typename?: 'Query' } & {
  inspiringCompanies?: Maybe<
    Array<
      Maybe<
        { __typename?: 'inspiringCompany' } & Pick<
          InspiringCompany,
          '_id' | 'name'
        >
      >
    >
  >;
};

export type JobFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type JobFieldsQuery = { __typename?: 'Query' } & {
  jobFields: Array<
    { __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'> & {
        parent?: Maybe<
          { __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>
        >;
      }
  >;
};

export type AdminLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type AdminLoginMutation = { __typename?: 'Mutation' } & {
  adminLogin?: Maybe<
    { __typename?: 'AdminUser' } & Pick<
      AdminUser,
      | '_id'
      | 'email'
      | 'userId'
      | 'fullName'
      | 'title'
      | 'roles'
      | 'verified'
      | 'token'
    >
  >;
};

export type MatchesForAJobQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type MatchesForAJobQuery = { __typename?: 'Query' } & {
  matchesForAJob: Array<
    { __typename?: 'Match' } & Pick<
      Match,
      | '_id'
      | 'score'
      | 'jobScore'
      | 'activationScore'
      | 'recruiterAction'
      | 'candidateAction'
      | 'bypass'
      | 'media'
      | 'isDeleted'
      | 'createdAt'
      | 'updatedAt'
    > & {
        jobListing?: Maybe<{ __typename?: 'Job' } & Pick<Job, '_id'>>;
        candidateListing?: Maybe<
          { __typename?: 'CandidateListing' } & Pick<
            CandidateListing,
            | '_id'
            | 'userId'
            | 'firstName'
            | 'lastName'
            | 'resume'
            | 'ResumePreview'
            | 'attachmentPreview'
            | 'position'
            | 'company'
            | 'title'
            | 'isProfileCompleted'
          > & {
              tags?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'tagsType' } & Pick<
                      TagsType,
                      '_id' | 'name' | 'color'
                    >
                  >
                >
              >;
              user?: Maybe<{ __typename?: 'User' } & Pick<User, 'email'>>;
              countries?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Country' } & Pick<Country, 'name' | 'code'>
                  >
                >
              >;
              location?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'location' } & Pick<
                      Location,
                      'coordinates' | 'country' | 'city' | 'code'
                    >
                  >
                >
              >;
            }
        >;
        matchingAlgoScores?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MatchingAlgorithmScores' } & Pick<
                MatchingAlgorithmScores,
                'candidateScore' | 'jobScore'
              >
            >
          >
        >;
      }
  >;
};

export type CreatedMatchSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CreatedMatchSubscription = { __typename?: 'Subscription' } & {
  createdMatch?: Maybe<
    { __typename?: 'Match' } & Pick<
      Match,
      | '_id'
      | 'score'
      | 'jobScore'
      | 'activationScore'
      | 'recruiterAction'
      | 'candidateAction'
      | 'bypass'
      | 'media'
      | 'isDeleted'
      | 'createdAt'
      | 'updatedAt'
    > & {
        candidateListing?: Maybe<
          { __typename?: 'CandidateListing' } & Pick<
            CandidateListing,
            | '_id'
            | 'userId'
            | 'firstName'
            | 'lastName'
            | 'resume'
            | 'ResumePreview'
            | 'attachmentPreview'
            | 'position'
            | 'isProfileCompleted'
          > & {
              user?: Maybe<{ __typename?: 'User' } & Pick<User, 'email'>>;
              countries?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Country' } & Pick<Country, 'name' | 'code'>
                  >
                >
              >;
              currentLocation?: Maybe<
                { __typename?: 'location' } & Pick<
                  Location,
                  'coordinates' | 'country' | 'city' | 'code'
                >
              >;
            }
        >;
      }
  >;
};

export type ObjectivesQueryVariables = Exact<{ [key: string]: never }>;

export type ObjectivesQuery = { __typename?: 'Query' } & {
  getObjectives: Array<
    { __typename?: 'Objectives' } & Pick<Objectives, '_id' | 'title'>
  >;
};

export type PersonalInterestQueryVariables = Exact<{ [key: string]: never }>;

export type PersonalInterestQuery = { __typename?: 'Query' } & {
  getPersonalInterests: Array<
    { __typename?: 'personalInterest' } & Pick<
      PersonalInterest,
      '_id' | 'title'
    >
  >;
};

export type SendMessageMutationVariables = Exact<{
  matchId: Scalars['String'];
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;

export type SendMessageMutation = { __typename?: 'Mutation' } & {
  messageTriggerMailRecruiter?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type SendMessageToCandidateMutationVariables = Exact<{
  sendTo: Scalars['String'];
  myRole: Scalars['String'];
  message: Scalars['String'];
  payload?: Maybe<MessagePayload>;
}>;

export type SendMessageToCandidateMutation = { __typename?: 'Mutation' } & {
  sendMessage?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
  >;
};

export type RecruiterResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type RecruiterResetPasswordMutation = { __typename?: 'Mutation' } & {
  recruiterResetPassword?: Maybe<
    { __typename?: 'RecruiterUser' } & Pick<RecruiterUser, 'email'> & {
        response?: Maybe<
          { __typename?: 'Response' } & Pick<Response, 'message' | 'status'>
        >;
      }
  >;
};

export type RecruiterSignupMutationVariables = Exact<{
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  company: Scalars['String'];
  location: UpdateLocationExperienceAndEducation;
}>;

export type RecruiterSignupMutation = { __typename?: 'Mutation' } & {
  recruiterSignup?: Maybe<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      | '_id'
      | 'userId'
      | 'title'
      | 'subscriptionPlanId'
      | 'fullName'
      | 'email'
      | 'token'
    >
  >;
};

export type RecruiterSignupForACompanyMutationVariables = Exact<{
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
  companyId: Scalars['String'];
}>;

export type RecruiterSignupForACompanyMutation = { __typename?: 'Mutation' } & {
  recruiterSignupForACompany?: Maybe<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      | '_id'
      | 'userId'
      | 'title'
      | 'subscriptionPlanId'
      | 'fullName'
      | 'email'
      | 'token'
    >
  >;
};

export type RemoveRecruiterFromACompanyMutationVariables = Exact<{
  userId: Scalars['String'];
  companyId: Scalars['String'];
}>;

export type RemoveRecruiterFromACompanyMutation = {
  __typename?: 'Mutation';
} & {
  removeRecruiterFromACompany?: Maybe<
    { __typename?: 'RecruiterUser' } & Pick<
      RecruiterUser,
      | '_id'
      | 'userId'
      | 'title'
      | 'subscriptionPlanId'
      | 'fullName'
      | 'email'
      | 'token'
    >
  >;
};

export type SectorsQueryVariables = Exact<{ [key: string]: never }>;

export type SectorsQuery = { __typename?: 'Query' } & {
  sectors?: Maybe<
    Array<
      Maybe<{ __typename?: 'SectorType' } & Pick<SectorType, '_id' | 'title'>>
    >
  >;
};

export type AdminSignupMutationVariables = Exact<{
  fullName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  title: Scalars['String'];
}>;

export type AdminSignupMutation = { __typename?: 'Mutation' } & {
  adminSignup?: Maybe<
    { __typename?: 'AdminUser' } & Pick<
      AdminUser,
      '_id' | 'fullName' | 'email' | 'title' | 'token'
    >
  >;
};

export type GetCandidateQueryVariables = Exact<{
  candidateId: Scalars['String'];
}>;

export type GetCandidateQuery = { __typename?: 'Query' } & {
  getCandidate?: Maybe<
    { __typename?: 'CandidateListingProfile' } & Pick<
      CandidateListingProfile,
      | '_id'
      | 'firstName'
      | 'lastName'
      | 'position'
      | 'description'
      | 'totalExperience'
      | 'educationComment'
      | 'benchMark'
      | 'personalInformation'
      | 'languageComments'
      | 'resume'
      | 'coverLetter'
      | 'isPublic'
      | 'profilePicture'
      | 'coverPhoto'
      | 'profileVideo'
      | 'targetRoles'
      | 'desiredSalaryPackage'
      | 'currentSalaryPackage'
      | 'isOnlyNetworker'
      | 'isDeleted'
      | 'token'
      | 'subscriptionPlanId'
      | 'createdAt'
      | 'updatedAt'
      | 'appliedJobs'
      | 'contractIdType'
      | 'company'
      | 'userId'
      | 'isProfileCompleted'
      | 'nextSteps'
      | 'twitter'
      | 'linkedIn'
      | 'facebook'
      | 'github'
    > & {
        experiences?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CandidateExperience' } & Pick<
                CandidateExperience,
                | '_id'
                | 'position'
                | 'company'
                | 'startYear'
                | 'endYear'
                | 'logo'
                | 'current'
                | 'sector'
                | 'jobField'
                | 'reasonForChange'
                | 'comments'
              > & {
                  location?: Maybe<
                    {
                      __typename?: 'locationTypeEducationAndExperience';
                    } & Pick<
                      LocationTypeEducationAndExperience,
                      'country' | 'city' | 'code'
                    > & {
                        coordinates?: Maybe<
                          { __typename?: 'coordinatesType' } & Pick<
                            CoordinatesType,
                            'lat' | 'lng'
                          >
                        >;
                      }
                  >;
                  commentData?: Maybe<
                    { __typename?: 'ExperienceCommentData' } & Pick<
                      ExperienceCommentData,
                      'candidateId' | 'company' | 'comments' | 'reasonForChange'
                    >
                  >;
                }
            >
          >
        >;
        targetLocations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CandidateTargetLocation' } & Pick<
                CandidateTargetLocation,
                'city' | 'name'
              >
            >
          >
        >;
        languages?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Languages' } & Pick<
                Languages,
                'language' | 'level'
              >
            >
          >
        >;
        education?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CandidateEducation' } & Pick<
                CandidateEducation,
                | '_id'
                | 'schoolName'
                | 'degree'
                | 'startYear'
                | 'current'
                | 'endYear'
                | 'logo'
              > & {
                  location?: Maybe<
                    {
                      __typename?: 'locationTypeEducationAndExperience';
                    } & Pick<
                      LocationTypeEducationAndExperience,
                      'country' | 'city' | 'code'
                    > & {
                        coordinates?: Maybe<
                          { __typename?: 'coordinatesType' } & Pick<
                            CoordinatesType,
                            'lat' | 'lng'
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
        currentLocation?: Maybe<
          { __typename?: 'location' } & Pick<
            Location,
            'country' | 'city' | 'code' | 'coordinates'
          >
        >;
        objectives?: Maybe<
          Array<
            Maybe<
              { __typename?: 'objectivestype' } & Pick<
                Objectivestype,
                '_id' | 'title'
              >
            >
          >
        >;
        professionalInterests?: Maybe<
          Array<
            Maybe<
              { __typename?: 'professionalIntereststype' } & Pick<
                ProfessionalIntereststype,
                '_id' | 'title'
              >
            >
          >
        >;
        tags?: Maybe<
          Array<
            Maybe<
              { __typename?: 'tagsType' } & Pick<
                TagsType,
                '_id' | 'name' | 'color'
              >
            >
          >
        >;
        sectors?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SectorsType' } & Pick<
                SectorsType,
                '_id' | 'title'
              >
            >
          >
        >;
        skills?: Maybe<
          Array<
            Maybe<
              { __typename?: 'skillsType' } & Pick<SkillsType, '_id' | 'title'>
            >
          >
        >;
        inspiringCompanies?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InspiringCompanyType' } & Pick<
                InspiringCompanyType,
                '_id' | 'name'
              >
            >
          >
        >;
        user?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'email'>>;
        yearlyCompensation?: Maybe<
          { __typename?: 'YearlyCompensation' } & Pick<
            YearlyCompensation,
            'currency' | 'comments' | 'currentComments'
          > & {
              current?: Maybe<
                { __typename?: 'Compensation' } & Pick<
                  Compensation,
                  | 'base'
                  | 'onTargetEarnings'
                  | 'shortTermIncentives'
                  | 'longTermIncentives'
                  | 'other'
                  | 'total'
                >
              >;
              expected?: Maybe<
                { __typename?: 'Compensation' } & Pick<
                  Compensation,
                  | 'base'
                  | 'onTargetEarnings'
                  | 'shortTermIncentives'
                  | 'longTermIncentives'
                  | 'other'
                  | 'total'
                >
              >;
            }
        >;
      }
  >;
};

export type GetSingleCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;

export type GetSingleCompanyQuery = { __typename?: 'Query' } & {
  getSingleCompany?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | '_id'
      | 'name'
      | 'description'
      | 'website'
      | 'companySize'
      | 'logoUrl'
      | 'createdAt'
      | 'facebook'
      | 'linkedIn'
      | 'twitter'
      | 'totalJobs'
    > & {
        sector?: Maybe<
          { __typename?: 'SectorType' } & Pick<SectorType, 'title'>
        >;
        createdBy?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
        users?: Maybe<
          Array<
            { __typename?: 'RecruiterUser' } & Pick<
              RecruiterUser,
              '_id' | 'email'
            >
          >
        >;
        jobs?: Maybe<
          Array<{ __typename?: 'Job' } & Pick<Job, '_id' | 'title'>>
        >;
      }
  >;
};

export type GetSingleJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GetSingleJobQuery = { __typename?: 'Query' } & {
  getJobDetails?: Maybe<
    { __typename?: 'Job' } & Pick<
      Job,
      | '_id'
      | 'title'
      | 'hideSalary'
      | 'experience'
      | 'salaryPackage'
      | 'description'
      | 'createdAt'
      | 'status'
      | 'favouriteBy'
      | 'isAnonymous'
      | 'AnonymousCompanyTitle'
      | 'AnonymousCompanyDescription'
      | 'banner'
      | 'mobileBanner'
      | 'language'
      | 'progressReport'
    > & {
        countries: Array<
          { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
        >;
        requiredSkills: Array<
          { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
        >;
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            '_id' | 'name' | 'logoUrl'
          > & {
              users?: Maybe<
                Array<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    '_id' | 'email'
                  >
                >
              >;
            }
        >;
        recruiters?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email' | 'roles'
              >
            >
          >
        >;
        leadRecruiter?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email' | 'fullName'
          >
        >;
        fields: Array<
          Maybe<{ __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>>
        >;
        contractType: { __typename?: 'ContractType' } & Pick<
          ContractType,
          '_id' | 'name'
        >;
        targetCompanies: Array<
          { __typename?: 'inspiringCompany' } & Pick<
            InspiringCompany,
            '_id' | 'name'
          >
        >;
        currency?: Maybe<
          { __typename?: 'Currency' } & Pick<
            Currency,
            '_id' | 'name' | 'symbol'
          >
        >;
        candidates?: Maybe<
          Array<
            { __typename?: 'Match' } & Pick<Match, '_id'> & {
                candidateListing?: Maybe<
                  { __typename?: 'CandidateListing' } & Pick<
                    CandidateListing,
                    '_id' | 'firstName' | 'lastName'
                  >
                >;
              }
          >
        >;
        yearlyCompensation?: Maybe<
          { __typename?: 'yearlyCompensationInputJobView' } & Pick<
            YearlyCompensationInputJobView,
            | 'currency'
            | 'total'
            | 'base'
            | 'onTargetEarnings'
            | 'shortTermIncentives'
            | 'longTermIncentives'
            | 'other'
            | 'comments'
          >
        >;
        assessment?: Maybe<
          { __typename?: 'AssessmentView' } & {
            experience?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'assessmentInputStructure' } & Pick<
                    AssessmentInputStructure,
                    'title' | 'description'
                  >
                >
              >
            >;
            traits?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'assessmentInputStructure' } & Pick<
                    AssessmentInputStructure,
                    'title' | 'description'
                  >
                >
              >
            >;
          }
        >;
      }
  >;
};

export type GetSingleRecruiterQueryVariables = Exact<{ [key: string]: never }>;

export type GetSingleRecruiterQuery = { __typename?: 'Query' } & {
  getSingleRecruiter?: Maybe<
    { __typename?: 'RecruiterBOUser' } & Pick<
      RecruiterBoUser,
      '_id' | 'fullName' | 'profilePic' | 'title' | 'createdAt' | 'description'
    > & {
        user?: Maybe<{ __typename?: 'UserObj' } & Pick<UserObj, 'email'>>;
        educations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterEducation' } & Pick<
                RecruiterEducation,
                'schoolName' | 'degree' | 'startYear' | 'current' | 'endYear'
              >
            >
          >
        >;
        experiences?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterExperience' } & Pick<
                RecruiterExperience,
                | 'position'
                | 'company'
                | 'startYear'
                | 'endYear'
                | 'current'
                | 'sector'
                | 'jobField'
              >
            >
          >
        >;
      }
  >;
};

export type SkillsQueryVariables = Exact<{
  status?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isBO?: Maybe<Scalars['Boolean']>;
}>;

export type SkillsQuery = { __typename?: 'Query' } & {
  skills: Array<
    { __typename?: 'Skills' } & Pick<
      Skills,
      | '_id'
      | 'title'
      | 'isArchived'
      | 'isDeleted'
      | 'createdBy'
      | 'status'
      | 'userType'
      | 'createdAt'
    > & {
        jobsCount?: Maybe<
          Array<Maybe<{ __typename?: 'Job' } & Pick<Job, '_id'>>>
        >;
        candidatesCount?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CandidateListing' } & Pick<
                CandidateListing,
                '_id'
              >
            >
          >
        >;
      }
  >;
};

export type AddSkillsMutationVariables = Exact<{
  title: Array<Scalars['String']>;
}>;

export type AddSkillsMutation = { __typename?: 'Mutation' } & {
  createSkills: Array<
    { __typename?: 'Skills' } & Pick<
      Skills,
      | '_id'
      | 'title'
      | 'isArchived'
      | 'isDeleted'
      | 'createdBy'
      | 'userType'
      | 'createdAt'
    >
  >;
};

export type UpdateSkillMutationVariables = Exact<{
  skillId: Scalars['String'];
  title: Scalars['String'];
}>;

export type UpdateSkillMutation = { __typename?: 'Mutation' } & {
  updateSkill?: Maybe<
    { __typename?: 'Skills' } & Pick<
      Skills,
      '_id' | 'title' | 'isArchived' | 'isDeleted'
    >
  >;
};

export type UpdateStatusMutationVariables = Exact<{
  skillId: Scalars['String'];
  status: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateStatusMutation = { __typename?: 'Mutation' } & {
  updateStatus?: Maybe<
    { __typename?: 'Skills' } & Pick<
      Skills,
      '_id' | 'title' | 'isArchived' | 'isDeleted'
    >
  >;
};

export type GetTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTagsQuery = { __typename?: 'Query' } & {
  getTags?: Maybe<
    Array<Maybe<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>>>
  >;
};

export type CreateTagMutationVariables = Exact<{
  name: Array<Scalars['String']>;
}>;

export type CreateTagMutation = { __typename?: 'Mutation' } & {
  createTag: Array<
    { __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>
  >;
};

export type EditTagMutationVariables = Exact<{
  tagId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}>;

export type EditTagMutation = { __typename?: 'Mutation' } & {
  editTag?: Maybe<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>>;
};

export type DeleteTagMutationVariables = Exact<{
  tagId?: Maybe<Scalars['ID']>;
}>;

export type DeleteTagMutation = { __typename?: 'Mutation' } & {
  deleteTag?: Maybe<{ __typename?: 'Tag' } & Pick<Tag, 'name'>>;
};

export type TagCandidatesMutationVariables = Exact<{
  candidateIds: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
}>;

export type TagCandidatesMutation = { __typename?: 'Mutation' } & {
  tagCandidates?: Maybe<
    Array<Maybe<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>>>
  >;
};

export type TagCrawliesMutationVariables = Exact<{
  candidateIds: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
}>;

export type TagCrawliesMutation = { __typename?: 'Mutation' } & {
  tagCrawlies?: Maybe<
    Array<Maybe<{ __typename?: 'Tag' } & Pick<Tag, '_id' | 'name' | 'color'>>>
  >;
};

export type UntagCandidateMutationVariables = Exact<{
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
}>;

export type UntagCandidateMutation = { __typename?: 'Mutation' } & {
  untagCandidate?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type UntagCrawliesMutationVariables = Exact<{
  tagId?: Maybe<Scalars['ID']>;
  candidateId?: Maybe<Scalars['ID']>;
}>;

export type UntagCrawliesMutation = { __typename?: 'Mutation' } & {
  untagCrawlies?: Maybe<
    { __typename?: 'Response' } & Pick<Response, 'message'>
  >;
};

export type UpdateJobIsFavouriteMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
  isFavourite: Scalars['Boolean'];
}>;

export type UpdateJobIsFavouriteMutation = { __typename?: 'Mutation' } & {
  updateJobIsFavourite?: Maybe<
    { __typename?: 'Job' } & Pick<
      Job,
      | '_id'
      | 'title'
      | 'hideSalary'
      | 'experience'
      | 'salaryPackage'
      | 'description'
      | 'createdAt'
      | 'status'
      | 'favouriteBy'
    > & {
        countries: Array<
          { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
        >;
        requiredSkills: Array<
          { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
        >;
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            '_id' | 'name' | 'logoUrl'
          > & {
              users?: Maybe<
                Array<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    '_id' | 'email'
                  >
                >
              >;
            }
        >;
        recruiters?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email' | 'roles'
              >
            >
          >
        >;
        leadRecruiter?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
        fields: Array<
          Maybe<{ __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>>
        >;
        contractType: { __typename?: 'ContractType' } & Pick<
          ContractType,
          '_id' | 'name'
        >;
        targetCompanies: Array<
          { __typename?: 'inspiringCompany' } & Pick<
            InspiringCompany,
            '_id' | 'name'
          >
        >;
        currency?: Maybe<
          { __typename?: 'Currency' } & Pick<
            Currency,
            '_id' | 'name' | 'symbol'
          >
        >;
      }
  >;
};

export type UpdateJobStatusMutationVariables = Exact<{
  jobId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
}>;

export type UpdateJobStatusMutation = { __typename?: 'Mutation' } & {
  updateJobStatus?: Maybe<
    { __typename?: 'Job' } & Pick<
      Job,
      | '_id'
      | 'title'
      | 'hideSalary'
      | 'experience'
      | 'salaryPackage'
      | 'description'
      | 'createdAt'
      | 'status'
      | 'favouriteBy'
    > & {
        countries: Array<
          { __typename?: 'Country' } & Pick<Country, '_id' | 'name' | 'code'>
        >;
        requiredSkills: Array<
          { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
        >;
        company?: Maybe<
          { __typename?: 'Company' } & Pick<
            Company,
            '_id' | 'name' | 'logoUrl'
          > & {
              users?: Maybe<
                Array<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    '_id' | 'email'
                  >
                >
              >;
            }
        >;
        recruiters?: Maybe<
          Array<
            Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email' | 'roles'
              >
            >
          >
        >;
        leadRecruiter?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'email'
          >
        >;
        fields: Array<
          Maybe<{ __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>>
        >;
        contractType: { __typename?: 'ContractType' } & Pick<
          ContractType,
          '_id' | 'name'
        >;
        targetCompanies: Array<
          { __typename?: 'inspiringCompany' } & Pick<
            InspiringCompany,
            '_id' | 'name'
          >
        >;
        currency?: Maybe<
          { __typename?: 'Currency' } & Pick<
            Currency,
            '_id' | 'name' | 'symbol'
          >
        >;
      }
  >;
};

export type UserAllJobsQueryVariables = Exact<{ [key: string]: never }>;

export type UserAllJobsQuery = { __typename?: 'Query' } & {
  getUserJobs?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Job' } & Pick<
          Job,
          | '_id'
          | 'title'
          | 'hideSalary'
          | 'salaryPackage'
          | 'experience'
          | 'description'
          | 'createdAt'
          | 'status'
          | 'favouriteBy'
        > & {
            countries: Array<
              { __typename?: 'Country' } & Pick<
                Country,
                '_id' | 'name' | 'code'
              >
            >;
            requiredSkills: Array<
              { __typename?: 'Skills' } & Pick<Skills, '_id' | 'title'>
            >;
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                '_id' | 'name' | 'logoUrl'
              > & {
                  users?: Maybe<
                    Array<
                      { __typename?: 'RecruiterUser' } & Pick<
                        RecruiterUser,
                        '_id' | 'email'
                      >
                    >
                  >;
                }
            >;
            currency?: Maybe<
              { __typename?: 'Currency' } & Pick<
                Currency,
                '_id' | 'name' | 'symbol'
              >
            >;
            leadRecruiter?: Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'email'
              >
            >;
            fields: Array<
              Maybe<
                { __typename?: 'JobField' } & Pick<JobField, '_id' | 'title'>
              >
            >;
            contractType: { __typename?: 'ContractType' } & Pick<
              ContractType,
              '_id' | 'name'
            >;
            owner?: Maybe<
              { __typename?: 'RecruiterUser' } & Pick<
                RecruiterUser,
                '_id' | 'userId' | 'fullName' | 'title'
              >
            >;
            targetCompanies: Array<
              { __typename?: 'inspiringCompany' } & Pick<
                InspiringCompany,
                '_id' | 'name'
              >
            >;
            recruiters?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'RecruiterUser' } & Pick<
                    RecruiterUser,
                    'email' | 'roles'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type CompaniesQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchText?: Maybe<Scalars['String']>;
  searchField?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  sortingColumn?: Maybe<Scalars['String']>;
  sortingOrder?: Maybe<Scalars['String']>;
}>;

export type CompaniesQuery = { __typename?: 'Query' } & {
  companies: Array<
    { __typename?: 'Company' } & Pick<
      Company,
      | '_id'
      | 'name'
      | 'description'
      | 'logoUrl'
      | 'website'
      | 'count'
      | 'companySize'
      | 'totalJobs'
      | 'facebook'
      | 'linkedIn'
      | 'twitter'
      | 'isDeleted'
      | 'status'
      | 'favouritedBy'
      | 'createdAt'
      | 'updatedAt'
    > & {
        sector?: Maybe<
          { __typename?: 'SectorType' } & Pick<SectorType, 'title'>
        >;
        users?: Maybe<
          Array<
            { __typename?: 'RecruiterUser' } & Pick<
              RecruiterUser,
              '_id' | 'email' | 'roles'
            >
          >
        >;
        jobs?: Maybe<
          Array<{ __typename?: 'Job' } & Pick<Job, '_id' | 'title'>>
        >;
        createdBy?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'roles' | 'email'
          >
        >;
        owner?: Maybe<
          { __typename?: 'RecruiterUser' } & Pick<
            RecruiterUser,
            '_id' | 'fullName' | 'title' | 'userId'
          >
        >;
      }
  >;
};

export type UpdateCompanyMutationVariables = Exact<{
  companyId: Scalars['String'];
  companyData: UpdateCompany;
}>;

export type UpdateCompanyMutation = { __typename?: 'Mutation' } & {
  updateCompany?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | '_id'
      | 'name'
      | 'description'
      | 'confidentialdescription'
      | 'logoUrl'
      | 'website'
      | 'companySize'
      | 'banner'
      | 'jobTitle'
      | 'totalJobs'
      | 'facebook'
      | 'linkedIn'
      | 'twitter'
      | 'status'
      | 'isDeleted'
      | 'favouritedBy'
    >
  >;
};

export const AllJobsDocument = gql`
  query AllJobs {
    AllJobs {
      _id
      title
      hideSalary
      salaryPackage
      experience
      description
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      createdAt
      newCandidates {
        activationScore
        _id
        score
        jobScore
        activationScore
        recruiterAction
        candidateAction
        bypass
        media
        isDeleted
        createdAt
        updatedAt
        candidateListing {
          _id
          firstName
          lastName
          position
          description
          resume
          source
          isPublic
          profilePicture
          isDeleted
          token
          subscriptionPlanId
          createdAt
          updatedAt
          title
          isProfileCompleted
          user {
            email
          }
        }
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useAllJobsQuery__
 *
 * To run a query within a React component, call `useAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllJobsQuery,
    AllJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllJobsQuery, AllJobsQueryVariables>(
    AllJobsDocument,
    baseOptions
  );
}
export function useAllJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllJobsQuery,
    AllJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllJobsQuery, AllJobsQueryVariables>(
    AllJobsDocument,
    baseOptions
  );
}
export type AllJobsQueryHookResult = ReturnType<typeof useAllJobsQuery>;
export type AllJobsLazyQueryHookResult = ReturnType<typeof useAllJobsLazyQuery>;
export type AllJobsQueryResult = ApolloReactCommon.QueryResult<
  AllJobsQuery,
  AllJobsQueryVariables
>;
export const AllActiveJobsDocument = gql`
  query AllActiveJobs(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $userId: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    AllActiveJobs(
      pageSize: $pageSize
      page: $page
      searchText: $searchText
      searchField: $searchField
      userId: $userId
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      title
      count
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      createdAt
      recruiters {
        _id
      }
      countries {
        name
      }
      recruitersDetail {
        fullName
        title
        profilePic
      }
      newCandidates {
        activationScore
        _id
        score
        jobScore
        activationScore
        recruiterAction
        candidateAction
        bypass
        media
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useAllActiveJobsQuery__
 *
 * To run a query within a React component, call `useAllActiveJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActiveJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActiveJobsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      userId: // value for 'userId'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useAllActiveJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllActiveJobsQuery,
    AllActiveJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllActiveJobsQuery,
    AllActiveJobsQueryVariables
  >(AllActiveJobsDocument, baseOptions);
}
export function useAllActiveJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllActiveJobsQuery,
    AllActiveJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllActiveJobsQuery,
    AllActiveJobsQueryVariables
  >(AllActiveJobsDocument, baseOptions);
}
export type AllActiveJobsQueryHookResult = ReturnType<
  typeof useAllActiveJobsQuery
>;
export type AllActiveJobsLazyQueryHookResult = ReturnType<
  typeof useAllActiveJobsLazyQuery
>;
export type AllActiveJobsQueryResult = ApolloReactCommon.QueryResult<
  AllActiveJobsQuery,
  AllActiveJobsQueryVariables
>;
export const AllArchivedJobsDocument = gql`
  query AllArchivedJobs(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $userId: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    AllArchivedJobs(
      pageSize: $pageSize
      page: $page
      searchText: $searchText
      searchField: $searchField
      userId: $userId
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      title
      count
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      createdAt
      countries {
        name
      }
      recruiters {
        _id
      }
      recruitersDetail {
        fullName
        title
        profilePic
      }
      newCandidates {
        activationScore
        _id
        score
        jobScore
        activationScore
        recruiterAction
        candidateAction
        bypass
        media
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useAllArchivedJobsQuery__
 *
 * To run a query within a React component, call `useAllArchivedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArchivedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArchivedJobsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      userId: // value for 'userId'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useAllArchivedJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllArchivedJobsQuery,
    AllArchivedJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllArchivedJobsQuery,
    AllArchivedJobsQueryVariables
  >(AllArchivedJobsDocument, baseOptions);
}
export function useAllArchivedJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllArchivedJobsQuery,
    AllArchivedJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllArchivedJobsQuery,
    AllArchivedJobsQueryVariables
  >(AllArchivedJobsDocument, baseOptions);
}
export type AllArchivedJobsQueryHookResult = ReturnType<
  typeof useAllArchivedJobsQuery
>;
export type AllArchivedJobsLazyQueryHookResult = ReturnType<
  typeof useAllArchivedJobsLazyQuery
>;
export type AllArchivedJobsQueryResult = ApolloReactCommon.QueryResult<
  AllArchivedJobsQuery,
  AllArchivedJobsQueryVariables
>;
export const AllPendingJobsDocument = gql`
  query AllPendingJobs(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    AllPendingJobs(
      pageSize: $pageSize
      page: $page
      searchText: $searchText
      searchField: $searchField
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      title
      count
      recruiters {
        _id
      }
      countries {
        name
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruitersDetail {
        fullName
        title
        profilePic
      }
      createdAt
      newCandidates {
        activationScore
        _id
        score
        jobScore
        activationScore
        recruiterAction
        candidateAction
        bypass
        media
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useAllPendingJobsQuery__
 *
 * To run a query within a React component, call `useAllPendingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPendingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPendingJobsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useAllPendingJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllPendingJobsQuery,
    AllPendingJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllPendingJobsQuery,
    AllPendingJobsQueryVariables
  >(AllPendingJobsDocument, baseOptions);
}
export function useAllPendingJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllPendingJobsQuery,
    AllPendingJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllPendingJobsQuery,
    AllPendingJobsQueryVariables
  >(AllPendingJobsDocument, baseOptions);
}
export type AllPendingJobsQueryHookResult = ReturnType<
  typeof useAllPendingJobsQuery
>;
export type AllPendingJobsLazyQueryHookResult = ReturnType<
  typeof useAllPendingJobsLazyQuery
>;
export type AllPendingJobsQueryResult = ApolloReactCommon.QueryResult<
  AllPendingJobsQuery,
  AllPendingJobsQueryVariables
>;
export const AllRejectedJobsDocument = gql`
  query AllRejectedJobs(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    AllRejectedJobs(
      pageSize: $pageSize
      page: $page
      searchText: $searchText
      searchField: $searchField
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      title
      count
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
      }
      countries {
        name
      }
      recruitersDetail {
        fullName
        title
        profilePic
      }
      createdAt
      newCandidates {
        activationScore
        _id
        score
        jobScore
        activationScore
        recruiterAction
        candidateAction
        bypass
        media
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useAllRejectedJobsQuery__
 *
 * To run a query within a React component, call `useAllRejectedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRejectedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRejectedJobsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useAllRejectedJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllRejectedJobsQuery,
    AllRejectedJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllRejectedJobsQuery,
    AllRejectedJobsQueryVariables
  >(AllRejectedJobsDocument, baseOptions);
}
export function useAllRejectedJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllRejectedJobsQuery,
    AllRejectedJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllRejectedJobsQuery,
    AllRejectedJobsQueryVariables
  >(AllRejectedJobsDocument, baseOptions);
}
export type AllRejectedJobsQueryHookResult = ReturnType<
  typeof useAllRejectedJobsQuery
>;
export type AllRejectedJobsLazyQueryHookResult = ReturnType<
  typeof useAllRejectedJobsLazyQuery
>;
export type AllRejectedJobsQueryResult = ApolloReactCommon.QueryResult<
  AllRejectedJobsQuery,
  AllRejectedJobsQueryVariables
>;
export const AllJobsViewDocument = gql`
  query AllJobsView {
    AllJobs {
      _id
      title
      company {
        name
      }
    }
  }
`;

/**
 * __useAllJobsViewQuery__
 *
 * To run a query within a React component, call `useAllJobsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobsViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllJobsViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllJobsViewQuery,
    AllJobsViewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllJobsViewQuery, AllJobsViewQueryVariables>(
    AllJobsViewDocument,
    baseOptions
  );
}
export function useAllJobsViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllJobsViewQuery,
    AllJobsViewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllJobsViewQuery,
    AllJobsViewQueryVariables
  >(AllJobsViewDocument, baseOptions);
}
export type AllJobsViewQueryHookResult = ReturnType<typeof useAllJobsViewQuery>;
export type AllJobsViewLazyQueryHookResult = ReturnType<
  typeof useAllJobsViewLazyQuery
>;
export type AllJobsViewQueryResult = ApolloReactCommon.QueryResult<
  AllJobsViewQuery,
  AllJobsViewQueryVariables
>;
export const GetJobsCountDocument = gql`
  query GetJobsCount($searchText: String, $searchType: String) {
    GetJobsCount(searchText: $searchText, searchField: $searchType) {
      activeJobsCount
      archivedJobsCount
      pendingJobsCount
      rejectedJobsCount
    }
  }
`;

/**
 * __useGetJobsCountQuery__
 *
 * To run a query within a React component, call `useGetJobsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsCountQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      searchType: // value for 'searchType'
 *   },
 * });
 */
export function useGetJobsCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetJobsCountQuery,
    GetJobsCountQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetJobsCountQuery,
    GetJobsCountQueryVariables
  >(GetJobsCountDocument, baseOptions);
}
export function useGetJobsCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetJobsCountQuery,
    GetJobsCountQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetJobsCountQuery,
    GetJobsCountQueryVariables
  >(GetJobsCountDocument, baseOptions);
}
export type GetJobsCountQueryHookResult = ReturnType<
  typeof useGetJobsCountQuery
>;
export type GetJobsCountLazyQueryHookResult = ReturnType<
  typeof useGetJobsCountLazyQuery
>;
export type GetJobsCountQueryResult = ApolloReactCommon.QueryResult<
  GetJobsCountQuery,
  GetJobsCountQueryVariables
>;
export const GetAllRecruitersInBoDocument = gql`
  query getAllRecruitersInBO {
    getAllRecruitersInBO {
      _id
      fullName
      profilePic
      status
      user {
        _id
        roles
        email
        title
        verified
        subscriptionPlanId
        token
      }
      title
      verified
      companiesCount
      jobsCount
      createdAt
    }
  }
`;

/**
 * __useGetAllRecruitersInBoQuery__
 *
 * To run a query within a React component, call `useGetAllRecruitersInBoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecruitersInBoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecruitersInBoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRecruitersInBoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllRecruitersInBoQuery,
    GetAllRecruitersInBoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetAllRecruitersInBoQuery,
    GetAllRecruitersInBoQueryVariables
  >(GetAllRecruitersInBoDocument, baseOptions);
}
export function useGetAllRecruitersInBoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllRecruitersInBoQuery,
    GetAllRecruitersInBoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllRecruitersInBoQuery,
    GetAllRecruitersInBoQueryVariables
  >(GetAllRecruitersInBoDocument, baseOptions);
}
export type GetAllRecruitersInBoQueryHookResult = ReturnType<
  typeof useGetAllRecruitersInBoQuery
>;
export type GetAllRecruitersInBoLazyQueryHookResult = ReturnType<
  typeof useGetAllRecruitersInBoLazyQuery
>;
export type GetAllRecruitersInBoQueryResult = ApolloReactCommon.QueryResult<
  GetAllRecruitersInBoQuery,
  GetAllRecruitersInBoQueryVariables
>;
export const UpdateAssessmentsDocument = gql`
  mutation UpdateAssessments(
    $matchId: String!
    $hideExperience: Boolean!
    $hideTraits: Boolean!
    $hideDrivers: Boolean!
    $experience: [AssessmentInputView!]!
    $traits: [AssessmentInputView!]!
    $drivers: [AssessmentInputView!]!
  ) {
    AssessmentsUpdate(
      matchId: $matchId
      hideExperience: $hideExperience
      hideTraits: $hideTraits
      hideDrivers: $hideDrivers
      experience: $experience
      traits: $traits
      drivers: $drivers
    ) {
      _id
    }
  }
`;
export type UpdateAssessmentsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAssessmentsMutation,
  UpdateAssessmentsMutationVariables
>;

/**
 * __useUpdateAssessmentsMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentsMutation, { data, loading, error }] = useUpdateAssessmentsMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      hideExperience: // value for 'hideExperience'
 *      hideTraits: // value for 'hideTraits'
 *      hideDrivers: // value for 'hideDrivers'
 *      experience: // value for 'experience'
 *      traits: // value for 'traits'
 *      drivers: // value for 'drivers'
 *   },
 * });
 */
export function useUpdateAssessmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAssessmentsMutation,
    UpdateAssessmentsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateAssessmentsMutation,
    UpdateAssessmentsMutationVariables
  >(UpdateAssessmentsDocument, baseOptions);
}
export type UpdateAssessmentsMutationHookResult = ReturnType<
  typeof useUpdateAssessmentsMutation
>;
export type UpdateAssessmentsMutationResult =
  ApolloReactCommon.MutationResult<UpdateAssessmentsMutation>;
export type UpdateAssessmentsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateAssessmentsMutation,
    UpdateAssessmentsMutationVariables
  >;
export const AttachRecruiterToACompanyDocument = gql`
  mutation AttachRecruiterToACompany($userId: String!, $companyId: String!) {
    AttachRecruiterToACompany(userId: $userId, companyId: $companyId) {
      _id
      userId
      title
      subscriptionPlanId
      fullName
      email
      token
    }
  }
`;
export type AttachRecruiterToACompanyMutationFn =
  ApolloReactCommon.MutationFunction<
    AttachRecruiterToACompanyMutation,
    AttachRecruiterToACompanyMutationVariables
  >;

/**
 * __useAttachRecruiterToACompanyMutation__
 *
 * To run a mutation, you first call `useAttachRecruiterToACompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachRecruiterToACompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachRecruiterToACompanyMutation, { data, loading, error }] = useAttachRecruiterToACompanyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAttachRecruiterToACompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AttachRecruiterToACompanyMutation,
    AttachRecruiterToACompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AttachRecruiterToACompanyMutation,
    AttachRecruiterToACompanyMutationVariables
  >(AttachRecruiterToACompanyDocument, baseOptions);
}
export type AttachRecruiterToACompanyMutationHookResult = ReturnType<
  typeof useAttachRecruiterToACompanyMutation
>;
export type AttachRecruiterToACompanyMutationResult =
  ApolloReactCommon.MutationResult<AttachRecruiterToACompanyMutation>;
export type AttachRecruiterToACompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AttachRecruiterToACompanyMutation,
    AttachRecruiterToACompanyMutationVariables
  >;
export const AttachRecruiterToAJobDocument = gql`
  mutation AttachRecruiterToAJob(
    $companyId: String!
    $jobId: String!
    $userIds: [String!]
  ) {
    AttachRecruiterToAJob(
      jobId: $jobId
      companyId: $companyId
      userIds: $userIds
    ) {
      message
      status
    }
  }
`;
export type AttachRecruiterToAJobMutationFn =
  ApolloReactCommon.MutationFunction<
    AttachRecruiterToAJobMutation,
    AttachRecruiterToAJobMutationVariables
  >;

/**
 * __useAttachRecruiterToAJobMutation__
 *
 * To run a mutation, you first call `useAttachRecruiterToAJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachRecruiterToAJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachRecruiterToAJobMutation, { data, loading, error }] = useAttachRecruiterToAJobMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      jobId: // value for 'jobId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAttachRecruiterToAJobMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AttachRecruiterToAJobMutation,
    AttachRecruiterToAJobMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AttachRecruiterToAJobMutation,
    AttachRecruiterToAJobMutationVariables
  >(AttachRecruiterToAJobDocument, baseOptions);
}
export type AttachRecruiterToAJobMutationHookResult = ReturnType<
  typeof useAttachRecruiterToAJobMutation
>;
export type AttachRecruiterToAJobMutationResult =
  ApolloReactCommon.MutationResult<AttachRecruiterToAJobMutation>;
export type AttachRecruiterToAJobMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AttachRecruiterToAJobMutation,
    AttachRecruiterToAJobMutationVariables
  >;
export const CompanyRecruitersDocument = gql`
  query CompanyRecruiters($userIds: [String!]) {
    getAllCompanyRecruiters(userIds: $userIds) {
      _id
      email
      fullName
      title
    }
  }
`;

/**
 * __useCompanyRecruitersQuery__
 *
 * To run a query within a React component, call `useCompanyRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRecruitersQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useCompanyRecruitersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyRecruitersQuery,
    CompanyRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CompanyRecruitersQuery,
    CompanyRecruitersQueryVariables
  >(CompanyRecruitersDocument, baseOptions);
}
export function useCompanyRecruitersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompanyRecruitersQuery,
    CompanyRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CompanyRecruitersQuery,
    CompanyRecruitersQueryVariables
  >(CompanyRecruitersDocument, baseOptions);
}
export type CompanyRecruitersQueryHookResult = ReturnType<
  typeof useCompanyRecruitersQuery
>;
export type CompanyRecruitersLazyQueryHookResult = ReturnType<
  typeof useCompanyRecruitersLazyQuery
>;
export type CompanyRecruitersQueryResult = ApolloReactCommon.QueryResult<
  CompanyRecruitersQuery,
  CompanyRecruitersQueryVariables
>;
export const ActivateCandidateDocument = gql`
  mutation activateCandidate($matchId: String!, $activationScore: Int!) {
    activateCandidate(matchId: $matchId, activationScore: $activationScore) {
      _id
      activationScore
      createdAt
      jobScore
      score
    }
  }
`;
export type ActivateCandidateMutationFn = ApolloReactCommon.MutationFunction<
  ActivateCandidateMutation,
  ActivateCandidateMutationVariables
>;

/**
 * __useActivateCandidateMutation__
 *
 * To run a mutation, you first call `useActivateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCandidateMutation, { data, loading, error }] = useActivateCandidateMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      activationScore: // value for 'activationScore'
 *   },
 * });
 */
export function useActivateCandidateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateCandidateMutation,
    ActivateCandidateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivateCandidateMutation,
    ActivateCandidateMutationVariables
  >(ActivateCandidateDocument, baseOptions);
}
export type ActivateCandidateMutationHookResult = ReturnType<
  typeof useActivateCandidateMutation
>;
export type ActivateCandidateMutationResult =
  ApolloReactCommon.MutationResult<ActivateCandidateMutation>;
export type ActivateCandidateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ActivateCandidateMutation,
    ActivateCandidateMutationVariables
  >;
export const CandidateBenchmarkDocument = gql`
  mutation candidateBenchmark($matchId: String!, $benchmark: String!) {
    candidateBenchmark(matchId: $matchId, benchmark: $benchmark) {
      message
    }
  }
`;
export type CandidateBenchmarkMutationFn = ApolloReactCommon.MutationFunction<
  CandidateBenchmarkMutation,
  CandidateBenchmarkMutationVariables
>;

/**
 * __useCandidateBenchmarkMutation__
 *
 * To run a mutation, you first call `useCandidateBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateBenchmarkMutation, { data, loading, error }] = useCandidateBenchmarkMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      benchmark: // value for 'benchmark'
 *   },
 * });
 */
export function useCandidateBenchmarkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CandidateBenchmarkMutation,
    CandidateBenchmarkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CandidateBenchmarkMutation,
    CandidateBenchmarkMutationVariables
  >(CandidateBenchmarkDocument, baseOptions);
}
export type CandidateBenchmarkMutationHookResult = ReturnType<
  typeof useCandidateBenchmarkMutation
>;
export type CandidateBenchmarkMutationResult =
  ApolloReactCommon.MutationResult<CandidateBenchmarkMutation>;
export type CandidateBenchmarkMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CandidateBenchmarkMutation,
    CandidateBenchmarkMutationVariables
  >;
export const CandidateShowCompensationDocument = gql`
  mutation candidateShowCompensation(
    $matchId: String!
    $showCurrentCompensation: Boolean
    $showExpectedCompensation: Boolean
  ) {
    candidateShowCompensation(
      matchId: $matchId
      showCurrentCompensation: $showCurrentCompensation
      showExpectedCompensation: $showExpectedCompensation
    ) {
      message
    }
  }
`;
export type CandidateShowCompensationMutationFn =
  ApolloReactCommon.MutationFunction<
    CandidateShowCompensationMutation,
    CandidateShowCompensationMutationVariables
  >;

/**
 * __useCandidateShowCompensationMutation__
 *
 * To run a mutation, you first call `useCandidateShowCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateShowCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateShowCompensationMutation, { data, loading, error }] = useCandidateShowCompensationMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      showCurrentCompensation: // value for 'showCurrentCompensation'
 *      showExpectedCompensation: // value for 'showExpectedCompensation'
 *   },
 * });
 */
export function useCandidateShowCompensationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CandidateShowCompensationMutation,
    CandidateShowCompensationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CandidateShowCompensationMutation,
    CandidateShowCompensationMutationVariables
  >(CandidateShowCompensationDocument, baseOptions);
}
export type CandidateShowCompensationMutationHookResult = ReturnType<
  typeof useCandidateShowCompensationMutation
>;
export type CandidateShowCompensationMutationResult =
  ApolloReactCommon.MutationResult<CandidateShowCompensationMutation>;
export type CandidateShowCompensationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CandidateShowCompensationMutation,
    CandidateShowCompensationMutationVariables
  >;
export const CandidateHidePersonalDetailsDocument = gql`
  mutation candidateHidePersonalDetails(
    $matchId: String!
    $hidePersonalDetails: Boolean
  ) {
    candidateHidePersonalDetails(
      matchId: $matchId
      hidePersonalDetails: $hidePersonalDetails
    ) {
      message
    }
  }
`;
export type CandidateHidePersonalDetailsMutationFn =
  ApolloReactCommon.MutationFunction<
    CandidateHidePersonalDetailsMutation,
    CandidateHidePersonalDetailsMutationVariables
  >;

/**
 * __useCandidateHidePersonalDetailsMutation__
 *
 * To run a mutation, you first call `useCandidateHidePersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateHidePersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateHidePersonalDetailsMutation, { data, loading, error }] = useCandidateHidePersonalDetailsMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      hidePersonalDetails: // value for 'hidePersonalDetails'
 *   },
 * });
 */
export function useCandidateHidePersonalDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CandidateHidePersonalDetailsMutation,
    CandidateHidePersonalDetailsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CandidateHidePersonalDetailsMutation,
    CandidateHidePersonalDetailsMutationVariables
  >(CandidateHidePersonalDetailsDocument, baseOptions);
}
export type CandidateHidePersonalDetailsMutationHookResult = ReturnType<
  typeof useCandidateHidePersonalDetailsMutation
>;
export type CandidateHidePersonalDetailsMutationResult =
  ApolloReactCommon.MutationResult<CandidateHidePersonalDetailsMutation>;
export type CandidateHidePersonalDetailsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CandidateHidePersonalDetailsMutation,
    CandidateHidePersonalDetailsMutationVariables
  >;
export const CandidateNextStepsDocument = gql`
  mutation candidateNextSteps($matchId: String!, $nextSteps: String) {
    candidateNextSteps(matchId: $matchId, nextSteps: $nextSteps) {
      message
    }
  }
`;
export type CandidateNextStepsMutationFn = ApolloReactCommon.MutationFunction<
  CandidateNextStepsMutation,
  CandidateNextStepsMutationVariables
>;

/**
 * __useCandidateNextStepsMutation__
 *
 * To run a mutation, you first call `useCandidateNextStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateNextStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateNextStepsMutation, { data, loading, error }] = useCandidateNextStepsMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      nextSteps: // value for 'nextSteps'
 *   },
 * });
 */
export function useCandidateNextStepsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CandidateNextStepsMutation,
    CandidateNextStepsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CandidateNextStepsMutation,
    CandidateNextStepsMutationVariables
  >(CandidateNextStepsDocument, baseOptions);
}
export type CandidateNextStepsMutationHookResult = ReturnType<
  typeof useCandidateNextStepsMutation
>;
export type CandidateNextStepsMutationResult =
  ApolloReactCommon.MutationResult<CandidateNextStepsMutation>;
export type CandidateNextStepsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CandidateNextStepsMutation,
    CandidateNextStepsMutationVariables
  >;
export const UpdateCandidateDocument = gql`
  mutation updateCandidate(
    $candidateId: String!
    $firstName: String
    $lastName: String
    $position: String
    $profilePicture: String
    $profileVideo: String
    $coverPhoto: String
    $personalInformation: String
    $benchMark: String
    $company: String
    $objectives: [SectorsInput]
    $professionalInterests: [String]
    $targetLocations: [TargetLocationsInput]
    $skills: [String]
    $customSkills: [String]
    $tags: [String]
    $customTags: [String]
    $country: String
    $city: String
    $code: String
    $lat: Float
    $lng: Float
    $experiences: [experiencesInput]
    $yearlyCompensation: YearlyCompensationInput
    $education: [educationInput]
    $languages: [LanguagesInput]
    $educationComment: String
    $languageComments: String
    $description: String
    $facebook: String
    $twitter: String
    $linkedIn: String
    $github: String
    $resume: String
    $coverLetter: String
    $inspiringCompanies: [InspiringCompanyInput]
    $sectors: [SectorsInput]
  ) {
    updateCandidate(
      candidateId: $candidateId
      candidate: {
        firstName: $firstName
        lastName: $lastName
        targetLocations: $targetLocations
        position: $position
        company: $company
        personalInformation: $personalInformation
        benchMark: $benchMark
        profilePicture: $profilePicture
        profileVideo: $profileVideo
        coverPhoto: $coverPhoto
        objectives: $objectives
        professionalInterests: $professionalInterests
        inspiringCompanies: $inspiringCompanies
        sectors: $sectors
        skills: $skills
        customSkills: $customSkills
        customTags: $customTags
        tags: $tags
        isProfileCompleted: true
        currentLocation: {
          country: $country
          city: $city
          code: $code
          coordinates: [$lat, $lng]
        }
        experiences: $experiences
        yearlyCompensation: $yearlyCompensation
        education: $education
        languages: $languages
        educationComment: $educationComment
        languageComments: $languageComments
        description: $description
        facebook: $facebook
        twitter: $twitter
        linkedIn: $linkedIn
        github: $github
        resume: $resume
        coverLetter: $coverLetter
      }
    ) {
      objectives {
        _id
        title
      }
      profilePicture
      profileVideo
      experiences {
        position
        company
      }
      position
    }
  }
`;
export type UpdateCandidateMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables
>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      position: // value for 'position'
 *      profilePicture: // value for 'profilePicture'
 *      profileVideo: // value for 'profileVideo'
 *      coverPhoto: // value for 'coverPhoto'
 *      personalInformation: // value for 'personalInformation'
 *      benchMark: // value for 'benchMark'
 *      company: // value for 'company'
 *      objectives: // value for 'objectives'
 *      professionalInterests: // value for 'professionalInterests'
 *      targetLocations: // value for 'targetLocations'
 *      skills: // value for 'skills'
 *      customSkills: // value for 'customSkills'
 *      tags: // value for 'tags'
 *      customTags: // value for 'customTags'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      code: // value for 'code'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      experiences: // value for 'experiences'
 *      yearlyCompensation: // value for 'yearlyCompensation'
 *      education: // value for 'education'
 *      languages: // value for 'languages'
 *      educationComment: // value for 'educationComment'
 *      languageComments: // value for 'languageComments'
 *      description: // value for 'description'
 *      facebook: // value for 'facebook'
 *      twitter: // value for 'twitter'
 *      linkedIn: // value for 'linkedIn'
 *      github: // value for 'github'
 *      resume: // value for 'resume'
 *      coverLetter: // value for 'coverLetter'
 *      inspiringCompanies: // value for 'inspiringCompanies'
 *      sectors: // value for 'sectors'
 *   },
 * });
 */
export function useUpdateCandidateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >(UpdateCandidateDocument, baseOptions);
}
export type UpdateCandidateMutationHookResult = ReturnType<
  typeof useUpdateCandidateMutation
>;
export type UpdateCandidateMutationResult =
  ApolloReactCommon.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCandidateMutation,
    UpdateCandidateMutationVariables
  >;
export const AddCompanyDocument = gql`
  mutation addCompany(
    $title: String!
    $description: String!
    $sector: String!
    $companySize: String!
    $logoUrl: String
    $website: String
    $facebook: String
    $linkedIn: String
    $twitter: String
    $companyId: String
  ) {
    registerCompany(
      companyData: {
        name: $title
        description: $description
        logoUrl: $logoUrl
        sector: $sector
        website: $website
        companySize: $companySize
        facebook: $facebook
        twitter: $twitter
        linkedIn: $linkedIn
      }
      companyId: $companyId
    ) {
      _id
      name
      description
      logoUrl
      website
      sector {
        title
      }
      companySize
      users {
        _id
        email
      }
      facebook
      linkedIn
      twitter
      isDeleted
      createdBy {
        _id
        email
      }
      createdAt
      updatedAt
    }
  }
`;
export type AddCompanyMutationFn = ApolloReactCommon.MutationFunction<
  AddCompanyMutation,
  AddCompanyMutationVariables
>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      sector: // value for 'sector'
 *      companySize: // value for 'companySize'
 *      logoUrl: // value for 'logoUrl'
 *      website: // value for 'website'
 *      facebook: // value for 'facebook'
 *      linkedIn: // value for 'linkedIn'
 *      twitter: // value for 'twitter'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCompanyMutation,
    AddCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddCompanyMutation,
    AddCompanyMutationVariables
  >(AddCompanyDocument, baseOptions);
}
export type AddCompanyMutationHookResult = ReturnType<
  typeof useAddCompanyMutation
>;
export type AddCompanyMutationResult =
  ApolloReactCommon.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCompanyMutation,
  AddCompanyMutationVariables
>;
export const AddJobDocument = gql`
  mutation addJob(
    $title: String!
    $description: String!
    $hideSalary: Boolean
    $countries: [String]!
    $salaryPackage: String!
    $requiredSkills: [String]!
    $customSkills: [String]
    $experience: String
    $currencyId: String!
    $recruiters: [String]
    $leadRecruiter: String!
    $fields: [String]!
    $contractType: String!
    $companyId: String!
    $targetCompanies: [String]
    $jobId: String
    $isAnonymous: Boolean
    $AnonymousCompanyTitle: String
    $AnonymousCompanyDescription: String
    $banner: String
    $mobileBanner: String
    $language: String
    $yearlyCompensation: yearlyCompensationInputJob
    $assessment: assessmentInput
    $progressReport: String
  ) {
    createJob(
      job: {
        title: $title
        description: $description
        hideSalary: $hideSalary
        countries: $countries
        salaryPackage: $salaryPackage
        requiredSkills: $requiredSkills
        customSkills: $customSkills
        experience: $experience
        companyId: $companyId
        recruiters: $recruiters
        leadRecruiter: $leadRecruiter
        fields: $fields
        contractType: $contractType
        currencyId: $currencyId
        targetCompanies: $targetCompanies
        AnonymousCompanyTitle: $AnonymousCompanyTitle
        AnonymousCompanyDescription: $AnonymousCompanyDescription
        banner: $banner
        mobileBanner: $mobileBanner
        language: $language
        yearlyCompensation: $yearlyCompensation
        assessment: $assessment
        progressReport: $progressReport
      }
      jobId: $jobId
      isAnonymous: $isAnonymous
    ) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      experience
      salaryPackage
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
        email
        roles
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      status
      isAnonymous
      AnonymousCompanyTitle
      AnonymousCompanyDescription
      candidates {
        _id
      }
      banner
      mobileBanner
      yearlyCompensation {
        currency
        total
        base
        onTargetEarnings
        shortTermIncentives
        longTermIncentives
        other
        comments
      }
      assessment {
        experience {
          title
          description
        }
        traits {
          title
          description
        }
      }
      progressReport
    }
  }
`;
export type AddJobMutationFn = ApolloReactCommon.MutationFunction<
  AddJobMutation,
  AddJobMutationVariables
>;

/**
 * __useAddJobMutation__
 *
 * To run a mutation, you first call `useAddJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobMutation, { data, loading, error }] = useAddJobMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      hideSalary: // value for 'hideSalary'
 *      countries: // value for 'countries'
 *      salaryPackage: // value for 'salaryPackage'
 *      requiredSkills: // value for 'requiredSkills'
 *      customSkills: // value for 'customSkills'
 *      experience: // value for 'experience'
 *      currencyId: // value for 'currencyId'
 *      recruiters: // value for 'recruiters'
 *      leadRecruiter: // value for 'leadRecruiter'
 *      fields: // value for 'fields'
 *      contractType: // value for 'contractType'
 *      companyId: // value for 'companyId'
 *      targetCompanies: // value for 'targetCompanies'
 *      jobId: // value for 'jobId'
 *      isAnonymous: // value for 'isAnonymous'
 *      AnonymousCompanyTitle: // value for 'AnonymousCompanyTitle'
 *      AnonymousCompanyDescription: // value for 'AnonymousCompanyDescription'
 *      banner: // value for 'banner'
 *      mobileBanner: // value for 'mobileBanner'
 *      language: // value for 'language'
 *      yearlyCompensation: // value for 'yearlyCompensation'
 *      assessment: // value for 'assessment'
 *      progressReport: // value for 'progressReport'
 *   },
 * });
 */
export function useAddJobMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddJobMutation,
    AddJobMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddJobMutation, AddJobMutationVariables>(
    AddJobDocument,
    baseOptions
  );
}
export type AddJobMutationHookResult = ReturnType<typeof useAddJobMutation>;
export type AddJobMutationResult =
  ApolloReactCommon.MutationResult<AddJobMutation>;
export type AddJobMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddJobMutation,
  AddJobMutationVariables
>;
export const AllCandidatesInBoDocument = gql`
  query AllCandidatesInBO(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchType: String
    $tags: [String]
    $source: String
    $dtFrom: String
    $dtTo: String
    $userId: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    getAllCandidatesInBO(
      pageSize: $pageSize
      page: $page
      searchParams: {
        searchText: $searchText
        searchType: $searchType
        tags: $tags
        source: $source
        dtFrom: $dtFrom
        dtTo: $dtTo
      }
      userId: $userId
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      firstName
      lastName
      title
      company
      position
      resume
      createdAt
      location
      email
      source
      userSource
      isProfileCompleted
      userIsProfileCompleted
      skills
      count
      tags {
        _id
        name
        color
      }
    }
  }
`;

/**
 * __useAllCandidatesInBoQuery__
 *
 * To run a query within a React component, call `useAllCandidatesInBoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCandidatesInBoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCandidatesInBoQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchType: // value for 'searchType'
 *      tags: // value for 'tags'
 *      source: // value for 'source'
 *      dtFrom: // value for 'dtFrom'
 *      dtTo: // value for 'dtTo'
 *      userId: // value for 'userId'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useAllCandidatesInBoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllCandidatesInBoQuery,
    AllCandidatesInBoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllCandidatesInBoQuery,
    AllCandidatesInBoQueryVariables
  >(AllCandidatesInBoDocument, baseOptions);
}
export function useAllCandidatesInBoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllCandidatesInBoQuery,
    AllCandidatesInBoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllCandidatesInBoQuery,
    AllCandidatesInBoQueryVariables
  >(AllCandidatesInBoDocument, baseOptions);
}
export type AllCandidatesInBoQueryHookResult = ReturnType<
  typeof useAllCandidatesInBoQuery
>;
export type AllCandidatesInBoLazyQueryHookResult = ReturnType<
  typeof useAllCandidatesInBoLazyQuery
>;
export type AllCandidatesInBoQueryResult = ApolloReactCommon.QueryResult<
  AllCandidatesInBoQuery,
  AllCandidatesInBoQueryVariables
>;
export const CountAllCandidatesInBoDocument = gql`
  query countAllCandidatesInBO($searchText: String, $searchType: String) {
    countAllCandidatesInBO(
      searchParams: { searchText: $searchText, searchType: $searchType }
    ) {
      count
    }
  }
`;

/**
 * __useCountAllCandidatesInBoQuery__
 *
 * To run a query within a React component, call `useCountAllCandidatesInBoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllCandidatesInBoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllCandidatesInBoQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      searchType: // value for 'searchType'
 *   },
 * });
 */
export function useCountAllCandidatesInBoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountAllCandidatesInBoQuery,
    CountAllCandidatesInBoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CountAllCandidatesInBoQuery,
    CountAllCandidatesInBoQueryVariables
  >(CountAllCandidatesInBoDocument, baseOptions);
}
export function useCountAllCandidatesInBoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountAllCandidatesInBoQuery,
    CountAllCandidatesInBoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CountAllCandidatesInBoQuery,
    CountAllCandidatesInBoQueryVariables
  >(CountAllCandidatesInBoDocument, baseOptions);
}
export type CountAllCandidatesInBoQueryHookResult = ReturnType<
  typeof useCountAllCandidatesInBoQuery
>;
export type CountAllCandidatesInBoLazyQueryHookResult = ReturnType<
  typeof useCountAllCandidatesInBoLazyQuery
>;
export type CountAllCandidatesInBoQueryResult = ApolloReactCommon.QueryResult<
  CountAllCandidatesInBoQuery,
  CountAllCandidatesInBoQueryVariables
>;
export const AllRecruitersDocument = gql`
  query AllRecruiters {
    getAllRecruiters {
      _id
      email
      fullName
      title
    }
  }
`;

/**
 * __useAllRecruitersQuery__
 *
 * To run a query within a React component, call `useAllRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRecruitersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRecruitersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllRecruitersQuery,
    AllRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllRecruitersQuery,
    AllRecruitersQueryVariables
  >(AllRecruitersDocument, baseOptions);
}
export function useAllRecruitersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllRecruitersQuery,
    AllRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllRecruitersQuery,
    AllRecruitersQueryVariables
  >(AllRecruitersDocument, baseOptions);
}
export type AllRecruitersQueryHookResult = ReturnType<
  typeof useAllRecruitersQuery
>;
export type AllRecruitersLazyQueryHookResult = ReturnType<
  typeof useAllRecruitersLazyQuery
>;
export type AllRecruitersQueryResult = ApolloReactCommon.QueryResult<
  AllRecruitersQuery,
  AllRecruitersQueryVariables
>;
export const AllRecruitersExceptDocument = gql`
  query AllRecruitersExcept($companyId: String) {
    getAllRecruitersExcept(companyId: $companyId) {
      _id
      email
      fullName
      title
      userId
    }
  }
`;

/**
 * __useAllRecruitersExceptQuery__
 *
 * To run a query within a React component, call `useAllRecruitersExceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRecruitersExceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRecruitersExceptQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllRecruitersExceptQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllRecruitersExceptQuery,
    AllRecruitersExceptQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllRecruitersExceptQuery,
    AllRecruitersExceptQueryVariables
  >(AllRecruitersExceptDocument, baseOptions);
}
export function useAllRecruitersExceptLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllRecruitersExceptQuery,
    AllRecruitersExceptQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllRecruitersExceptQuery,
    AllRecruitersExceptQueryVariables
  >(AllRecruitersExceptDocument, baseOptions);
}
export type AllRecruitersExceptQueryHookResult = ReturnType<
  typeof useAllRecruitersExceptQuery
>;
export type AllRecruitersExceptLazyQueryHookResult = ReturnType<
  typeof useAllRecruitersExceptLazyQuery
>;
export type AllRecruitersExceptQueryResult = ApolloReactCommon.QueryResult<
  AllRecruitersExceptQuery,
  AllRecruitersExceptQueryVariables
>;
export const GenericResetPasswordDocument = gql`
  mutation GenericResetPassword(
    $email: String!
    $password: String!
    $resetCode: Int!
    $bypassResetCode: Boolean!
  ) {
    resetPassword(
      email: $email
      resetCode: $resetCode
      newPassword: $password
      bypassResetCode: $bypassResetCode
    ) {
      message
      status
    }
  }
`;
export type GenericResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  GenericResetPasswordMutation,
  GenericResetPasswordMutationVariables
>;

/**
 * __useGenericResetPasswordMutation__
 *
 * To run a mutation, you first call `useGenericResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenericResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [genericResetPasswordMutation, { data, loading, error }] = useGenericResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      resetCode: // value for 'resetCode'
 *      bypassResetCode: // value for 'bypassResetCode'
 *   },
 * });
 */
export function useGenericResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenericResetPasswordMutation,
    GenericResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenericResetPasswordMutation,
    GenericResetPasswordMutationVariables
  >(GenericResetPasswordDocument, baseOptions);
}
export type GenericResetPasswordMutationHookResult = ReturnType<
  typeof useGenericResetPasswordMutation
>;
export type GenericResetPasswordMutationResult =
  ApolloReactCommon.MutationResult<GenericResetPasswordMutation>;
export type GenericResetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GenericResetPasswordMutation,
    GenericResetPasswordMutationVariables
  >;
export const LoginResetPasswordDocument = gql`
  mutation LoginResetPassword(
    $email: String!
    $password: String!
    $resetCode: Int!
    $bypassResetCode: Boolean!
  ) {
    resetPassword(
      email: $email
      resetCode: $resetCode
      newPassword: $password
      bypassResetCode: $bypassResetCode
    ) {
      message
      status
    }
  }
`;
export type LoginResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  LoginResetPasswordMutation,
  LoginResetPasswordMutationVariables
>;

/**
 * __useLoginResetPasswordMutation__
 *
 * To run a mutation, you first call `useLoginResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginResetPasswordMutation, { data, loading, error }] = useLoginResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      resetCode: // value for 'resetCode'
 *      bypassResetCode: // value for 'bypassResetCode'
 *   },
 * });
 */
export function useLoginResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginResetPasswordMutation,
    LoginResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    LoginResetPasswordMutation,
    LoginResetPasswordMutationVariables
  >(LoginResetPasswordDocument, baseOptions);
}
export type LoginResetPasswordMutationHookResult = ReturnType<
  typeof useLoginResetPasswordMutation
>;
export type LoginResetPasswordMutationResult =
  ApolloReactCommon.MutationResult<LoginResetPasswordMutation>;
export type LoginResetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LoginResetPasswordMutation,
    LoginResetPasswordMutationVariables
  >;
export const VerifyResetCodeDocument = gql`
  mutation verifyResetCode($email: String!, $resetCode: String!) {
    verifyResetCode(email: $email, resetCode: $resetCode) {
      message
      status
      code
    }
  }
`;
export type VerifyResetCodeMutationFn = ApolloReactCommon.MutationFunction<
  VerifyResetCodeMutation,
  VerifyResetCodeMutationVariables
>;

/**
 * __useVerifyResetCodeMutation__
 *
 * To run a mutation, you first call `useVerifyResetCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyResetCodeMutation, { data, loading, error }] = useVerifyResetCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      resetCode: // value for 'resetCode'
 *   },
 * });
 */
export function useVerifyResetCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyResetCodeMutation,
    VerifyResetCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyResetCodeMutation,
    VerifyResetCodeMutationVariables
  >(VerifyResetCodeDocument, baseOptions);
}
export type VerifyResetCodeMutationHookResult = ReturnType<
  typeof useVerifyResetCodeMutation
>;
export type VerifyResetCodeMutationResult =
  ApolloReactCommon.MutationResult<VerifyResetCodeMutation>;
export type VerifyResetCodeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    VerifyResetCodeMutation,
    VerifyResetCodeMutationVariables
  >;
export const ForgetPasswordDocument = gql`
  mutation forgetPassword($email: String!) {
    forgetPassword(email: $email) {
      message
      status
      email
    }
  }
`;
export type ForgetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ForgetPasswordMutation,
  ForgetPasswordMutationVariables
>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForgetPasswordMutation,
    ForgetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ForgetPasswordMutation,
    ForgetPasswordMutationVariables
  >(ForgetPasswordDocument, baseOptions);
}
export type ForgetPasswordMutationHookResult = ReturnType<
  typeof useForgetPasswordMutation
>;
export type ForgetPasswordMutationResult =
  ApolloReactCommon.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ForgetPasswordMutation,
    ForgetPasswordMutationVariables
  >;
export const GenericResetEmailDocument = gql`
  mutation GenericResetEmail($email: String!, $newEmail: String!) {
    resetEmail(email: $email, newEmail: $newEmail) {
      message
      status
    }
  }
`;
export type GenericResetEmailMutationFn = ApolloReactCommon.MutationFunction<
  GenericResetEmailMutation,
  GenericResetEmailMutationVariables
>;

/**
 * __useGenericResetEmailMutation__
 *
 * To run a mutation, you first call `useGenericResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenericResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [genericResetEmailMutation, { data, loading, error }] = useGenericResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useGenericResetEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenericResetEmailMutation,
    GenericResetEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenericResetEmailMutation,
    GenericResetEmailMutationVariables
  >(GenericResetEmailDocument, baseOptions);
}
export type GenericResetEmailMutationHookResult = ReturnType<
  typeof useGenericResetEmailMutation
>;
export type GenericResetEmailMutationResult =
  ApolloReactCommon.MutationResult<GenericResetEmailMutation>;
export type GenericResetEmailMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GenericResetEmailMutation,
    GenericResetEmailMutationVariables
  >;
export const GetAdminsDocument = gql`
  query getAdmins {
    allAdmins {
      _id
      fullName
      userId
    }
  }
`;

/**
 * __useGetAdminsQuery__
 *
 * To run a query within a React component, call `useGetAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminsQuery,
    GetAdminsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAdminsQuery, GetAdminsQueryVariables>(
    GetAdminsDocument,
    baseOptions
  );
}
export function useGetAdminsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminsQuery,
    GetAdminsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetAdminsQuery, GetAdminsQueryVariables>(
    GetAdminsDocument,
    baseOptions
  );
}
export type GetAdminsQueryHookResult = ReturnType<typeof useGetAdminsQuery>;
export type GetAdminsLazyQueryHookResult = ReturnType<
  typeof useGetAdminsLazyQuery
>;
export type GetAdminsQueryResult = ApolloReactCommon.QueryResult<
  GetAdminsQuery,
  GetAdminsQueryVariables
>;
export const GetCandidatesDocument = gql`
  query getCandidates {
    allCandidates {
      _id
      firstName
      lastName
      userId
    }
  }
`;

/**
 * __useGetCandidatesQuery__
 *
 * To run a query within a React component, call `useGetCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCandidatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCandidatesQuery,
    GetCandidatesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCandidatesQuery,
    GetCandidatesQueryVariables
  >(GetCandidatesDocument, baseOptions);
}
export function useGetCandidatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCandidatesQuery,
    GetCandidatesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCandidatesQuery,
    GetCandidatesQueryVariables
  >(GetCandidatesDocument, baseOptions);
}
export type GetCandidatesQueryHookResult = ReturnType<
  typeof useGetCandidatesQuery
>;
export type GetCandidatesLazyQueryHookResult = ReturnType<
  typeof useGetCandidatesLazyQuery
>;
export type GetCandidatesQueryResult = ApolloReactCommon.QueryResult<
  GetCandidatesQuery,
  GetCandidatesQueryVariables
>;
export const GetPersonalInterestsDocument = gql`
  query getPersonalInterests {
    getPersonalInterests {
      _id
      title
    }
  }
`;

/**
 * __useGetPersonalInterestsQuery__
 *
 * To run a query within a React component, call `useGetPersonalInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonalInterestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPersonalInterestsQuery,
    GetPersonalInterestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetPersonalInterestsQuery,
    GetPersonalInterestsQueryVariables
  >(GetPersonalInterestsDocument, baseOptions);
}
export function useGetPersonalInterestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPersonalInterestsQuery,
    GetPersonalInterestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetPersonalInterestsQuery,
    GetPersonalInterestsQueryVariables
  >(GetPersonalInterestsDocument, baseOptions);
}
export type GetPersonalInterestsQueryHookResult = ReturnType<
  typeof useGetPersonalInterestsQuery
>;
export type GetPersonalInterestsLazyQueryHookResult = ReturnType<
  typeof useGetPersonalInterestsLazyQuery
>;
export type GetPersonalInterestsQueryResult = ApolloReactCommon.QueryResult<
  GetPersonalInterestsQuery,
  GetPersonalInterestsQueryVariables
>;
export const GetRecruitersDocument = gql`
  query getRecruiters {
    allRecruiters {
      _id
      fullName
      userId
    }
  }
`;

/**
 * __useGetRecruitersQuery__
 *
 * To run a query within a React component, call `useGetRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecruitersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecruitersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetRecruitersQuery,
    GetRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetRecruitersQuery,
    GetRecruitersQueryVariables
  >(GetRecruitersDocument, baseOptions);
}
export function useGetRecruitersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecruitersQuery,
    GetRecruitersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetRecruitersQuery,
    GetRecruitersQueryVariables
  >(GetRecruitersDocument, baseOptions);
}
export type GetRecruitersQueryHookResult = ReturnType<
  typeof useGetRecruitersQuery
>;
export type GetRecruitersLazyQueryHookResult = ReturnType<
  typeof useGetRecruitersLazyQuery
>;
export type GetRecruitersQueryResult = ApolloReactCommon.QueryResult<
  GetRecruitersQuery,
  GetRecruitersQueryVariables
>;
export const CompanyJobsDocument = gql`
  query companyJobs($companyId: ID!) {
    getCompanyJobs(companyId: $companyId) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      salaryPackage
      experience
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      recruiters {
        email
        roles
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useCompanyJobsQuery__
 *
 * To run a query within a React component, call `useCompanyJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyJobsQuery,
    CompanyJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CompanyJobsQuery, CompanyJobsQueryVariables>(
    CompanyJobsDocument,
    baseOptions
  );
}
export function useCompanyJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompanyJobsQuery,
    CompanyJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CompanyJobsQuery,
    CompanyJobsQueryVariables
  >(CompanyJobsDocument, baseOptions);
}
export type CompanyJobsQueryHookResult = ReturnType<typeof useCompanyJobsQuery>;
export type CompanyJobsLazyQueryHookResult = ReturnType<
  typeof useCompanyJobsLazyQuery
>;
export type CompanyJobsQueryResult = ApolloReactCommon.QueryResult<
  CompanyJobsQuery,
  CompanyJobsQueryVariables
>;
export const ContractTypesDocument = gql`
  query contractTypes {
    contracts {
      _id
      name
    }
  }
`;

/**
 * __useContractTypesQuery__
 *
 * To run a query within a React component, call `useContractTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContractTypesQuery,
    ContractTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ContractTypesQuery,
    ContractTypesQueryVariables
  >(ContractTypesDocument, baseOptions);
}
export function useContractTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContractTypesQuery,
    ContractTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ContractTypesQuery,
    ContractTypesQueryVariables
  >(ContractTypesDocument, baseOptions);
}
export type ContractTypesQueryHookResult = ReturnType<
  typeof useContractTypesQuery
>;
export type ContractTypesLazyQueryHookResult = ReturnType<
  typeof useContractTypesLazyQuery
>;
export type ContractTypesQueryResult = ApolloReactCommon.QueryResult<
  ContractTypesQuery,
  ContractTypesQueryVariables
>;
export const CountriesDocument = gql`
  query Countries {
    countries {
      _id
      name
      code
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions
  );
}
export function useCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountriesQuery,
    CountriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
    CountriesDocument,
    baseOptions
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<
  typeof useCountriesLazyQuery
>;
export type CountriesQueryResult = ApolloReactCommon.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const CrawliesDocument = gql`
  query Crawlies(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $tags: [String]
  ) {
    crawliesPersonalDetails(
      size: $pageSize
      pageNumber: $page
      searchText: $searchText
      searchField: $searchField
      tags: $tags
    ) {
      _id
      firstName
      lastName
      email
      phone
      createdAt
      count
      tags {
        _id
        name
        color
      }
      title
      CV
      company
      linkedIn
      location
    }
  }
`;

/**
 * __useCrawliesQuery__
 *
 * To run a query within a React component, call `useCrawliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrawliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrawliesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCrawliesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CrawliesQuery,
    CrawliesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CrawliesQuery, CrawliesQueryVariables>(
    CrawliesDocument,
    baseOptions
  );
}
export function useCrawliesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CrawliesQuery,
    CrawliesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CrawliesQuery, CrawliesQueryVariables>(
    CrawliesDocument,
    baseOptions
  );
}
export type CrawliesQueryHookResult = ReturnType<typeof useCrawliesQuery>;
export type CrawliesLazyQueryHookResult = ReturnType<
  typeof useCrawliesLazyQuery
>;
export type CrawliesQueryResult = ApolloReactCommon.QueryResult<
  CrawliesQuery,
  CrawliesQueryVariables
>;
export const CreateCandidateAndMatchDocument = gql`
  mutation createCandidateAndMatch(
    $jobId: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $summary: String!
    $position: String!
    $company: String!
    $country: String!
    $profilePicture: String!
    $resume: String!
    $profileVideo: String
    $coverLetter: String
    $skills: [String!]!
    $customSkills: [String]
    $media: String!
    $action: String!
    $experiences: [experiencesInput]
    $educations: [educationInput]
    $currentLocation: locationInput
    $resumeName: String
    $coverLetterName: String
  ) {
    createCandidateAndMatch(
      jobId: $jobId
      candidateInfo: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        summary: $summary
        position: $position
        company: $company
        country: $country
        profilePicture: $profilePicture
        resume: $resume
        profileVideo: $profileVideo
        coverLetter: $coverLetter
        skills: $skills
        experiences: $experiences
        educations: $educations
        currentLocation: $currentLocation
        resumeName: $resumeName
        coverLetterName: $coverLetterName
        customSkills: $customSkills
      }
      media: $media
      action: $action
    ) {
      _id
      email
      roles
      token
      candidate {
        _id
        resume
        firstName
        lastName
        description
        title
        profilePicture
        experiences {
          position
          company
          startYear
          endYear
          current
        }
        education {
          schoolName
          degree
          startYear
          current
          endYear
        }
        objectives {
          _id
          title
        }
        professionalInterests {
          _id
          title
        }
        skills {
          _id
          title
        }
      }
    }
  }
`;
export type CreateCandidateAndMatchMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateCandidateAndMatchMutation,
    CreateCandidateAndMatchMutationVariables
  >;

/**
 * __useCreateCandidateAndMatchMutation__
 *
 * To run a mutation, you first call `useCreateCandidateAndMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidateAndMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidateAndMatchMutation, { data, loading, error }] = useCreateCandidateAndMatchMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      summary: // value for 'summary'
 *      position: // value for 'position'
 *      company: // value for 'company'
 *      country: // value for 'country'
 *      profilePicture: // value for 'profilePicture'
 *      resume: // value for 'resume'
 *      profileVideo: // value for 'profileVideo'
 *      coverLetter: // value for 'coverLetter'
 *      skills: // value for 'skills'
 *      customSkills: // value for 'customSkills'
 *      media: // value for 'media'
 *      action: // value for 'action'
 *      experiences: // value for 'experiences'
 *      educations: // value for 'educations'
 *      currentLocation: // value for 'currentLocation'
 *      resumeName: // value for 'resumeName'
 *      coverLetterName: // value for 'coverLetterName'
 *   },
 * });
 */
export function useCreateCandidateAndMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCandidateAndMatchMutation,
    CreateCandidateAndMatchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCandidateAndMatchMutation,
    CreateCandidateAndMatchMutationVariables
  >(CreateCandidateAndMatchDocument, baseOptions);
}
export type CreateCandidateAndMatchMutationHookResult = ReturnType<
  typeof useCreateCandidateAndMatchMutation
>;
export type CreateCandidateAndMatchMutationResult =
  ApolloReactCommon.MutationResult<CreateCandidateAndMatchMutation>;
export type CreateCandidateAndMatchMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCandidateAndMatchMutation,
    CreateCandidateAndMatchMutationVariables
  >;
export const CreateJobLandingPageDocument = gql`
  mutation createJobLandingPage(
    $jobId: String!
    $header: String
    $content: String
    $leftContent: String
    $rightContent: String
    $metaTitle: String
    $metaDescription: String
    $customCss: String
    $cssLink: String
    $footer: String
    $pageTitle: String
    $faviconUrl: String
    $metaImageUrl: String
    $jobLandingPageId: String
  ) {
    createJobLandingPage(
      jobLandingPage: {
        header: $header
        content: $content
        leftContent: $leftContent
        rightContent: $rightContent
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        customCss: $customCss
        cssLink: $cssLink
        footer: $footer
        pageTitle: $pageTitle
        faviconUrl: $faviconUrl
        metaImageUrl: $metaImageUrl
        jobId: $jobId
      }
      jobLandingPageId: $jobLandingPageId
    ) {
      _id
      jobId
      header
      content
      leftContent
      rightContent
      metaTitle
      metaDescription
      customCss
      cssLink
      footer
      pageTitle
      faviconUrl
      metaImageUrl
      isDeleted
    }
  }
`;
export type CreateJobLandingPageMutationFn = ApolloReactCommon.MutationFunction<
  CreateJobLandingPageMutation,
  CreateJobLandingPageMutationVariables
>;

/**
 * __useCreateJobLandingPageMutation__
 *
 * To run a mutation, you first call `useCreateJobLandingPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobLandingPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobLandingPageMutation, { data, loading, error }] = useCreateJobLandingPageMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      header: // value for 'header'
 *      content: // value for 'content'
 *      leftContent: // value for 'leftContent'
 *      rightContent: // value for 'rightContent'
 *      metaTitle: // value for 'metaTitle'
 *      metaDescription: // value for 'metaDescription'
 *      customCss: // value for 'customCss'
 *      cssLink: // value for 'cssLink'
 *      footer: // value for 'footer'
 *      pageTitle: // value for 'pageTitle'
 *      faviconUrl: // value for 'faviconUrl'
 *      metaImageUrl: // value for 'metaImageUrl'
 *      jobLandingPageId: // value for 'jobLandingPageId'
 *   },
 * });
 */
export function useCreateJobLandingPageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateJobLandingPageMutation,
    CreateJobLandingPageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateJobLandingPageMutation,
    CreateJobLandingPageMutationVariables
  >(CreateJobLandingPageDocument, baseOptions);
}
export type CreateJobLandingPageMutationHookResult = ReturnType<
  typeof useCreateJobLandingPageMutation
>;
export type CreateJobLandingPageMutationResult =
  ApolloReactCommon.MutationResult<CreateJobLandingPageMutation>;
export type CreateJobLandingPageMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateJobLandingPageMutation,
    CreateJobLandingPageMutationVariables
  >;
export const CurrenciesDocument = gql`
  query Currencies {
    currencies {
      _id
      name
      symbol
    }
  }
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    baseOptions
  );
}
export function useCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >(CurrenciesDocument, baseOptions);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<
  typeof useCurrenciesLazyQuery
>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<
  CurrenciesQuery,
  CurrenciesQueryVariables
>;
export const DeleteJobDocument = gql`
  mutation deleteJob($jobId: String) {
    deleteJob(jobId: $jobId) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      experience
      salaryPackage
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
        email
        roles
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      status
      favouriteBy
    }
  }
`;
export type DeleteJobMutationFn = ApolloReactCommon.MutationFunction<
  DeleteJobMutation,
  DeleteJobMutationVariables
>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDeleteJobMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteJobMutation,
    DeleteJobMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteJobMutation,
    DeleteJobMutationVariables
  >(DeleteJobDocument, baseOptions);
}
export type DeleteJobMutationHookResult = ReturnType<
  typeof useDeleteJobMutation
>;
export type DeleteJobMutationResult =
  ApolloReactCommon.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteJobMutation,
  DeleteJobMutationVariables
>;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    currentUser {
      _id
      email
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, baseOptions);
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, baseOptions);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetAllMatchDocument = gql`
  query getAllMatch(
    $ListingId: String
    $searchText: String
    $searchField: String
  ) {
    getAllMatch(
      ListingId: $ListingId
      searchText: $searchText
      searchField: $searchField
    ) {
      _id
      recruiterAction
      candidateAction
      company {
        _id
        name
      }
      job {
        _id
        title
        createdAt
      }
      country {
        name
      }
    }
  }
`;

/**
 * __useGetAllMatchQuery__
 *
 * To run a query within a React component, call `useGetAllMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMatchQuery({
 *   variables: {
 *      ListingId: // value for 'ListingId'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *   },
 * });
 */
export function useGetAllMatchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllMatchQuery,
    GetAllMatchQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetAllMatchQuery, GetAllMatchQueryVariables>(
    GetAllMatchDocument,
    baseOptions
  );
}
export function useGetAllMatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllMatchQuery,
    GetAllMatchQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllMatchQuery,
    GetAllMatchQueryVariables
  >(GetAllMatchDocument, baseOptions);
}
export type GetAllMatchQueryHookResult = ReturnType<typeof useGetAllMatchQuery>;
export type GetAllMatchLazyQueryHookResult = ReturnType<
  typeof useGetAllMatchLazyQuery
>;
export type GetAllMatchQueryResult = ApolloReactCommon.QueryResult<
  GetAllMatchQuery,
  GetAllMatchQueryVariables
>;
export const GetJobLandingPageDocument = gql`
  query getJobLandingPage($jobId: ID!) {
    getJobLandingPage(jobId: $jobId) {
      _id
      jobId
      header
      content
      leftContent
      rightContent
      metaTitle
      metaDescription
      customCss
      cssLink
      footer
      pageTitle
      faviconUrl
      metaImageUrl
      isDeleted
    }
  }
`;

/**
 * __useGetJobLandingPageQuery__
 *
 * To run a query within a React component, call `useGetJobLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobLandingPageQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobLandingPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetJobLandingPageQuery,
    GetJobLandingPageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetJobLandingPageQuery,
    GetJobLandingPageQueryVariables
  >(GetJobLandingPageDocument, baseOptions);
}
export function useGetJobLandingPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetJobLandingPageQuery,
    GetJobLandingPageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetJobLandingPageQuery,
    GetJobLandingPageQueryVariables
  >(GetJobLandingPageDocument, baseOptions);
}
export type GetJobLandingPageQueryHookResult = ReturnType<
  typeof useGetJobLandingPageQuery
>;
export type GetJobLandingPageLazyQueryHookResult = ReturnType<
  typeof useGetJobLandingPageLazyQuery
>;
export type GetJobLandingPageQueryResult = ApolloReactCommon.QueryResult<
  GetJobLandingPageQuery,
  GetJobLandingPageQueryVariables
>;
export const GetMatchDocument = gql`
  query getMatch($matchId: String!) {
    getMatch(matchId: $matchId) {
      _id
      benchmark
      showCurrentCompensation
      showExpectedCompensation
      hidePersonalDetails
      nextSteps
      candidateListing {
        _id
        firstName
        lastName
        position
        description
        personalInformation
        benchMark
        totalExperience
        educationComment
        attachmentPreview
        ResumePreview
        experiences {
          _id
          position
          company
          startYear
          endYear
          current
          logo
          location {
            country
            city
            code
            coordinates {
              lat
              lng
            }
          }
          sector
          jobField
          reasonForChange
          comments
          commentData {
            candidateId
            company
            comments
            reasonForChange
          }
        }
        targetLocations {
          city
          name
        }
        languages {
          language
          level
        }
        languageComments
        resume
        coverLetter
        education {
          _id
          schoolName
          degree
          startYear
          current
          logo
          location {
            country
            city
            code
            coordinates {
              lat
              lng
            }
          }
          endYear
        }
        isPublic
        currentLocation {
          country
          city
          code
          coordinates
        }
        profilePicture
        coverPhoto
        profileVideo
        objectives {
          _id
          title
        }
        professionalInterests {
          _id
          title
        }
        targetRoles
        desiredSalaryPackage
        currentSalaryPackage
        isOnlyNetworker
        isDeleted
        token
        subscriptionPlanId
        createdAt
        updatedAt
        appliedJobs
        tags {
          _id
          name
          color
        }
        contractIdType
        sectors {
          _id
          title
        }
        inspiringCompanies {
          _id
          name
        }
        company
        userId
        skills {
          _id
          title
        }
        isProfileCompleted
        user {
          _id
          email
        }
        yearlyCompensation {
          currency
          current {
            base
            onTargetEarnings
            shortTermIncentives
            longTermIncentives
            other
            total
          }
          expected {
            base
            onTargetEarnings
            shortTermIncentives
            longTermIncentives
            other
            total
          }
          comments
          currentComments
        }
        twitter
        linkedIn
        facebook
        github
      }
      AssessmentReport {
        _id
        matchId
        hideExperience
        hideTraits
        hideDrivers
        experience {
          title
          level
          comment
        }
        traits {
          title
          level
          comment
        }
        drivers {
          title
          level
          comment
        }
      }
      score
      jobScore
      activationScore
      recruiterAction
      candidateAction
      bypass
      media
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMatchQuery__
 *
 * To run a query within a React component, call `useGetMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *   },
 * });
 */
export function useGetMatchQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMatchQuery,
    GetMatchQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetMatchQuery, GetMatchQueryVariables>(
    GetMatchDocument,
    baseOptions
  );
}
export function useGetMatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMatchQuery,
    GetMatchQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetMatchQuery, GetMatchQueryVariables>(
    GetMatchDocument,
    baseOptions
  );
}
export type GetMatchQueryHookResult = ReturnType<typeof useGetMatchQuery>;
export type GetMatchLazyQueryHookResult = ReturnType<
  typeof useGetMatchLazyQuery
>;
export type GetMatchQueryResult = ApolloReactCommon.QueryResult<
  GetMatchQuery,
  GetMatchQueryVariables
>;
export const InspiringCompaniesDocument = gql`
  query inspiringCompanies {
    inspiringCompanies {
      _id
      name
    }
  }
`;

/**
 * __useInspiringCompaniesQuery__
 *
 * To run a query within a React component, call `useInspiringCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspiringCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspiringCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInspiringCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InspiringCompaniesQuery,
    InspiringCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InspiringCompaniesQuery,
    InspiringCompaniesQueryVariables
  >(InspiringCompaniesDocument, baseOptions);
}
export function useInspiringCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InspiringCompaniesQuery,
    InspiringCompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InspiringCompaniesQuery,
    InspiringCompaniesQueryVariables
  >(InspiringCompaniesDocument, baseOptions);
}
export type InspiringCompaniesQueryHookResult = ReturnType<
  typeof useInspiringCompaniesQuery
>;
export type InspiringCompaniesLazyQueryHookResult = ReturnType<
  typeof useInspiringCompaniesLazyQuery
>;
export type InspiringCompaniesQueryResult = ApolloReactCommon.QueryResult<
  InspiringCompaniesQuery,
  InspiringCompaniesQueryVariables
>;
export const JobFieldsDocument = gql`
  query JobFields {
    jobFields {
      _id
      title
      parent {
        _id
        title
      }
    }
  }
`;

/**
 * __useJobFieldsQuery__
 *
 * To run a query within a React component, call `useJobFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    JobFieldsQuery,
    JobFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<JobFieldsQuery, JobFieldsQueryVariables>(
    JobFieldsDocument,
    baseOptions
  );
}
export function useJobFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    JobFieldsQuery,
    JobFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<JobFieldsQuery, JobFieldsQueryVariables>(
    JobFieldsDocument,
    baseOptions
  );
}
export type JobFieldsQueryHookResult = ReturnType<typeof useJobFieldsQuery>;
export type JobFieldsLazyQueryHookResult = ReturnType<
  typeof useJobFieldsLazyQuery
>;
export type JobFieldsQueryResult = ApolloReactCommon.QueryResult<
  JobFieldsQuery,
  JobFieldsQueryVariables
>;
export const AdminLoginDocument = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      _id
      email
      userId
      fullName
      title
      roles
      verified
      token
    }
  }
`;
export type AdminLoginMutationFn = ApolloReactCommon.MutationFunction<
  AdminLoginMutation,
  AdminLoginMutationVariables
>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminLoginMutation,
    AdminLoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AdminLoginMutation,
    AdminLoginMutationVariables
  >(AdminLoginDocument, baseOptions);
}
export type AdminLoginMutationHookResult = ReturnType<
  typeof useAdminLoginMutation
>;
export type AdminLoginMutationResult =
  ApolloReactCommon.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminLoginMutation,
  AdminLoginMutationVariables
>;
export const MatchesForAJobDocument = gql`
  query matchesForAJob($jobId: String!) {
    matchesForAJob(jobId: $jobId) {
      _id
      jobListing {
        _id
      }
      candidateListing {
        _id
        userId
        firstName
        lastName
        resume
        ResumePreview
        attachmentPreview
        position
        company
        tags {
          _id
          name
          color
        }
        title
        user {
          email
        }
        countries {
          name
          code
        }
        location {
          coordinates
          country
          city
          code
        }
        isProfileCompleted
      }
      score
      jobScore
      activationScore
      recruiterAction
      candidateAction
      bypass
      media
      isDeleted
      createdAt
      updatedAt
      matchingAlgoScores {
        candidateScore
        jobScore
      }
    }
  }
`;

/**
 * __useMatchesForAJobQuery__
 *
 * To run a query within a React component, call `useMatchesForAJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesForAJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesForAJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useMatchesForAJobQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MatchesForAJobQuery,
    MatchesForAJobQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MatchesForAJobQuery,
    MatchesForAJobQueryVariables
  >(MatchesForAJobDocument, baseOptions);
}
export function useMatchesForAJobLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MatchesForAJobQuery,
    MatchesForAJobQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MatchesForAJobQuery,
    MatchesForAJobQueryVariables
  >(MatchesForAJobDocument, baseOptions);
}
export type MatchesForAJobQueryHookResult = ReturnType<
  typeof useMatchesForAJobQuery
>;
export type MatchesForAJobLazyQueryHookResult = ReturnType<
  typeof useMatchesForAJobLazyQuery
>;
export type MatchesForAJobQueryResult = ApolloReactCommon.QueryResult<
  MatchesForAJobQuery,
  MatchesForAJobQueryVariables
>;
export const CreatedMatchDocument = gql`
  subscription createdMatch {
    createdMatch {
      _id
      candidateListing {
        _id
        userId
        firstName
        lastName
        resume
        ResumePreview
        attachmentPreview
        position
        user {
          email
        }
        countries {
          name
          code
        }
        currentLocation {
          coordinates
          country
          city
          code
        }
        isProfileCompleted
      }
      score
      jobScore
      activationScore
      recruiterAction
      candidateAction
      bypass
      media
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useCreatedMatchSubscription__
 *
 * To run a query within a React component, call `useCreatedMatchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreatedMatchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedMatchSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCreatedMatchSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    CreatedMatchSubscription,
    CreatedMatchSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    CreatedMatchSubscription,
    CreatedMatchSubscriptionVariables
  >(CreatedMatchDocument, baseOptions);
}
export type CreatedMatchSubscriptionHookResult = ReturnType<
  typeof useCreatedMatchSubscription
>;
export type CreatedMatchSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<CreatedMatchSubscription>;
export const ObjectivesDocument = gql`
  query Objectives {
    getObjectives {
      _id
      title
    }
  }
`;

/**
 * __useObjectivesQuery__
 *
 * To run a query within a React component, call `useObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectivesQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectivesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ObjectivesQuery,
    ObjectivesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ObjectivesQuery, ObjectivesQueryVariables>(
    ObjectivesDocument,
    baseOptions
  );
}
export function useObjectivesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ObjectivesQuery,
    ObjectivesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ObjectivesQuery,
    ObjectivesQueryVariables
  >(ObjectivesDocument, baseOptions);
}
export type ObjectivesQueryHookResult = ReturnType<typeof useObjectivesQuery>;
export type ObjectivesLazyQueryHookResult = ReturnType<
  typeof useObjectivesLazyQuery
>;
export type ObjectivesQueryResult = ApolloReactCommon.QueryResult<
  ObjectivesQuery,
  ObjectivesQueryVariables
>;
export const PersonalInterestDocument = gql`
  query PersonalInterest {
    getPersonalInterests {
      _id
      title
    }
  }
`;

/**
 * __usePersonalInterestQuery__
 *
 * To run a query within a React component, call `usePersonalInterestQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalInterestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalInterestQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalInterestQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PersonalInterestQuery,
    PersonalInterestQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PersonalInterestQuery,
    PersonalInterestQueryVariables
  >(PersonalInterestDocument, baseOptions);
}
export function usePersonalInterestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PersonalInterestQuery,
    PersonalInterestQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PersonalInterestQuery,
    PersonalInterestQueryVariables
  >(PersonalInterestDocument, baseOptions);
}
export type PersonalInterestQueryHookResult = ReturnType<
  typeof usePersonalInterestQuery
>;
export type PersonalInterestLazyQueryHookResult = ReturnType<
  typeof usePersonalInterestLazyQuery
>;
export type PersonalInterestQueryResult = ApolloReactCommon.QueryResult<
  PersonalInterestQuery,
  PersonalInterestQueryVariables
>;
export const SendMessageDocument = gql`
  mutation sendMessage($matchId: String!, $action: String!, $message: String) {
    messageTriggerMailRecruiter(
      matchId: $matchId
      action: $action
      message: $message
    ) {
      message
    }
  }
`;
export type SendMessageMutationFn = ApolloReactCommon.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      action: // value for 'action'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendMessageMutation,
    SendMessageMutationVariables
  >(SendMessageDocument, baseOptions);
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  ApolloReactCommon.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const SendMessageToCandidateDocument = gql`
  mutation sendMessageToCandidate(
    $sendTo: String!
    $myRole: String!
    $message: String!
    $payload: MessagePayload
  ) {
    sendMessage(
      sendTo: $sendTo
      myRole: $myRole
      message: $message
      payload: $payload
    ) {
      message
      status
    }
  }
`;
export type SendMessageToCandidateMutationFn =
  ApolloReactCommon.MutationFunction<
    SendMessageToCandidateMutation,
    SendMessageToCandidateMutationVariables
  >;

/**
 * __useSendMessageToCandidateMutation__
 *
 * To run a mutation, you first call `useSendMessageToCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToCandidateMutation, { data, loading, error }] = useSendMessageToCandidateMutation({
 *   variables: {
 *      sendTo: // value for 'sendTo'
 *      myRole: // value for 'myRole'
 *      message: // value for 'message'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSendMessageToCandidateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendMessageToCandidateMutation,
    SendMessageToCandidateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendMessageToCandidateMutation,
    SendMessageToCandidateMutationVariables
  >(SendMessageToCandidateDocument, baseOptions);
}
export type SendMessageToCandidateMutationHookResult = ReturnType<
  typeof useSendMessageToCandidateMutation
>;
export type SendMessageToCandidateMutationResult =
  ApolloReactCommon.MutationResult<SendMessageToCandidateMutation>;
export type SendMessageToCandidateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SendMessageToCandidateMutation,
    SendMessageToCandidateMutationVariables
  >;
export const RecruiterResetPasswordDocument = gql`
  mutation recruiterResetPassword($email: String!, $password: String!) {
    recruiterResetPassword(email: $email, password: $password) {
      email
      response {
        message
        status
      }
    }
  }
`;
export type RecruiterResetPasswordMutationFn =
  ApolloReactCommon.MutationFunction<
    RecruiterResetPasswordMutation,
    RecruiterResetPasswordMutationVariables
  >;

/**
 * __useRecruiterResetPasswordMutation__
 *
 * To run a mutation, you first call `useRecruiterResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterResetPasswordMutation, { data, loading, error }] = useRecruiterResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRecruiterResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecruiterResetPasswordMutation,
    RecruiterResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RecruiterResetPasswordMutation,
    RecruiterResetPasswordMutationVariables
  >(RecruiterResetPasswordDocument, baseOptions);
}
export type RecruiterResetPasswordMutationHookResult = ReturnType<
  typeof useRecruiterResetPasswordMutation
>;
export type RecruiterResetPasswordMutationResult =
  ApolloReactCommon.MutationResult<RecruiterResetPasswordMutation>;
export type RecruiterResetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RecruiterResetPasswordMutation,
    RecruiterResetPasswordMutationVariables
  >;
export const RecruiterSignupDocument = gql`
  mutation recruiterSignup(
    $fullName: String!
    $email: String!
    $password: String!
    $title: String!
    $company: String!
    $location: UpdateLocationExperienceAndEducation!
  ) {
    recruiterSignup(
      fullName: $fullName
      email: $email
      password: $password
      title: $title
      company: $company
      location: $location
    ) {
      _id
      userId
      title
      subscriptionPlanId
      fullName
      email
      token
    }
  }
`;
export type RecruiterSignupMutationFn = ApolloReactCommon.MutationFunction<
  RecruiterSignupMutation,
  RecruiterSignupMutationVariables
>;

/**
 * __useRecruiterSignupMutation__
 *
 * To run a mutation, you first call `useRecruiterSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterSignupMutation, { data, loading, error }] = useRecruiterSignupMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      title: // value for 'title'
 *      company: // value for 'company'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useRecruiterSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecruiterSignupMutation,
    RecruiterSignupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RecruiterSignupMutation,
    RecruiterSignupMutationVariables
  >(RecruiterSignupDocument, baseOptions);
}
export type RecruiterSignupMutationHookResult = ReturnType<
  typeof useRecruiterSignupMutation
>;
export type RecruiterSignupMutationResult =
  ApolloReactCommon.MutationResult<RecruiterSignupMutation>;
export type RecruiterSignupMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RecruiterSignupMutation,
    RecruiterSignupMutationVariables
  >;
export const RecruiterSignupForACompanyDocument = gql`
  mutation recruiterSignupForACompany(
    $fullName: String!
    $email: String!
    $password: String!
    $title: String!
    $companyId: String!
  ) {
    recruiterSignupForACompany(
      fullName: $fullName
      email: $email
      password: $password
      title: $title
      companyId: $companyId
    ) {
      _id
      userId
      title
      subscriptionPlanId
      fullName
      email
      token
    }
  }
`;
export type RecruiterSignupForACompanyMutationFn =
  ApolloReactCommon.MutationFunction<
    RecruiterSignupForACompanyMutation,
    RecruiterSignupForACompanyMutationVariables
  >;

/**
 * __useRecruiterSignupForACompanyMutation__
 *
 * To run a mutation, you first call `useRecruiterSignupForACompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterSignupForACompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterSignupForACompanyMutation, { data, loading, error }] = useRecruiterSignupForACompanyMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      title: // value for 'title'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRecruiterSignupForACompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecruiterSignupForACompanyMutation,
    RecruiterSignupForACompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RecruiterSignupForACompanyMutation,
    RecruiterSignupForACompanyMutationVariables
  >(RecruiterSignupForACompanyDocument, baseOptions);
}
export type RecruiterSignupForACompanyMutationHookResult = ReturnType<
  typeof useRecruiterSignupForACompanyMutation
>;
export type RecruiterSignupForACompanyMutationResult =
  ApolloReactCommon.MutationResult<RecruiterSignupForACompanyMutation>;
export type RecruiterSignupForACompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RecruiterSignupForACompanyMutation,
    RecruiterSignupForACompanyMutationVariables
  >;
export const RemoveRecruiterFromACompanyDocument = gql`
  mutation removeRecruiterFromACompany($userId: String!, $companyId: String!) {
    removeRecruiterFromACompany(userId: $userId, companyId: $companyId) {
      _id
      userId
      title
      subscriptionPlanId
      fullName
      email
      token
    }
  }
`;
export type RemoveRecruiterFromACompanyMutationFn =
  ApolloReactCommon.MutationFunction<
    RemoveRecruiterFromACompanyMutation,
    RemoveRecruiterFromACompanyMutationVariables
  >;

/**
 * __useRemoveRecruiterFromACompanyMutation__
 *
 * To run a mutation, you first call `useRemoveRecruiterFromACompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecruiterFromACompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecruiterFromACompanyMutation, { data, loading, error }] = useRemoveRecruiterFromACompanyMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRemoveRecruiterFromACompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRecruiterFromACompanyMutation,
    RemoveRecruiterFromACompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveRecruiterFromACompanyMutation,
    RemoveRecruiterFromACompanyMutationVariables
  >(RemoveRecruiterFromACompanyDocument, baseOptions);
}
export type RemoveRecruiterFromACompanyMutationHookResult = ReturnType<
  typeof useRemoveRecruiterFromACompanyMutation
>;
export type RemoveRecruiterFromACompanyMutationResult =
  ApolloReactCommon.MutationResult<RemoveRecruiterFromACompanyMutation>;
export type RemoveRecruiterFromACompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RemoveRecruiterFromACompanyMutation,
    RemoveRecruiterFromACompanyMutationVariables
  >;
export const SectorsDocument = gql`
  query Sectors {
    sectors {
      _id
      title
    }
  }
`;

/**
 * __useSectorsQuery__
 *
 * To run a query within a React component, call `useSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SectorsQuery,
    SectorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SectorsQuery, SectorsQueryVariables>(
    SectorsDocument,
    baseOptions
  );
}
export function useSectorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SectorsQuery,
    SectorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SectorsQuery, SectorsQueryVariables>(
    SectorsDocument,
    baseOptions
  );
}
export type SectorsQueryHookResult = ReturnType<typeof useSectorsQuery>;
export type SectorsLazyQueryHookResult = ReturnType<typeof useSectorsLazyQuery>;
export type SectorsQueryResult = ApolloReactCommon.QueryResult<
  SectorsQuery,
  SectorsQueryVariables
>;
export const AdminSignupDocument = gql`
  mutation adminSignup(
    $fullName: String!
    $email: String!
    $password: String!
    $title: String!
  ) {
    adminSignup(
      fullName: $fullName
      email: $email
      password: $password
      title: $title
    ) {
      _id
      fullName
      email
      title
      token
    }
  }
`;
export type AdminSignupMutationFn = ApolloReactCommon.MutationFunction<
  AdminSignupMutation,
  AdminSignupMutationVariables
>;

/**
 * __useAdminSignupMutation__
 *
 * To run a mutation, you first call `useAdminSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSignupMutation, { data, loading, error }] = useAdminSignupMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAdminSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSignupMutation,
    AdminSignupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AdminSignupMutation,
    AdminSignupMutationVariables
  >(AdminSignupDocument, baseOptions);
}
export type AdminSignupMutationHookResult = ReturnType<
  typeof useAdminSignupMutation
>;
export type AdminSignupMutationResult =
  ApolloReactCommon.MutationResult<AdminSignupMutation>;
export type AdminSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminSignupMutation,
  AdminSignupMutationVariables
>;
export const GetCandidateDocument = gql`
  query getCandidate($candidateId: String!) {
    getCandidate(candidateId: $candidateId) {
      _id
      firstName
      lastName
      position
      description
      totalExperience
      educationComment
      benchMark
      personalInformation
      experiences {
        _id
        position
        company
        startYear
        location {
          country
          city
          code
          coordinates {
            lat
            lng
          }
        }
        endYear
        logo
        current
        sector
        jobField
        reasonForChange
        comments
        commentData {
          candidateId
          company
          comments
          reasonForChange
        }
      }
      targetLocations {
        city
        name
      }
      languages {
        language
        level
      }
      languageComments
      resume
      coverLetter
      education {
        _id
        schoolName
        degree
        startYear
        current
        endYear
        location {
          country
          city
          code
          coordinates {
            lat
            lng
          }
        }
        logo
      }
      isPublic
      currentLocation {
        country
        city
        code
        coordinates
      }
      profilePicture
      coverPhoto
      profileVideo
      objectives {
        _id
        title
      }
      professionalInterests {
        _id
        title
      }
      targetRoles
      desiredSalaryPackage
      currentSalaryPackage
      isOnlyNetworker
      isDeleted
      token
      subscriptionPlanId
      createdAt
      updatedAt
      appliedJobs
      tags {
        _id
        name
        color
      }
      contractIdType
      sectors {
        _id
        title
      }
      company
      userId
      skills {
        _id
        title
      }
      inspiringCompanies {
        _id
        name
      }
      isProfileCompleted
      user {
        _id
        email
      }
      yearlyCompensation {
        currency
        current {
          base
          onTargetEarnings
          shortTermIncentives
          longTermIncentives
          other
          total
        }
        expected {
          base
          onTargetEarnings
          shortTermIncentives
          longTermIncentives
          other
          total
        }
        comments
        currentComments
      }
      nextSteps
      twitter
      linkedIn
      facebook
      github
    }
  }
`;

/**
 * __useGetCandidateQuery__
 *
 * To run a query within a React component, call `useGetCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useGetCandidateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCandidateQuery,
    GetCandidateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCandidateQuery,
    GetCandidateQueryVariables
  >(GetCandidateDocument, baseOptions);
}
export function useGetCandidateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCandidateQuery,
    GetCandidateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCandidateQuery,
    GetCandidateQueryVariables
  >(GetCandidateDocument, baseOptions);
}
export type GetCandidateQueryHookResult = ReturnType<
  typeof useGetCandidateQuery
>;
export type GetCandidateLazyQueryHookResult = ReturnType<
  typeof useGetCandidateLazyQuery
>;
export type GetCandidateQueryResult = ApolloReactCommon.QueryResult<
  GetCandidateQuery,
  GetCandidateQueryVariables
>;
export const GetSingleCompanyDocument = gql`
  query getSingleCompany($companyId: String!) {
    getSingleCompany(companyId: $companyId) {
      _id
      name
      description
      sector {
        title
      }
      website
      companySize
      logoUrl
      createdAt
      createdBy {
        _id
        email
      }
      facebook
      linkedIn
      twitter
      users {
        _id
        email
      }
      jobs {
        _id
        title
      }
      totalJobs
    }
  }
`;

/**
 * __useGetSingleCompanyQuery__
 *
 * To run a query within a React component, call `useGetSingleCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSingleCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleCompanyQuery,
    GetSingleCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleCompanyQuery,
    GetSingleCompanyQueryVariables
  >(GetSingleCompanyDocument, baseOptions);
}
export function useGetSingleCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSingleCompanyQuery,
    GetSingleCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSingleCompanyQuery,
    GetSingleCompanyQueryVariables
  >(GetSingleCompanyDocument, baseOptions);
}
export type GetSingleCompanyQueryHookResult = ReturnType<
  typeof useGetSingleCompanyQuery
>;
export type GetSingleCompanyLazyQueryHookResult = ReturnType<
  typeof useGetSingleCompanyLazyQuery
>;
export type GetSingleCompanyQueryResult = ApolloReactCommon.QueryResult<
  GetSingleCompanyQuery,
  GetSingleCompanyQueryVariables
>;
export const GetSingleJobDocument = gql`
  query getSingleJob($jobId: ID!) {
    getJobDetails(jobId: $jobId) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      experience
      salaryPackage
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
        email
        roles
      }
      leadRecruiter {
        _id
        email
        fullName
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      status
      favouriteBy
      isAnonymous
      AnonymousCompanyTitle
      AnonymousCompanyDescription
      banner
      mobileBanner
      candidates {
        _id
        candidateListing {
          _id
          firstName
          lastName
        }
      }
      language
      yearlyCompensation {
        currency
        total
        base
        onTargetEarnings
        shortTermIncentives
        longTermIncentives
        other
        comments
      }
      assessment {
        experience {
          title
          description
        }
        traits {
          title
          description
        }
      }
      progressReport
    }
  }
`;

/**
 * __useGetSingleJobQuery__
 *
 * To run a query within a React component, call `useGetSingleJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetSingleJobQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleJobQuery,
    GetSingleJobQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleJobQuery,
    GetSingleJobQueryVariables
  >(GetSingleJobDocument, baseOptions);
}
export function useGetSingleJobLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSingleJobQuery,
    GetSingleJobQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSingleJobQuery,
    GetSingleJobQueryVariables
  >(GetSingleJobDocument, baseOptions);
}
export type GetSingleJobQueryHookResult = ReturnType<
  typeof useGetSingleJobQuery
>;
export type GetSingleJobLazyQueryHookResult = ReturnType<
  typeof useGetSingleJobLazyQuery
>;
export type GetSingleJobQueryResult = ApolloReactCommon.QueryResult<
  GetSingleJobQuery,
  GetSingleJobQueryVariables
>;
export const GetSingleRecruiterDocument = gql`
  query getSingleRecruiter {
    getSingleRecruiter {
      _id
      fullName
      profilePic
      title
      createdAt
      user {
        email
      }
      description
      educations {
        schoolName
        degree
        startYear
        current
        endYear
      }
      experiences {
        position
        company
        startYear
        endYear
        current
        sector
        jobField
      }
    }
  }
`;

/**
 * __useGetSingleRecruiterQuery__
 *
 * To run a query within a React component, call `useGetSingleRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleRecruiterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSingleRecruiterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSingleRecruiterQuery,
    GetSingleRecruiterQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSingleRecruiterQuery,
    GetSingleRecruiterQueryVariables
  >(GetSingleRecruiterDocument, baseOptions);
}
export function useGetSingleRecruiterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSingleRecruiterQuery,
    GetSingleRecruiterQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSingleRecruiterQuery,
    GetSingleRecruiterQueryVariables
  >(GetSingleRecruiterDocument, baseOptions);
}
export type GetSingleRecruiterQueryHookResult = ReturnType<
  typeof useGetSingleRecruiterQuery
>;
export type GetSingleRecruiterLazyQueryHookResult = ReturnType<
  typeof useGetSingleRecruiterLazyQuery
>;
export type GetSingleRecruiterQueryResult = ApolloReactCommon.QueryResult<
  GetSingleRecruiterQuery,
  GetSingleRecruiterQueryVariables
>;
export const SkillsDocument = gql`
  query Skills(
    $status: String
    $isArchived: Boolean
    $isDeleted: Boolean
    $isBO: Boolean
  ) {
    skills(
      status: $status
      isArchived: $isArchived
      isDeleted: $isDeleted
      isBO: $isBO
    ) {
      _id
      title
      isArchived
      isDeleted
      createdBy
      status
      userType
      createdAt
      jobsCount {
        _id
      }
      candidatesCount {
        _id
      }
    }
  }
`;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      isArchived: // value for 'isArchived'
 *      isDeleted: // value for 'isDeleted'
 *      isBO: // value for 'isBO'
 *   },
 * });
 */
export function useSkillsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SkillsQuery,
    SkillsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SkillsQuery, SkillsQueryVariables>(
    SkillsDocument,
    baseOptions
  );
}
export function useSkillsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkillsQuery,
    SkillsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SkillsQuery, SkillsQueryVariables>(
    SkillsDocument,
    baseOptions
  );
}
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = ApolloReactCommon.QueryResult<
  SkillsQuery,
  SkillsQueryVariables
>;
export const AddSkillsDocument = gql`
  mutation addSkills($title: [String!]!) {
    createSkills(title: $title) {
      _id
      title
      isArchived
      isDeleted
      createdBy
      userType
      createdAt
    }
  }
`;
export type AddSkillsMutationFn = ApolloReactCommon.MutationFunction<
  AddSkillsMutation,
  AddSkillsMutationVariables
>;

/**
 * __useAddSkillsMutation__
 *
 * To run a mutation, you first call `useAddSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSkillsMutation, { data, loading, error }] = useAddSkillsMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddSkillsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSkillsMutation,
    AddSkillsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddSkillsMutation,
    AddSkillsMutationVariables
  >(AddSkillsDocument, baseOptions);
}
export type AddSkillsMutationHookResult = ReturnType<
  typeof useAddSkillsMutation
>;
export type AddSkillsMutationResult =
  ApolloReactCommon.MutationResult<AddSkillsMutation>;
export type AddSkillsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSkillsMutation,
  AddSkillsMutationVariables
>;
export const UpdateSkillDocument = gql`
  mutation updateSkill($skillId: String!, $title: String!) {
    updateSkill(skillId: $skillId, title: $title) {
      _id
      title
      isArchived
      isDeleted
    }
  }
`;
export type UpdateSkillMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSkillMutation,
  UpdateSkillMutationVariables
>;

/**
 * __useUpdateSkillMutation__
 *
 * To run a mutation, you first call `useUpdateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillMutation, { data, loading, error }] = useUpdateSkillMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateSkillMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSkillMutation,
    UpdateSkillMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSkillMutation,
    UpdateSkillMutationVariables
  >(UpdateSkillDocument, baseOptions);
}
export type UpdateSkillMutationHookResult = ReturnType<
  typeof useUpdateSkillMutation
>;
export type UpdateSkillMutationResult =
  ApolloReactCommon.MutationResult<UpdateSkillMutation>;
export type UpdateSkillMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSkillMutation,
  UpdateSkillMutationVariables
>;
export const UpdateStatusDocument = gql`
  mutation updateStatus(
    $skillId: String!
    $status: String!
    $isArchived: Boolean
    $isDeleted: Boolean
  ) {
    updateStatus(
      skillId: $skillId
      status: $status
      isArchived: $isArchived
      isDeleted: $isDeleted
    ) {
      _id
      title
      isArchived
      isDeleted
    }
  }
`;
export type UpdateStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStatusMutation,
  UpdateStatusMutationVariables
>;

/**
 * __useUpdateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusMutation, { data, loading, error }] = useUpdateStatusMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      status: // value for 'status'
 *      isArchived: // value for 'isArchived'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useUpdateStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStatusMutation,
    UpdateStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStatusMutation,
    UpdateStatusMutationVariables
  >(UpdateStatusDocument, baseOptions);
}
export type UpdateStatusMutationHookResult = ReturnType<
  typeof useUpdateStatusMutation
>;
export type UpdateStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateStatusMutation>;
export type UpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStatusMutation,
  UpdateStatusMutationVariables
>;
export const GetTagsDocument = gql`
  query getTags {
    getTags {
      _id
      name
      color
    }
  }
`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTagsQuery,
    GetTagsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    baseOptions
  );
}
export function useGetTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTagsQuery,
    GetTagsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    baseOptions
  );
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = ApolloReactCommon.QueryResult<
  GetTagsQuery,
  GetTagsQueryVariables
>;
export const CreateTagDocument = gql`
  mutation createTag($name: [String!]!) {
    createTag(name: $name) {
      _id
      name
      color
    }
  }
`;
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTagMutation,
    CreateTagMutationVariables
  >(CreateTagDocument, baseOptions);
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>;
export type CreateTagMutationResult =
  ApolloReactCommon.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const EditTagDocument = gql`
  mutation editTag($tagId: ID, $name: String) {
    editTag(_id: $tagId, name: $name) {
      _id
      name
      color
    }
  }
`;
export type EditTagMutationFn = ApolloReactCommon.MutationFunction<
  EditTagMutation,
  EditTagMutationVariables
>;

/**
 * __useEditTagMutation__
 *
 * To run a mutation, you first call `useEditTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTagMutation, { data, loading, error }] = useEditTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditTagMutation,
    EditTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EditTagMutation,
    EditTagMutationVariables
  >(EditTagDocument, baseOptions);
}
export type EditTagMutationHookResult = ReturnType<typeof useEditTagMutation>;
export type EditTagMutationResult =
  ApolloReactCommon.MutationResult<EditTagMutation>;
export type EditTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditTagMutation,
  EditTagMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($tagId: ID) {
    deleteTag(_id: $tagId) {
      name
    }
  }
`;
export type DeleteTagMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >(DeleteTagDocument, baseOptions);
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>;
export type DeleteTagMutationResult =
  ApolloReactCommon.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const TagCandidatesDocument = gql`
  mutation tagCandidates($candidateIds: [ID!]!, $tags: [ID!]!) {
    tagCandidates(candidateIds: $candidateIds, tags: $tags) {
      _id
      name
      color
    }
  }
`;
export type TagCandidatesMutationFn = ApolloReactCommon.MutationFunction<
  TagCandidatesMutation,
  TagCandidatesMutationVariables
>;

/**
 * __useTagCandidatesMutation__
 *
 * To run a mutation, you first call `useTagCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCandidatesMutation, { data, loading, error }] = useTagCandidatesMutation({
 *   variables: {
 *      candidateIds: // value for 'candidateIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useTagCandidatesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TagCandidatesMutation,
    TagCandidatesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TagCandidatesMutation,
    TagCandidatesMutationVariables
  >(TagCandidatesDocument, baseOptions);
}
export type TagCandidatesMutationHookResult = ReturnType<
  typeof useTagCandidatesMutation
>;
export type TagCandidatesMutationResult =
  ApolloReactCommon.MutationResult<TagCandidatesMutation>;
export type TagCandidatesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    TagCandidatesMutation,
    TagCandidatesMutationVariables
  >;
export const TagCrawliesDocument = gql`
  mutation tagCrawlies($candidateIds: [ID!]!, $tags: [ID!]!) {
    tagCrawlies(candidateIds: $candidateIds, tags: $tags) {
      _id
      name
      color
    }
  }
`;
export type TagCrawliesMutationFn = ApolloReactCommon.MutationFunction<
  TagCrawliesMutation,
  TagCrawliesMutationVariables
>;

/**
 * __useTagCrawliesMutation__
 *
 * To run a mutation, you first call `useTagCrawliesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCrawliesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCrawliesMutation, { data, loading, error }] = useTagCrawliesMutation({
 *   variables: {
 *      candidateIds: // value for 'candidateIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useTagCrawliesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TagCrawliesMutation,
    TagCrawliesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TagCrawliesMutation,
    TagCrawliesMutationVariables
  >(TagCrawliesDocument, baseOptions);
}
export type TagCrawliesMutationHookResult = ReturnType<
  typeof useTagCrawliesMutation
>;
export type TagCrawliesMutationResult =
  ApolloReactCommon.MutationResult<TagCrawliesMutation>;
export type TagCrawliesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TagCrawliesMutation,
  TagCrawliesMutationVariables
>;
export const UntagCandidateDocument = gql`
  mutation untagCandidate($tagId: ID, $candidateId: ID) {
    untagCandidate(tagId: $tagId, candidateId: $candidateId) {
      message
    }
  }
`;
export type UntagCandidateMutationFn = ApolloReactCommon.MutationFunction<
  UntagCandidateMutation,
  UntagCandidateMutationVariables
>;

/**
 * __useUntagCandidateMutation__
 *
 * To run a mutation, you first call `useUntagCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUntagCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [untagCandidateMutation, { data, loading, error }] = useUntagCandidateMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useUntagCandidateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UntagCandidateMutation,
    UntagCandidateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UntagCandidateMutation,
    UntagCandidateMutationVariables
  >(UntagCandidateDocument, baseOptions);
}
export type UntagCandidateMutationHookResult = ReturnType<
  typeof useUntagCandidateMutation
>;
export type UntagCandidateMutationResult =
  ApolloReactCommon.MutationResult<UntagCandidateMutation>;
export type UntagCandidateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UntagCandidateMutation,
    UntagCandidateMutationVariables
  >;
export const UntagCrawliesDocument = gql`
  mutation untagCrawlies($tagId: ID, $candidateId: ID) {
    untagCrawlies(tagId: $tagId, candidateId: $candidateId) {
      message
    }
  }
`;
export type UntagCrawliesMutationFn = ApolloReactCommon.MutationFunction<
  UntagCrawliesMutation,
  UntagCrawliesMutationVariables
>;

/**
 * __useUntagCrawliesMutation__
 *
 * To run a mutation, you first call `useUntagCrawliesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUntagCrawliesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [untagCrawliesMutation, { data, loading, error }] = useUntagCrawliesMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useUntagCrawliesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UntagCrawliesMutation,
    UntagCrawliesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UntagCrawliesMutation,
    UntagCrawliesMutationVariables
  >(UntagCrawliesDocument, baseOptions);
}
export type UntagCrawliesMutationHookResult = ReturnType<
  typeof useUntagCrawliesMutation
>;
export type UntagCrawliesMutationResult =
  ApolloReactCommon.MutationResult<UntagCrawliesMutation>;
export type UntagCrawliesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UntagCrawliesMutation,
    UntagCrawliesMutationVariables
  >;
export const UpdateJobIsFavouriteDocument = gql`
  mutation updateJobIsFavourite($jobId: String, $isFavourite: Boolean!) {
    updateJobIsFavourite(jobId: $jobId, action: { isFavourite: $isFavourite }) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      experience
      salaryPackage
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
        email
        roles
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      status
      favouriteBy
    }
  }
`;
export type UpdateJobIsFavouriteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateJobIsFavouriteMutation,
  UpdateJobIsFavouriteMutationVariables
>;

/**
 * __useUpdateJobIsFavouriteMutation__
 *
 * To run a mutation, you first call `useUpdateJobIsFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobIsFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobIsFavouriteMutation, { data, loading, error }] = useUpdateJobIsFavouriteMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      isFavourite: // value for 'isFavourite'
 *   },
 * });
 */
export function useUpdateJobIsFavouriteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateJobIsFavouriteMutation,
    UpdateJobIsFavouriteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateJobIsFavouriteMutation,
    UpdateJobIsFavouriteMutationVariables
  >(UpdateJobIsFavouriteDocument, baseOptions);
}
export type UpdateJobIsFavouriteMutationHookResult = ReturnType<
  typeof useUpdateJobIsFavouriteMutation
>;
export type UpdateJobIsFavouriteMutationResult =
  ApolloReactCommon.MutationResult<UpdateJobIsFavouriteMutation>;
export type UpdateJobIsFavouriteMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateJobIsFavouriteMutation,
    UpdateJobIsFavouriteMutationVariables
  >;
export const UpdateJobStatusDocument = gql`
  mutation updateJobStatus($jobId: String, $status: String!) {
    updateJobStatus(jobId: $jobId, action: { status: $status }) {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      experience
      salaryPackage
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      recruiters {
        _id
        email
        roles
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      targetCompanies {
        _id
        name
      }
      currency {
        _id
        name
        symbol
      }
      status
      favouriteBy
    }
  }
`;
export type UpdateJobStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateJobStatusMutation,
  UpdateJobStatusMutationVariables
>;

/**
 * __useUpdateJobStatusMutation__
 *
 * To run a mutation, you first call `useUpdateJobStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobStatusMutation, { data, loading, error }] = useUpdateJobStatusMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateJobStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateJobStatusMutation,
    UpdateJobStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateJobStatusMutation,
    UpdateJobStatusMutationVariables
  >(UpdateJobStatusDocument, baseOptions);
}
export type UpdateJobStatusMutationHookResult = ReturnType<
  typeof useUpdateJobStatusMutation
>;
export type UpdateJobStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateJobStatusMutation>;
export type UpdateJobStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateJobStatusMutation,
    UpdateJobStatusMutationVariables
  >;
export const UserAllJobsDocument = gql`
  query userAllJobs {
    getUserJobs {
      _id
      title
      hideSalary
      countries {
        _id
        name
        code
      }
      salaryPackage
      experience
      description
      requiredSkills {
        _id
        title
      }
      company {
        _id
        name
        logoUrl
        users {
          _id
          email
        }
      }
      currency {
        _id
        name
        symbol
      }
      leadRecruiter {
        _id
        email
      }
      fields {
        _id
        title
      }
      contractType {
        _id
        name
      }
      createdAt
      owner {
        _id
        userId
        fullName
        title
      }
      targetCompanies {
        _id
        name
      }
      recruiters {
        email
        roles
      }
      status
      favouriteBy
    }
  }
`;

/**
 * __useUserAllJobsQuery__
 *
 * To run a query within a React component, call `useUserAllJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAllJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAllJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAllJobsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAllJobsQuery,
    UserAllJobsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserAllJobsQuery, UserAllJobsQueryVariables>(
    UserAllJobsDocument,
    baseOptions
  );
}
export function useUserAllJobsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAllJobsQuery,
    UserAllJobsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserAllJobsQuery,
    UserAllJobsQueryVariables
  >(UserAllJobsDocument, baseOptions);
}
export type UserAllJobsQueryHookResult = ReturnType<typeof useUserAllJobsQuery>;
export type UserAllJobsLazyQueryHookResult = ReturnType<
  typeof useUserAllJobsLazyQuery
>;
export type UserAllJobsQueryResult = ApolloReactCommon.QueryResult<
  UserAllJobsQuery,
  UserAllJobsQueryVariables
>;
export const CompaniesDocument = gql`
  query Companies(
    $pageSize: Int
    $page: Int
    $searchText: String
    $searchField: String
    $userId: String
    $sortingColumn: String
    $sortingOrder: String
  ) {
    companies(
      pageSize: $pageSize
      page: $page
      searchText: $searchText
      searchField: $searchField
      userId: $userId
      sortingColumn: $sortingColumn
      sortingOrder: $sortingOrder
    ) {
      _id
      name
      description
      logoUrl
      website
      count
      sector {
        title
      }
      companySize
      users {
        _id
        email
        roles
      }
      jobs {
        _id
        title
      }
      totalJobs
      facebook
      linkedIn
      twitter
      isDeleted
      status
      createdBy {
        _id
        roles
        email
      }
      owner {
        _id
        fullName
        title
        userId
      }
      favouritedBy
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      searchText: // value for 'searchText'
 *      searchField: // value for 'searchField'
 *      userId: // value for 'userId'
 *      sortingColumn: // value for 'sortingColumn'
 *      sortingOrder: // value for 'sortingOrder'
 *   },
 * });
 */
export function useCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    baseOptions
  );
}
export function useCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    baseOptions
  );
}
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<
  typeof useCompaniesLazyQuery
>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<
  CompaniesQuery,
  CompaniesQueryVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($companyId: String!, $companyData: UpdateCompany!) {
    updateCompany(companyId: $companyId, companyData: $companyData) {
      _id
      name
      description
      confidentialdescription
      logoUrl
      website
      companySize
      banner
      jobTitle
      totalJobs
      facebook
      linkedIn
      twitter
      status
      isDeleted
      favouritedBy
    }
  }
`;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyData: // value for 'companyData'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UpdateCompanyDocument, baseOptions);
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult =
  ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >;
