import firebase from 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA4DnRthM6jmOtDFL46_HL42bjaTvEnZs8',
  authDomain: 'blonk-141307.firebaseapp.com',
  databaseURL: 'https://blonk-141307.firebaseio.com',
  projectId: 'blonk-141307',
  storageBucket: 'blonk-141307.appspot.com',
  messagingSenderId: '55665187756',
  appId: '1:55665187756:web:1675d10ff52bd3ac273809',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

firebase.analytics();

export default db;
